import React from 'react';
import TopBar from './../component/topbar';
import TopicArticleListPage from './../component/article/topic-article-list';
import Footer from './../component/footer';

class TopicArticleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      article: []
    }
  }

  render() {
    return (
      <div className="main">
        <TopBar login={this.props.login}></TopBar>
        <TopicArticleListPage></TopicArticleListPage>
      </div>
    );
  }
}

export default TopicArticleList;
