import React from 'react';
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import {reactLocalStorage} from 'reactjs-localstorage';

import moment from 'moment';
import 'moment/locale/id'
import validator from 'validator';
import Swal from 'sweetalert2';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import * as Config from './../config';
import { getDay } from 'date-fns';

class VaccineV2 extends React.Component {
  constructor(props) {
    super(props);
    moment.locale('id')

    this.mainPage = this.mainPage.bind(this);
    this.patientDataFormPage2 = this.patientDataFormPage2.bind(this);
    this.vaccineLocationSelectPage = this.vaccineLocationSelectPage.bind(this);
    this.hospitalSelectionPage = this.hospitalSelectionPage.bind(this);
    this.hospitalSelectionEditPage = this.hospitalSelectionEditPage.bind(this);
    this.patientDataFormPage = this.patientDataFormPage.bind(this);
    this.patientEditFormPage = this.patientEditFormPage.bind(this);
    this.submitNewPatient = this.submitNewPatient.bind(this);
    this.editPatient = this.editPatient.bind(this);
    //this.updateHospitalSelection = this.updateHospitalSelection.bind(this);
    this.patientDetailPage = this.patientDetailPage.bind(this);
    this.generateStepBar = this.generateStepBar.bind(this);
    this.screeningWelcomePage = this.screeningWelcomePage.bind(this);
    this.screeningVaccineNoPage = this.screeningVaccineNoPage.bind(this);
    this.screeningAlergicPage = this.screeningAlergicPage.bind(this);
    this.screeningAlergicAfterPage = this.screeningAlergicAfterPage.bind(this);
    this.screeningPregnantPage = this.screeningPregnantPage.bind(this);
    this.screeningAutoimmunePage = this.screeningAutoimmunePage.bind(this);
    this.screeningBloodPage = this.screeningBloodPage.bind(this);
    this.screeningChemoPage = this.screeningChemoPage.bind(this);
    this.screeningHeartPage = this.screeningHeartPage.bind(this);
    this.screeningStairsPage = this.screeningStairsPage.bind(this);
    this.screeningFatiquePage = this.screeningFatiquePage.bind(this);
    this.screeningIllnessPage = this.screeningIllnessPage.bind(this);
    this.screeningWalkingPage = this.screeningWalkingPage.bind(this);
    this.screeningWeightPage = this.screeningWeightPage.bind(this);
    this.screeningSuccessPage = this.screeningSuccessPage.bind(this);
    this.screeningFailedPage = this.screeningFailedPage.bind(this);
    this.confirmationDetailPage = this.confirmationDetailPage.bind(this);
    this.isScreeningRejected = this.isScreeningRejected.bind(this);
    this.isScreeningRejected2 = this.isScreeningRejected2.bind(this);
    this.submitScreening = this.submitScreening.bind(this);
    this.submitScreening2 = this.submitScreening2.bind(this);
    this.generateCalendar = this.generateCalendar.bind(this);
    this.submitSchedule = this.submitSchedule.bind(this);
    this.confirmPatient = this.confirmPatient.bind(this);
    this.fetchPasscodeLocation = this.fetchPasscodeLocation.bind(this);
    this.resetState = this.resetState.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleKTPFileChange = this.handleKTPFileChange.bind(this);
    this.setBirthdate = this.setBirthdate.bind(this);
    this.clearIdentityImg = this.clearIdentityImg.bind(this);
    this.deleteIdentityImg = this.deleteIdentityImg.bind(this);
    this.clearKTPImg = this.clearKTPImg.bind(this);
    this.goPage = this.goPage.bind(this);
    this.fetchHospitalList = this.fetchHospitalList.bind(this);
    this.fetchHospitalVaccineNo = this.fetchHospitalVaccineNo.bind(this);
    this.isLansia = this.isLansia.bind(this);

    this.state = {
      isLoading: null,
      user: reactLocalStorage.getObject('user', null),
      page: null,
      patientList: [],
      patientVal: null,

      vaccine: null,
      form: null,
    };
  }

  resetState(page = null) {
    this.setState(prevState => ({
      isLoading: false,
      page: page === null ? 'MAIN PAGE' : page,
      form: {
        ...prevState.form,
        vaccineLocation: '',
        passcode: '',
        passcodeLocation: '',
        hospitalVal: '',
        programList: [],
        programVal: '',
        professionList: [],
        professionVal: '',
        fullName: '',
        birthDate: '',
        genderList: ['Laki-Laki', 'Perempuan'],
        genderVal: '',
        phoneNo: '',
        email: '',
        ktpImg: null,
        ktpImgPreview: null,
        identityImg: [null],
        identityImgPreview: [null],
        // screening
        allowedVaccineNo: [],
        vaccineNo: '',
        alergic: '',
        alergicAfter: '',
        pregnant: '',
        autoimmune: '',
        blood: '',
        chemo: '',
        heart: '',
        stairs: '',
        fatique: '',
        illness: '',
        walking: '',
        weight: '',
        // schedule
        schedule: moment(),
        allowedDateList: [],
        hourList: [],
        hourVal: '',
        term1: false,
        term2: false,
        term3: false,
      }
    }), () => {
      let carouselList = document.querySelector("li.active");
      if(carouselList != null) carouselList.click();
    });
  }

  async fetchHospitalList() {
    try {
      let response = await axios.get(`${Config.API_URL}/hospital_vaccine_list`);
      let data = response.data;
      if(data.statusCode === 200) {
        this.setState(prevState => ({
          form: {
            ...prevState.form,
            hospitalList: data.data,
          },
        }));
        console.log(this.state.form.hospitalList);
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Terjadi kesalahan pada koneksi anda. Silahkan coba beberapa saat lagi dan pastikan koneksi internet bekerja dengan baik. ', 'error');
    }
  }

  async fetchHospitalVaccineNo(patient) {
    try {
      let formData = { };
      formData.location_vaccine_id = patient.location_vaccine_id;
      formData.hospital_name = patient.hospital_name;
      formData.type_vaccine = patient.type_vaccine;
      let response = await axios.post(`${Config.API_URL}/location_vaccine_status`, formData);
      let data = response.data;
      if(data.statusCode === 200) {
        let vaccineNo = data.data;
        let allowedVaccineNo = [];
        for(let i=0;i<vaccineNo.length;i++) allowedVaccineNo.push(vaccineNo[i].vaccine_status);
        this.setState(prevState => ({
          form: {
            ...prevState.form,
            allowedVaccineNo: allowedVaccineNo,
          }
        }));
        console.log(this.state.form.allowedVaccineNo);
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Terjadi kesalahan pada koneksi anda. Silahkan coba beberapa saat lagi dan pastikan koneksi internet bekerja dengan baik. ', 'error');
    }
  }

  async fetchHospitalProgram() {
    let index = this.state.form.hospitalVal;
    if(index === '') {
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          programList: [],
          programVal: '',
        }
      }));
      return;
    }
    let hospital = this.state.form.hospitalList[index];
    let formData = { };
    formData.location_vaccine_id = hospital.location_vaccine_id;
    formData.hospital_name = hospital.location_vaccine_name;
    try {
      let response = await axios.post(`${Config.API_URL}/location_vaccine_available`, formData);
      let data = response.data;
      if(data.statusCode === 200) {
        let programList = data.data;
        for(let i=0;i<programList.length;i++) {
          let item = programList[i];
          item.program_name = item.type_vaccine;
        }
        this.setState(prevState => ({
          form: {
            ...prevState.form,
            programList: programList,
          }
        }));
        console.log(this.state.form.programList);
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Terjadi kesalahan pada koneksi anda. Silahkan coba beberapa saat lagi dan pastikan koneksi internet bekerja dengan baik. ', 'error');
    }
  }

  async fetchHospitalProfession() {
    let index = this.state.form.hospitalVal;
    if(index === '') {
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          professionList: [],
          professionVal: '',
        }
      }));
      return;
    }
    let hospital = this.state.form.hospitalList[index];
    let formData = { };
    formData.location_vaccine_id = hospital.location_vaccine_id;
    formData.hospital_name = hospital.location_vaccine_name;
    try {
      let response = await axios.post(`${Config.API_URL}/location_vaccine_profession`, formData);
      let data = response.data;
      if(data.statusCode === 200) {
        let result = data.data;
        let professionList = [];
        for(let i=0;i<result.length;i++) professionList.push(result[i].profession);
        this.setState(prevState => ({
          form: {
            ...prevState.form,
            professionList: professionList,
          }
        }));
        console.log(this.state.form.professionList);
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Terjadi kesalahan pada koneksi anda. Silahkan coba beberapa saat lagi dan pastikan koneksi internet bekerja dengan baik. ', 'error');
    }
  }

  async fetchPatientList() {
    this.setState({patientList: []});
    try {
      let response = await axios.get(`${Config.API_URL}/list_data_vaccine_user/${this.props.match.params.userID}`);
      let data = response.data;
      if(data.statusCode === 200) {
        let patientList = data.data;
        for(let i=0;i<patientList.length;i++) {
          let patient = patientList[i];
          patient.full_name = patient.fullname;
          patient.birthdate = moment(patient.birthdate, 'DD/MM/YYYY');
          patient.phone = patient.phone_number;
          patient.vaccine_location = patient.category;
          patient.id = patient.register_vaccine_new_id;
          if(patient.vaccine_date != null) patient.vaccine_date = moment(patient.vaccine_date, 'DD/MM/YYYY').format("YYYY-MM-DD");
          patient.hour_start = patient.timeslot_start_time;
          patient.hour_end = patient.timeslot_end_time;
          patient.status_timeslot = patient.status;
          patient.status = patient.status_screening;
          if(patient.status === '' || patient.status === null) patient.status = 'PENDING';
          patient.program_name = patient.type_vaccine;
          patient.vaccine_no = patient.status_vaccine;
        }
        this.setState({ patientList: data.data });
        console.log(this.state.patientList);
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Terjadi kesalahan pada koneksi anda. Silahkan coba beberapa saat lagi dan pastikan koneksi internet bekerja dengan baik. ', 'error');
    }
  }

  async fetchPasscodeLocation() {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        passcodeLocation: '',
      }
    }));
    let formData = { };
    formData.card_id_number = this.state.form.passcode;
    formData.passcode = this.state.form.passcode;
    try {
      let response = await axios.post(`${Config.API_URL}/data_person_company`, formData);
      let data = response.data;
      if(data.statusCode === 200) {
        if(data.data.length === 0) return Swal.fire('Gagal', 'Passcode atau No. KTP tidak ditemukan', 'warning');
        let passcodeLocation = data.data[0];
        this.setState(prevState => ({
          form: {
            ...prevState.form,
            passcodeLocation: passcodeLocation,
            fullName: passcodeLocation.fullname ?? '',
            birthDate: moment(passcodeLocation.birthdate, 'YYYY/MM/DD').toDate(),
            genderVal: passcodeLocation.gender ?? '',
            phoneNo: passcodeLocation.phone_number ?? '',
            email: passcodeLocation.email ?? '',
          }
        }));
        console.log(this.state.form.passcodeLocation);
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Terjadi kesalahan pada koneksi anda. Silahkan coba beberapa saat lagi dan pastikan koneksi internet bekerja dengan baik. ', 'error');
    }
  }

  async fetchLocationVaccineCalendar() {
    let formData = { };
    formData.location_vaccine_id = this.state.patientVal.location_vaccine_id;
    formData.hospital_name = this.state.patientVal.hospital_name;
    formData.type_vaccine = this.state.patientVal.type_vaccine;
    formData.vaccine_status = this.state.patientVal.status_vaccine;
    let response = await axios.post(`${Config.API_URL}/location_vaccine_calendar`, formData);
    try {
      let data = response.data;
      if(data.statusCode === 200) {
        this.setState(prevState => ({
          form: {
            ...prevState.form,
            allowedDateList: data.data,
          },
        }));
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Terjadi kesalahan pada koneksi anda. Silahkan coba beberapa saat lagi dan pastikan koneksi internet bekerja dengan baik. ', 'error');
    }
  }

  async fetchLocationVaccineHour(date) {
    this.setState(prevState=>({
      form: {
        ...prevState.form,
        hourList: [],
        hourVal: '',
      }
    }))

    let formData = { };
    formData.location_vaccine_id = this.state.patientVal.location_vaccine_id;
    formData.hospitalName = this.state.patientVal.hospital_name;
    formData.date = moment(date).format('YYYY-MM-DD');
    formData.type_vaccine = this.state.patientVal.type_vaccine;
    let response = await axios.post(`${Config.API_URL}/get_data_timeslot`, formData);
    try {
      let data = response.data;
      if(data.statusCode === 200) {
        let hourList = data.data;
        for(let i=0;i<hourList.length;i++) {
          let hour = hourList[i];
          hour.hour_start = hour.timeslot_start_time;
          hour.hour_end = hour.timeslot_end_time;
        }
        this.setState(prevState => ({
          form: {
            ...prevState.form,
            hourList: hourList,
            hourVal: '',
          }
        }));
        console.log(this.state.form.hourList);
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Terjadi kesalahan pada koneksi anda. Silahkan coba beberapa saat lagi dan pastikan koneksi internet bekerja dengan baik. ', 'error');
    }
  }

  async submitNewPatient() {
    if(this.state.isLoading) return;
    let form = this.state.form;
    if(form.fullName === '') return Swal.fire('Wajib isi nama lengkap');
    if(form.birthDate === '') return Swal.fire('Wajib isi tanggal lahir');
    if(form.genderVal === '') return Swal.fire('Wajib isi jenis kelamin');
    if(form.phoneNo === '') return Swal.fire('Wajib isi nomor telpon');
    if(!validator.isMobilePhone(form.phoneNo, 'id-ID')) return Swal.fire('Nomor handphone tidak valid');
    if(!validator.isEmail(form.email)) return Swal.fire('Email tidak valid');
    if(form.vaccineLocation === 'RUMAH SAKIT') {
      if(form.programVal === '') return Swal.fire('Wajib pilih program vaksinasi');
      if(form.programList[form.programVal].program_name === 'Vaksinasi Pelayanan Publik (18-59 tahun)' && form.professionVal === '') return Swal.fire('Profesi wajib diisi');
      if(form.programList[form.programVal].program_name === 'Vaksinasi Umum (18-59 tahun)'  && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 18) return Swal.fire('Minimal usia pendaftar vaksin adalah 18 tahun');
      if(form.programList[form.programVal].program_name === 'Vaksinasi Umum (18-59 tahun)'  && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') > 59) return Swal.fire('Maximal usia pendaftar vaksin adalah 60 tahun');
      if(form.programList[form.programVal].program_name === 'Vaksinasi Umum (18+ tahun)' && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 18) return Swal.fire('Minimal usia pendaftar vaksin adalah 18 tahun');
      //if(form.programList[form.programVal].program_name !== 'Vaksinasi Umum (60+ tahun)' && form.programList[form.programVal].program_name !== 'Vaksinasi Umum (51-59 tahun)' && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 18) return Swal.fire('Minimal usia pendaftar vaksin adalah 18 tahun');
      if(form.programList[form.programVal].program_name === 'Vaksinasi Umum (51-59 tahun)' && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 50) return Swal.fire('Minimal usia pendaftar vaksininasi lansia adalah 50 sampai 59 tahun');
      if(form.programList[form.programVal].program_name === 'Vaksinasi Umum (51-59 tahun)' && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') > 59) return Swal.fire('Minimal usia pendaftar vaksininasi lansia adalah 50 sampai 59 tahun');
      if(form.programList[form.programVal].program_name === 'Vaksinasi Umum (60+ tahun)' && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 60) return Swal.fire('Minimal usia pendaftar vaksininasi lansia adalah 60 tahun');
      if(form.programList[form.programVal].program_name === 'Vaksinasi Umum (12+ tahun)' && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 12) return Swal.fire('Minimal usia pendaftar vaksininasi lansia adalah 12 tahun');
      if(form.programList[form.programVal].program_name === 'Vaksinasi Umum (12-17 tahun)' && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 12 && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') > 17) return Swal.fire('Minimal usia pendaftar vaksininasi lansia adalah 12 tahun sampai 17 tahun');
      if(form.programList[form.programVal].program_name === 'Vaksinasi Gotong Royong (18-59 tahun)'  && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 18 && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') > 59 ) return Swal.fire('Minimal usia pendaftar vaksin adalah 18 tahun sampai 59 tahun');
    } else {
      if(moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 18) return Swal.fire('Minimal usia pendaftar vaksin adalah 18 tahun')
    }
    if(form.ktpImg === null) return Swal.fire('Foto Kartu Identitas wajib diunggah');
    this.setState({isLoading: true});
    let formData = new FormData();
    formData.append('userId', this.props.match.params.userID);
    formData.append('category', form.vaccineLocation);
    formData.append('hospital_name', form.vaccineLocation === 'RUMAH SAKIT' ? form.hospitalList[form.hospitalVal].name : form.passcodeLocation.location_vaccine_company);
    formData.append('type_vaccine', form.vaccineLocation === 'RUMAH SAKIT' ? form.programList[form.programVal].program_name : 'Vaksinasi Perusahaan (18-59 tahun)');
    formData.append('fullname', form.fullName);
    formData.append('profession', form.vaccineLocation === 'RUMAH SAKIT' && form.programList[form.programVal].program_name === 'Vaksinasi Pelayanan Publik (18-59 tahun)' ? form.professionVal : '');
    formData.append('birthdate', moment(form.birthDate).format("DD-MM-YYYY"));
    formData.append('gender', form.genderVal);
    formData.append('phone_number', form.phoneNo);
    formData.append('email', form.email);
    formData.append('ktpPhotoPath', form.ktpImg);
    let imgIndex = 0;
    for(let i=0;i<this.state.form.identityImg.length;i++) {
      if(this.state.form.identityImg[i] == null) continue;
      formData.append('photoPath' + (imgIndex === 0 ? '' : imgIndex), this.state.form.identityImg[i]);
      imgIndex++;
    }
    try {
      let response = await axios.post(`${Config.API_URL}/create_register_vaccine_new`, formData);
      let data = response.data;
      if(data.statusCode === 200) {
        await this.fetchPatientList();
        this.setState({
          isLoading: false, patientVal: this.state.patientList[0]},
          () => this.resetState(form.vaccineLocation === 'PASSCODE' ? 'SCREENING VACCINE NO PAGE' : 'PATIENT DETAIL PAGE')
        );
        if(form.vaccineLocation === 'PASSCODE') this.fetchHospitalVaccineNo(this.state.patientList[0]);
      } else {
        await Swal.fire('Gagal', data.statusMessage, 'warning');
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Terjadi kesalahan pada koneksi anda. Silahkan coba beberapa saat lagi dan pastikan koneksi internet bekerja dengan baik. ', 'error');
    }
    this.setState({isLoading: false});
  }

  async editPatient() {
    let form = this.state.form;
    let patientID = this.state.patientVal.id;
    let age = moment().diff(form.birthDate, 'years');
    if(form.fullName === '') return Swal.fire('Wajib isi nama lengkap');
    if(form.birthDate === '') return Swal.fire('Wajib isi tanggal lahir');
    if(form.genderVal === '') return Swal.fire('Wajib isi jenis kelamin');
    if(form.phoneNo === '') return Swal.fire('Wajib isi nomor telpon');
    if(!validator.isMobilePhone(form.phoneNo, 'id-ID')) return Swal.fire('Nomor handphone tidak valid');
    if(!validator.isEmail(form.email)) return Swal.fire('Email tidak valid');


    if(this.state.patientVal.type_vaccine === 'Vaksinasi Pelayanan Publik (18-59 tahun)' && form.professionVal === '') return Swal.fire('Profesi wajib diisi');
    if(this.state.patientVal.type_vaccine === 'Vaksinasi Umum (18-59 tahun)'  && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 18) return Swal.fire('Minimal usia pendaftar vaksin adalah 18 tahun');
    if(this.state.patientVal.type_vaccine === 'Vaksinasi Umum (18-59 tahun)'  && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') > 59) return Swal.fire('Maximal usia pendaftar vaksin adalah 60 tahun');
    if(this.state.patientVal.type_vaccine === 'Vaksinasi Umum (18+ tahun)' && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 18) return Swal.fire('Minimal usia pendaftar vaksin adalah 18 tahun');
   // if(this.state.patientVal.type_vaccine !== 'Vaksinasi Umum (60+ tahun)' && this.state.patientVal.type_vaccine !== 'Vaksinasi Umum (51-59 tahun)' && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 18) return Swal.fire('Minimal usia pendaftar vaksin adalah 18 tahun');
    if(this.state.patientVal.type_vaccine === 'Vaksinasi Umum (51-59 tahun)' && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 50) return Swal.fire('Minimal usia pendaftar vaksininasi lansia adalah 50 sampai 59 tahun');
    if(this.state.patientVal.type_vaccine === 'Vaksinasi Umum (51-59 tahun)' && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') > 59) return Swal.fire('Minimal usia pendaftar vaksininasi lansia adalah 50 sampai 59 tahun');
    if(this.state.patientVal.type_vaccine === 'Vaksinasi Umum (60+ tahun)' && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 60) return Swal.fire('Minimal usia pendaftar vaksininasi lansia adalah 60 tahun');
    if(this.state.patientVal.type_vaccine === 'Vaksinasi Umum (12+ tahun)' && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 12) return Swal.fire('Minimal usia pendaftar vaksininasi lansia adalah 12 tahun');
    if(this.state.patientVal.type_vaccine === 'Vaksinasi Umum (12-17 tahun)' && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 12 && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') > 17) return Swal.fire('Minimal usia pendaftar vaksininasi lansia adalah 12 tahun sampai 17 tahun');
    if(this.state.patientVal.type_vaccine === 'Vaksinasi Gotong Royong (18-59 tahun)'  && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 18 && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') > 59 ) return Swal.fire('Minimal usia pendaftar vaksin adalah 18 tahun sampai 59 tahun');


    // if(this.state.patientVal.type_vaccine === 'Vaksinasi Pelayanan Publik (18-59 tahun)' && form.professionVal === '') return Swal.fire('Profesi wajib diisi');
    // if(this.state.patientVal.type_vaccine === 'Vaksinasi Umum (18-59 tahun)'  && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 18) return Swal.fire('Minimal usia pendaftar vaksin adalah 18 tahun');
    // if(this.state.patientVal.type_vaccine === 'Vaksinasi Umum (18-59 tahun)'  && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') > 59) return Swal.fire('Maximal usia pendaftar vaksin adalah 59 tahun');
    // if(this.state.patientVal.type_vaccine !== 'Vaksinasi Umum (60+ tahun)' && this.state.patientVal.type_vaccine !== 'Vaksinasi Umum (51-59 tahun)' && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 18) return Swal.fire('Minimal usia pendaftar vaksin adalah 18 tahun');
    // if(this.state.patientVal.type_vaccine === 'Vaksinasi Umum (51-59 tahun)' && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 50) return Swal.fire('Minimal usia pendaftar vaksininasi lansia adalah 50 sampai 59 tahun');
    // if(this.state.patientVal.type_vaccine === 'Vaksinasi Umum (51-59 tahun)' && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') > 59) return Swal.fire('Minimal usia pendaftar vaksininasi lansia adalah 50 sampai 59 tahun');
    // if(this.state.patientVal.type_vaccine === 'Vaksinasi Umum (60+ tahun)' && moment().diff(moment(form.birthDate).format('YYYY-MM-DD'), 'years') < 59) return Swal.fire('Minimal usia pendaftar vaksininasi lansia adalah 59 tahun');
    this.setState({isLoading: true});
    let formData = new FormData();
    formData.append('userId', this.state.patientVal.userId);
    formData.append('category', this.state.patientVal.vaccine_location);
    formData.append('hospital_name', form.hospitalVal != '' ? form.hospitalList[form.hospitalVal].name : this.state.patientVal.hospital_name);
    formData.append('type_vaccine', form.programVal != '' ? form.programList[form.programVal].program_name : this.state.patientVal.type_vaccine);
    formData.append('fullname', form.fullName);
    formData.append('profession', form.professionVal != '' ? form.professionVal : this.state.patientVal.profession);
    formData.append('birthdate', moment(form.birthDate).format("DD-MM-YYYY"));
    formData.append('gender', form.genderVal);
    formData.append('phone_number', form.phoneNo);
    formData.append('email', form.email);
    formData.append('status_vaccine', this.state.patientVal.status_vaccine);
    formData.append('is_alergic', this.state.patientVal.is_alergic);
    formData.append('is_pregnant', this.state.patientVal.is_pregnant);
    formData.append('is_autoimun', this.state.patientVal.is_autoimun);
    formData.append('is_blood_disorders', this.state.patientVal.is_blood_disorders);
    formData.append('is_immunosupressant', this.state.patientVal.is_immunosupressant);
    formData.append('is_have_disease', this.state.patientVal.is_have_disease);
    formData.append('is_hard_climb_stair', this.state.patientVal.is_hard_climb_stair);
    formData.append('is_fatigue', this.state.patientVal.is_fatigue);
    formData.append('is_many_diseases', this.state.patientVal.is_many_diseases);
    formData.append('is_walk', this.state.patientVal.is_walk);
    formData.append('is_drop_weight', this.state.patientVal.is_drop_weight);
    formData.append('status', this.state.patientVal.status);
    if(form.ktpImg != null) formData.append('ktpPhotoPath', form.ktpImg);
    let imgIndex = 0;
    for(let i=0;i<this.state.form.identityImg.length;i++) {
      if(this.state.form.identityImg[i] == null) continue;
      formData.append('photoPath' + (imgIndex === 0 ? '' : imgIndex), this.state.form.identityImg[i]);
      imgIndex++;
    }
    try {
      let response = await axios.post(`${Config.API_URL}/edit_register_vaccine_new/${patientID}`, formData);
      let data = response.data;
      if(data.statusCode === 200) {
        await this.fetchPatientList();
        for(let i=0;i<this.state.patientList.length;i++) {
          let item = this.state.patientList[i];
          if(item.id === patientID) {
            this.setState({ patientVal: item });
            break;
          }
        }
        this.resetState('PATIENT DETAIL PAGE');
      } else {
        await Swal.fire('Gagal', data.statusMessage, 'warning');
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Terjadi kesalahan pada koneksi anda. Silahkan coba beberapa saat lagi dan pastikan koneksi internet bekerja dengan baik. ', 'error');
    }
    this.setState({isLoading: false});
  }

  // async updateHospitalSelection() {
  //   if(this.state.isLoading) return;
  //   let form = this.state.form;
  //   this.setState({isLoading: true});
  //   let formData = new FormData();
  //   formData.append('task', 'updateHospitalSelection');
  //   formData.append('patientID', this.state.patientVal.id);
  //   formData.append('hospitalID', form.hospitalList[form.hospitalVal].location_vaccine_id);
  //   formData.append('hospitalName', form.hospitalList[form.hospitalVal].location_vaccine_name);
  //   try {
  //     let response = await axios.post(`https://yerapi.digitalnative.id/api.php`, formData);
  //     let data = response.data;
  //     if(data.statusCode === 200) {
  //       await this.fetchPatientList();
  //       this.setState({
  //         isLoading: false, patientVal: this.state.patientList[0]},
  //         () => this.resetState('PATIENT DETAIL PAGE')
  //       );
  //     } else {
  //       await Swal.fire('Gagal', data.statusMessage, 'warning');
  //     }
  //   } catch(error) {
  //     await Swal.fire('Gagal', 'Terjadi kesalahan pada koneksi anda. Silahkan coba beberapa saat lagi dan pastikan koneksi internet bekerja dengan baik. ', 'error');
  //   }
  //   this.setState({isLoading: false});
  // }

  isScreeningRejected() {
    let form = this.state.form;
    let yesAnswer = 0;
    if(form.vaccineNo === 'PERTAMA' && form.alergic === 'Ya') yesAnswer++;
    if(form.vaccineNo === 'KEDUA' && form.alergicAfter === 'Ya') yesAnswer++;
    if(form.pregnant === 'Ya') yesAnswer++;
    if(form.autoimmune === 'Ya') yesAnswer++;
    if(form.blood === 'Ya') yesAnswer++;
    if(form.chemo === 'Ya') yesAnswer++;
    if(form.heart === 'Ya') yesAnswer++;
    if(form.stairs === 'Ya') yesAnswer++;
    if(form.fatique === 'Ya') yesAnswer++;
    if(form.illness === 'Ya') yesAnswer++;
    if(form.walking === 'Ya') yesAnswer++;
    if(form.weight === 'Ya') yesAnswer++;
    return yesAnswer >= 1;
  }

  isScreeningRejected2() {
    let form = this.state.form;
    let yesAnswer = 0;
    if(form.vaccineNo === 'PERTAMA' && form.alergic === 'Ya') yesAnswer++;
    if(form.vaccineNo === 'KEDUA' && form.alergicAfter === 'Ya') yesAnswer++;
    if(form.pregnant === 'Ya') yesAnswer++;
    if(form.autoimmune === 'Ya') yesAnswer++;
    if(form.blood === 'Ya') yesAnswer++;
    if(form.chemo === 'Ya') yesAnswer++;
    if(form.heart === 'Ya') yesAnswer++;
    return yesAnswer >= 1;
  }

  async submitScreening() {
    let form = this.state.form;
    let patientID = this.state.patientVal.id;
    let status;
    if(this.isLansia(this.state.patientVal)) status = this.isScreeningRejected() ? 'REJECTED' : 'ACCEPTED';
    else status = this.isScreeningRejected2() ? 'REJECTED' : 'ACCEPTED';
    if(status === 'REJECTED') {
      let confirm = await Swal.fire({
        position: 'bottom',
        title: 'Saya memiliki surat persetujuan dari dokter spesialis untuk vaksin ?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Ya`,
        denyButtonText: `Tidak`,
      });
      if(confirm.isConfirmed === true) status = 'ACCEPTED';
      else if(confirm.isDenied === true) status = 'REJECTED';
      else return;
    }
    this.setState({isLoading: true});
    let formData = new FormData();
    formData.append('userId', this.state.patientVal.userId);
    formData.append('category', this.state.patientVal.vaccine_location);
    formData.append('location_vaccine_id', this.state.patientVal.location_vaccine_id);
    formData.append('hospital_name', this.state.patientVal.hospital_name);
    formData.append('type_vaccine', this.state.patientVal.type_vaccine);
    formData.append('fullname', this.state.patientVal.fullname);
    formData.append('profession', this.state.patientVal.profession);
    formData.append('birthdate', moment(this.state.patientVal.birthdate).format("DD-MM-YYYY"));
    formData.append('gender', this.state.patientVal.gender);
    formData.append('phone_number', this.state.patientVal.phone);
    formData.append('email', this.state.patientVal.email);
    formData.append('status_vaccine', form.vaccineNo);
    formData.append('is_alergic', form.alergic);
    formData.append('is_pregnant', form.pregnant);
    formData.append('is_autoimun', form.autoimmune);
    formData.append('is_blood_disorders', form.blood);
    formData.append('is_immunosupressant', form.chemo);
    formData.append('is_have_disease', form.heart);
    formData.append('is_hard_climb_stair', form.stairs);
    formData.append('is_fatigue', form.fatique);
    formData.append('is_many_diseases', form.illness);
    formData.append('is_walk', form.walking);
    formData.append('is_drop_weight', form.weight);
    formData.append('status', status);
    try {
      let response = await axios.post(`${Config.API_URL}/edit_register_vaccine_new/${patientID}`, formData);
      let data = response.data;
      if(data.statusCode === 200) {
        await this.fetchPatientList();
        for(let i=0;i<this.state.patientList.length;i++) {
          let item = this.state.patientList[i];
          if(item.id === patientID) {
            this.setState({ patientVal: item });
            break;
          }
        }
        if(status === 'ACCEPTED') this.goPage('SCREENING SUCCESS PAGE');
        if(status === 'REJECTED') this.goPage('SCREENING FAILED PAGE');
      } else {
        await Swal.fire('Gagal', data.statusMessage, 'warning');
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Terjadi kesalahan pada koneksi anda. Silahkan coba beberapa saat lagi dan pastikan koneksi internet bekerja dengan baik. ', 'error');
    }
    this.setState({isLoading: false});
  }

  async submitScreening2() {
    let form = this.state.form;
    let patientID = this.state.patientVal.id;
    let status = this.isScreeningRejected2() ? 'REJECTED' : 'ACCEPTED';
    if(status === 'REJECTED') {
      let confirm = await Swal.fire({
        position: 'bottom',
        title: 'Saya memiliki surat persetujuan dari dokter spesialis untuk vaksin ?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Ya`,
        denyButtonText: `Tidak`,
      });
      if(confirm.isConfirmed === true) status = 'ACCEPTED';
      else if(confirm.isDenied === true) status = 'REJECTED';
      else return;
    }
    this.setState({isLoading: true});
    let formData = new FormData();
    formData.append('userId', this.state.patientVal.userId);
    formData.append('category', this.state.patientVal.vaccine_location);
    formData.append('location_vaccine_id', this.state.patientVal.location_vaccine_id);
    formData.append('hospital_name', this.state.patientVal.hospital_name);
    formData.append('type_vaccine', this.state.patientVal.type_vaccine);
    formData.append('fullname', this.state.patientVal.fullname);
    formData.append('profession', this.state.patientVal.profession);
    formData.append('birthdate', moment(this.state.patientVal.birthdate).format("DD-MM-YYYY"));
    formData.append('gender', this.state.patientVal.gender);
    formData.append('phone_number', this.state.patientVal.phone);
    formData.append('email', this.state.patientVal.email);
    formData.append('status_vaccine', form.vaccineNo);
    formData.append('is_alergic', form.alergic);
    formData.append('is_pregnant', form.pregnant);
    formData.append('is_autoimun', form.autoimmune);
    formData.append('is_blood_disorders', form.blood);
    formData.append('is_immunosupressant', form.chemo);
    formData.append('is_have_disease', form.heart);
    formData.append('is_hard_climb_stair', '');
    formData.append('is_fatigue', '');
    formData.append('is_many_diseases', '');
    formData.append('is_walk', '');
    formData.append('is_drop_weight', '');
    formData.append('status', status);
    try {
      let response = await axios.post(`${Config.API_URL}/edit_register_vaccine_new/${patientID}`, formData);
      let data = response.data;
      if(data.statusCode === 200) {
        await this.fetchPatientList();
        for(let i=0;i<this.state.patientList.length;i++) {
          let item = this.state.patientList[i];
          if(item.id === patientID) {
            this.setState({ patientVal: item });
            break;
          }
        }
        if(status === 'ACCEPTED') this.goPage('SCREENING SUCCESS PAGE');
        if(status === 'REJECTED') this.goPage('SCREENING FAILED PAGE');
      } else {
        await Swal.fire('Gagal', data.statusMessage, 'warning');
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Terjadi kesalahan pada koneksi anda. Silahkan coba beberapa saat lagi dan pastikan koneksi internet bekerja dengan baik. ', 'error');
    }
    this.setState({isLoading: false});
  }

  async submitSchedule() {
    let form = this.state.form;
    let patientID = this.state.patientVal.id;
    this.setState({isLoading: true});
    let formData = { };
    formData.register_vaccine_new_id = patientID;
    formData.userId = this.state.patientVal.userId;
    formData.timeslot_id = form.hourVal.timeslot_id;
    formData.date = moment(form.schedule).format('YYYY-MM-DD');
    try {
      let response = await axios.post(`${Config.API_URL}/create_schedule_vaccine`, formData);
      let data = response.data;
      if(data.statusCode === 200) {
        await this.fetchPatientList();
        for(let i=0;i<this.state.patientList.length;i++) {
          let item = this.state.patientList[i];
          if(item.id === patientID) {
            this.setState({ patientVal: item });
            break;
          }
        }
        this.goPage('PATIENT DETAIL PAGE');
      } else {
        await Swal.fire('Gagal', data.statusMessage, 'warning');
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Terjadi kesalahan pada koneksi anda. Silahkan coba beberapa saat lagi dan pastikan koneksi internet bekerja dengan baik. ', 'error');
    }
    this.setState({isLoading: false});
  }

  async confirmPatient() {
    this.setState({isLoading: true});
    try {
      let response = await axios.post(`${Config.API_URL}/confirm_register_vaccine/${this.state.patientVal.id}`);
      let data = response.data;
      if(data.statusCode === 200) {
        await this.fetchPatientList();
        for(let i=0;i<this.state.patientList.length;i++) {
          let item = this.state.patientList[i];
          if(item.id === this.state.patientVal.id) {
            this.setState({ patientVal: item });
            break;
          }
        }
      } else {
        await Swal.fire('Gagal', data.statusMessage, 'warning');
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Terjadi kesalahan pada koneksi anda. Silahkan coba beberapa saat lagi dan pastikan koneksi internet bekerja dengan baik. ', 'error');
    }
    this.setState({isLoading: false});
  }

  async componentDidMount() {
    window.location.href = `${Config.BASE_URL}/vaksin`
    // if(this.state.user != null && Number(this.state.user.userId) !== Number(this.props.match.params.userID)) window.location.href = `${Config.BASE_URL}/vaccine/${this.state.user.userId}`;
    // this.resetState();
    // this.fetchPatientList();
    // this.fetchHospitalList();
  }

  setBirthdate(date) {
    this.state.form.birthDate = moment(date).toDate();
    this.setState({ form: this.state.form });
  }

  handleFormChange(event, callback = null) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    if(callback == null) this.setState(prevState => ({form: {...prevState.form, [name]: value}}));
    else this.setState(prevState => ({form: {...prevState.form, [name]: value}}), callback);
  }

  handleFileChange(event, index) {
    let context = this;
    let file = event.target.files[0];
    if(file != null) {
      this.state.form.identityImg[index] = file;
      let reader = new FileReader();
      reader.addEventListener("load", function() {
        context.state.form.identityImgPreview[index] = reader.result;
        if(context.state.form.identityImg[context.state.form.identityImg.length - 1] != null && context.state.form.identityImg.length < 5) {
          context.state.form.identityImg.push(null);
          context.state.form.identityImgPreview.push(null);
        }
        context.setState({form: context.state.form});
      }, false);
      reader.readAsDataURL(file);
    } else {
      this.state.form.identityImg[index] = null;
      this.state.form.identityImgPreview[index] = null;
    }
    this.setState({form: this.state.form});
  }

  handleKTPFileChange(event) {
    let context = this;
    let file = event.target.files[0];
    if(file != null) {
      this.state.form.ktpImg = file;
      let reader = new FileReader();
      reader.addEventListener("load", function() {
        context.state.form.ktpImgPreview = reader.result;
        context.setState({form: context.state.form});
      }, false);
      reader.readAsDataURL(file);
    } else {
      this.state.form.ktpImg = null;
      this.state.form.ktpImgPreview = null;
    }
    this.setState({form: this.state.form});
  }

  clearIdentityImg(index) {
    this.state.form.identityImg[index] = null;
    this.state.form.identityImgPreview[index] = null;
    if(index !== 0) {
      this.state.form.identityImg.splice(index, 1);
      this.state.form.identityImgPreview.splice(index, 1);
    }
    this.setState({ form: this.state.form });
  }

  async deleteIdentityImg(img) {
    let confirm = await Swal.fire({
      title: 'Hapus unggahan sebelumnya?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya'
    });
    if(!confirm.value) return;
    this.setState({isLoading: true});
    try {
      let response = await axios.post(`${Config.API_URL}/delete_photo_user/${img.user_photo_id}}`);
      let data = response.data;
      if(data.statusCode === 200) {
        await this.fetchPatientList();
        for(let i=0;i<this.state.patientList.length;i++) {
          let item = this.state.patientList[i];
          if(item.id === this.state.patientVal.id) {
            this.setState({ patientVal: item });
            break;
          }
        }
      } else {
        await Swal.fire('Gagal', data.statusMessage, 'warning');
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Terjadi kesalahan pada koneksi anda. Silahkan coba beberapa saat lagi dan pastikan koneksi internet bekerja dengan baik. ', 'error');
    }
    this.setState({isLoading: false});
  }

  clearKTPImg() {
    this.state.form.ktpImg = null;
    this.setState({ form: this.state.form });
  }

  goPage(page) {
    if(page === 'SCHEDULE SELECT PAGE') {
      this.fetchLocationVaccineCalendar();
    } else if(page === 'PATIENT DETAIL PAGE') {
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          schedule: this.state.patientVal.vaccine_date !== null ? moment(this.state.patientVal.vaccine_date) : moment(),
        }
      }));
    } else if(page === 'PATIENT DATA FORM PAGE') {
      this.fetchHospitalProgram();
      this.fetchHospitalProfession();
    } else if(page === 'PATIENT EDIT FORM PAGE') {
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          fullName: this.state.patientVal.full_name,
          genderVal: this.state.patientVal.gender,
          birthDate: moment(this.state.patientVal.birthdate).toDate(),
          phoneNo: this.state.patientVal.phone,
          email: this.state.patientVal.email,
        }
      }));
    } else if(page === 'HOSPITAL SELECTION EDIT PAGE') {
      let index;
      for(let i=0;i<this.state.form.hospitalList.length;i++) {
        let hospital = this.state.form.hospitalList[i];
        if(hospital.location_vaccine_name === this.state.patientVal.hospital_name) {
          index = i; break;
        }
      }
      if(index == null) index = "";
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          hospitalVal: index,
        }
      }));
    } else if(page === 'PATIENT EDIT FORM PAGE 2') {
      let context = this;
      this.fetchHospitalProgram().then(function() {
        let index;
        for(let i=0;i<context.state.form.programList.length;i++) {
          let item = context.state.form.programList[i];
          if(item.program_name === context.state.patientVal.type_vaccine) {
            index = i;
          }
        }
        if(index != null) {
          context.setState(prevState => ({
            form: {
              ...prevState.form,
              programVal: index,
              professionVal: context.state.patientVal.profession,
            }
          }));
        }
      });
      this.fetchHospitalProfession();

      this.setState(prevState => ({
        form: {
          ...prevState.form,
          fullName: this.state.patientVal.full_name,
          genderVal: this.state.patientVal.gender,
          birthDate: moment(this.state.patientVal.birthdate).toDate(),
          phoneNo: this.state.patientVal.phone,
          email: this.state.patientVal.email,
        }
      }));
    } else if(page === 'SCREENING VACCINE NO PAGE') {
      let context = this;
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          allowedVaccineNo: [],
          vaccineNo: '',
        }
      }));
      this.fetchHospitalVaccineNo(this.state.patientVal);
    }
    this.setState({page: page});
  }

  mainPage() {
    return (
    <div className="main-page">
      <div className="topbar">
        <div style={{minWidth: '16px'}}></div>
        <div onClick={() => window.location.href = `${Config.BASE_URL}/vaccine-close`} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
        <div className="text">Skrining Vaksin</div>
        <div style={{minWidth: '40px'}}></div>
      </div>
      <div className="container">
        <p className="blue-text">Daftar Pasien Vaksinasi COVID-19</p>
        <div id="main-carousel" className="carousel slide" data-ride="carousel" data-interval="false">
          <ul className="carousel-indicators">
          <li data-target="#main-carousel" data-slide-to={0} className={`${this.state.patientList.length >= 0 ? `active` : ``}`}></li>
            {this.state.patientList.map((item, index) => (
              <li key={index+1} data-target="#main-carousel" data-slide-to={index+1}></li>
            ))}
          </ul>
          <div className="carousel-inner">
            <div className={`carousel-item ${this.state.patientList.length >= 0 ? `active` : ``}`}>
                <div onClick={() => this.goPage('VACCINE LOCATION SELECT PAGE')} className="add-new-patient clickable">
                  <div className="d-flex justify-content-center">
                    <div className="icon"><img src={`${Config.BASE_URL}/img/add-icon-green.png`} /></div>
                    <div style={{minWidth: '4px'}}></div>
                    <div className="text align-self-center">Tambah Daftar Pasien</div>
                  </div>
                </div>
              </div>
            {this.state.patientList.map((item, index) => (
              <div key={index+1} className={`carousel-item`}>
                <div className="patient-card">
                  <div className="program-name">{item.program_name}</div>
                  <div className="hospital-name">{item.hospital_name}</div>
                  <div style={{minHeight: '4px'}}></div>
                  <div className="fullname">{item.full_name}</div>
                  <div className="birthdate">{moment(item.birthdate).format('DD MMMM YYYY')} ({moment().diff(moment(item.birthdate).format('YYYY-MM-DD'), 'years')} Tahun)</div>
                  <div className="gender">{item.gender}</div>
                  <div className="phone">{item.phone}</div>
                  <div className="email">{item.email}</div>
                  <div style={{minHeight: '8px'}}></div>
                  <div className="text">Kuisioner Skrining Vaksin</div>
                  <div style={{minHeight: '8px'}}></div>
                  {item.status === 'PENDING' && <div onClick={() => {
                    this.setState({
                      patientVal: item
                    }, () => this.goPage('SCREENING WELCOME PAGE'));
                  }} className="white-button clickable">
                    <div className="left">Isi Kuisioner Skrining</div>
                    <div className="right"><img src={`${Config.BASE_URL}/img/arrow-right.png`} /></div>
                  </div>}
                  {item.status === 'ACCEPTED' && <div className="green-notice">Lolos Skrining</div>}
                  {item.status === 'REJECTED' && <div className="red-notice">Tidak Lolos Skrining</div>}
                  <div style={{minHeight: '8px'}}></div>
                  {item.vaccine_no != null && <div className="vaccine-no">{item.vaccine_no === 'PERTAMA' ? 'Vaksin Pertama' : 'Vaksin Kedua'}</div>}
                  <div style={{minHeight: '8px'}}></div>
                  {item.vaccine_date != null && <>
                    <div className="schedule-text">Jadwal</div>
                    <div style={{minHeight: '8px'}}></div>
                    <div className="d-flex">
                      <div className="img"><img src={`${Config.BASE_URL}/img/calendar-black-icon.png`} /></div>
                      <div style={{minWidth: '4px'}}></div>
                      <div className="vaccine-date">{moment(item.vaccine_date).format("DD MMMM YYYY")}</div>
                    </div>
                  </>}
                  <div onClick={() => {
                    this.setState({
                      patientVal: item
                    }, () => this.goPage('PATIENT DETAIL PAGE'));
                  }} className="view-detail clickable">Lihat detail</div>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </div>);
  }

  vaccineLocationSelectPage() {
    return (
    <div className="vaccine-location-select-page">
      <div className="topbar">
        <div style={{minWidth: '16px'}}></div>
        <div onClick={() => this.resetState('MAIN PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
        <div className="text">Skrining Vaksin</div>
        <div style={{minWidth: '40px'}}></div>
      </div>
      <div className="container flex-grow-1 d-flex flex-column">
        <div className="choose-text">Pilih Jalur Vaksinasi :</div>
        <div style={{minHeight: '20px'}}></div>
        <div className="company-vaccine">
          <div className="blue-text">Vaksinasi Perusahaan</div>
          <div style={{minHeight: '8px'}}></div>
          <p>Apakah kamu memiliki Passcode atau terdaftar di perusahaan ?</p>
          <div style={{minHeight: '12px'}}></div>
          <p className="blue-subtext">Jika iya, Masukkan Passcode atau No. KTP</p>
          <div style={{minHeight: '4px'}}></div>
          <div className="form-group">
            <input value={this.state.form.passcode} onChange={this.handleFormChange} name="passcode" type="text" className="custom-blue-input" placeholder="Masukkan Passcode atau No. KTP"/>
          </div>
          <div style={{minHeight: '12px'}}></div>
          <div className="form-group">
            <button onClick={async () => {
              await this.fetchPasscodeLocation();
              if(this.state.form.passcodeLocation !== '') {
                this.setState(prevState => ({ form: { ...prevState.form, vaccineLocation: 'PASSCODE' } }));
                this.goPage('PATIENT DATA FORM PAGE 2');
              }
            }} className="color-button w-100">Cek Lokasi</button>
          </div>
          <div style={{minHeight: '8px'}}></div>
        </div>
        <div style={{minHeight: '8px'}}></div>
        <p className="blue-text text-center">atau</p>
        <div style={{minHeight: '8px'}}></div>
        <div className="general-vaccine">
          <div className="blue-text">Vaksinasi Umum</div>
          <div style={{minHeight: '16px'}}></div>
          <div className="d-flex">
            <div className="img align-self-center"><img src={`${Config.BASE_URL}/img/hospital-icon.png`} alt=""/></div>
            <div style={{minWidth: '16px'}}></div>
            <div className="text">Program vaksinasi lansia dan program vaksinasi pemerintah lainnya (umum).</div>
          </div>
          <div style={{minHeight: '16px'}}></div>
          <div className="action">
            <button onClick={() => { this.setState(prevState => ({ form: { ...prevState.form, vaccineLocation: 'RUMAH SAKIT' } })); this.goPage('HOSPITAL SELECTION PAGE'); }} className="border-button">Pilih Lokasi</button>
          </div>
        </div>
      </div>
      <div style={{minHeight: '28px'}}></div>
    </div>);
  }

  hospitalSelectionPage() {
    console.log(this.state.form);
    return (
    <div className="hospital-selection-page">
      <div className="topbar">
        <div style={{minWidth: '16px'}}></div>
        <div onClick={() => { this.setState(prevState => ({form: { ...prevState.form, hospitalVal: '', programList: []}})); this.goPage('VACCINE LOCATION SELECT PAGE') }} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
        <div className="text">Skrining Vaksin</div>
        <div style={{minWidth: '40px'}}></div>
      </div>
      <div className="select-hospital">
        <div className="container">
          <p>Pilih Rumah Sakit untuk melihat Program Vaksinasi COVID-19 dan jadwal yang tersedia</p>
          <div className="form-group">
            <label>Rumah Sakit</label>
            <select name="hospitalVal" value={this.state.form.hospitalVal} onChange={this.handleFormChange} className="input-field">
              <option value=''>Pilih Rumah Sakit</option>
              {this.state.form.hospitalList.map((item, index) => (
                <option key={index} value={index}>{item.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{__html: this.state.form.hospitalVal === '' ? '' : this.state.form.hospitalList[this.state.form.hospitalVal] === null ? '': this.state.form.hospitalList[this.state.form.hospitalVal].description  }} className="container flex-grow-1 d-flex flex-column justify-content-start overflow">
      </div>

      <div className="form-group action">
        <button onClick={() => {
          if(this.state.form.hospitalVal === '') return Swal.fire('Rumah Sakit Wajib dipilih');
          this.goPage('PATIENT DATA FORM PAGE');
        }} className="color-button">Pilih</button>
      </div>
    </div>);
  }

  hospitalSelectionEditPage() {
    return (
    <div className="hospital-selection-page">
      <div className="topbar">
        <div style={{minWidth: '16px'}}></div>
        <div onClick={() => this.resetState('PATIENT DETAIL PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
        <div className="text">Skrining Vaksin</div>
        <div style={{minWidth: '40px'}}></div>
      </div>
      <div className="select-hospital">
        <div className="container">
          <p>Pilih Rumah Sakit untuk melihat Program Vaksinasi COVID-19 dan jadwal yang tersedia</p>
          <div className="form-group">
            <label>Rumah Sakit</label>
            <select name="hospitalVal" value={this.state.form.hospitalVal} onChange={(e) => { this.handleFormChange(e, () => { this.fetchHospitalProgram(); this.fetchHospitalProfession(); }); }} className="input-field">
              { this.state.form.hospitalList.length > 0 && this.state.form.hospitalList.map((item, index) => (
                <option key={index} value={index}>{item.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
       <div dangerouslySetInnerHTML={{__html: this.state.form.hospitalVal === '' ? '' : this.state.form.hospitalList[this.state.form.hospitalVal] === null ? '': this.state.form.hospitalList[this.state.form.hospitalVal].description  }} className="container flex-grow-1 d-flex flex-column justify-content-start overflow">
      </div>
      <div className="form-group action">
        <button onClick={() => this.goPage('PATIENT EDIT FORM PAGE 2')} className="color-button">Pilih</button>
      </div>
    </div>);
  }

  patientDataFormPage() {
    return (
    <div className="patient-data-form-page">
      <div className="topbar">
        <div style={{minWidth: '16px'}}></div>
        <div onClick={() => {
          this.setState(prevState => ({
            form: {
              ...prevState.form,
              programVal: '',
              fullName: '',
              birthDate: '',
              genderVal: '',
              phoneNo: '',
              email: '',
              identityImg: [null],
              identityImgPreview: [null],
              ktpImg: null,
              ktpImgPreview: null,
            }
          }));
          this.goPage('HOSPITAL SELECTION PAGE');
        }} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
        <div className="text">Skrining Vaksin</div>
        <div style={{minWidth: '40px'}}></div>
      </div>
      <div className="d-flex flex-column h-100 overflow flex-grow-1">
        <div className="select-hospital">
          <div className="container">
            <div className="form-group">
              <label>Lokasi Vaksinasi</label>
              <select name="hospitalVal" value={this.state.form.hospitalVal} onChange={(e) => { this.handleFormChange(e, () => { this.fetchHospitalProgram(); this.fetchHospitalProfession(); }); this.setState(prevState => ({ form: { ...prevState.form, programVal: '', professionVal: '' } })) }} className="input-field">
                {this.state.form.hospitalList.map((item, index) => (
                  <option key={index} value={index}>{item.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Pilih Program Vaksinasi</label>
              <select name="programVal" value={this.state.form.programVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih Program Vaksinasi</option>
                {this.state.form.programList.map((item, index) => (
                  <option key={index} value={index}>{item.program_name}</option>
                ))}
              </select>
              {this.state.form.programVal !== '' && <div className="program-note text-danger"><small><em>{this.state.form.programList[this.state.form.programVal].noted}</em></small></div>}
            </div>
            {this.state.form.programVal !== '' && this.state.form.programList[this.state.form.programVal].program_name === 'Vaksinasi Pelayanan Publik (18-59 tahun)' && <div className="form-group">
            <label>Kategori</label>
              <select name="professionVal" value={this.state.form.professionVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih Kategori</option>
                {this.state.form.professionList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>}

            {this.state.form.programVal !== '' && this.state.form.programList[this.state.form.programVal].program_name === 'Vaksinasi Gotong Royong (18-59 tahun)' && <div className="form-group">
            <label>Kategori</label>
              <select name="professionVal" value={this.state.form.professionVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih Kategori</option>
                {this.state.form.professionList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>}

            {this.state.form.programVal !== '' && this.state.form.programList[this.state.form.programVal].program_name === 'Vaksinasi Umum (18+ tahun)' && <div className="form-group">
              <label>Kategori</label>
              <select name="professionVal" value={this.state.form.professionVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih Kategori</option>
                {this.state.form.professionList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>}

                {this.state.form.programVal !== '' && this.state.form.programList[this.state.form.programVal].program_name === 'Vaksinasi Umum (18-59 tahun)' && <div className="form-group">
              <label>Kategori</label>
              <select name="professionVal" value={this.state.form.professionVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih Kategori</option>
                {this.state.form.professionList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>}

                {this.state.form.programVal !== '' && this.state.form.programList[this.state.form.programVal].program_name === 'Vaksinasi Umum (12+ tahun)' && <div className="form-group">
              <label>Kategori</label>
              <select name="professionVal" value={this.state.form.professionVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih Kategori</option>
                {this.state.form.professionList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>}

                {this.state.form.programVal !== '' && this.state.form.programList[this.state.form.programVal].program_name === 'Vaksinasi Umum (12-17 tahun)' && <div className="form-group">
              <label>Kategori</label>
              <select name="professionVal" value={this.state.form.professionVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih Kategori</option>
                {this.state.form.professionList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>}
          </div>
        </div>


        <div className="container flex-grow-1 d-flex flex-column justify-content-start">
          <div className="form-group">
            <label>Isi Data Pasien Vaksin</label>
          </div>

          <div className="form-group">
            <label>Nama Lengkap <span className="text-danger">*</span></label>
            <input name="fullName" value={this.state.form.fullName} onChange={this.handleFormChange} type="text" placeholder="Tulis Nama Lengkap" className="input-field"/>
          </div>
          <div className="form-group">
            <label>Tanggal Lahir <span className="text-danger">*</span></label>
            <DatePicker dateFormat="dd-MM-yyyy" placeholderText="Pilih tanggal lahir"
              disabledKeyboardNavigation
              withPortal
              onFocus={(e) => e.target.readOnly = true}
              selected={this.state.form.birthDate}
              onChangeRaw={(e) => e.preventDefault()}
              onChange={date => this.setBirthdate(date)}
              showYearDropdown={true}
              dropdownMode={'select'}
              maxDate={moment().toDate()} />
          </div>
          <div className="form-group">
            <label>Jenis Kelamin <span className="text-danger">*</span></label>
            <select name="genderVal" value={this.state.form.genderVal} onChange={this.handleFormChange} className="input-field">
              <option value=''>Pilih Jenis Kelamin</option>
              {this.state.form.genderList.map((item, index) => (
                <option key={index} value={item}>{item}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Nomor Handphone / WA <span className="text-danger">*</span></label>
            <input name="phoneNo" value={this.state.form.phoneNo} onChange={this.handleFormChange} type="text" placeholder="contoh: 081234567890" className="input-field"/>
          </div>
          <div className="form-group">
            <label>Email <span className="text-danger">*</span></label>
            <input name="email" value={this.state.form.email} onChange={this.handleFormChange} type="email" placeholder="Tulis E-mail" className="input-field"/>
          </div>
          <div className="form-group">
            {this.state.form.ktpImg === null && <div className="d-flex justify-content-between">
              <div className="align-self-center"><label>Unggah Foto Kartu Identitas <span className="text-danger">*</span></label></div>
              <div style={{minWidth: '20px'}}></div>
              <div>
                <input id="identity-file-input" type="file" accept=".jpg,.jpeg,.png" style={{display: 'none'}} onChange={this.handleKTPFileChange}/>
                <button type="button" onClick={() => document.getElementById('identity-file-input').click()} className="color-button">Unggah</button>
              </div>
            </div>}
            {this.state.form.ktpImg != null && <div className="d-flex">
              <div className="filename flex-grow-1 align-self-center">{this.state.form.ktpImg.name}</div>
              <div className="filesize align-self-center">{new Intl.NumberFormat('en-IN', { maximumFractionDigits: 0 }).format(this.state.form.ktpImg.size / 1000)}KB</div>
              <div style={{minWidth: '12px'}}></div>
              <div className="fileview align-self-center" data-toggle="modal" data-target="#ktp-view-modal">Lihat</div>
              <div className="modal fade" id="ktp-view-modal">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      {this.state.form.ktpImgPreview != null && <img id="identity-preview-img" className="identity-preview-img" src={this.state.form.ktpImgPreview} alt=""/>}
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{minWidth: '15px'}}></div>
              <div onClick={this.clearKTPImg} className="filedelete align-self-center">Hapus</div>
              <div style={{minWidth: '14px'}}></div>
            </div>}
          </div>
          <div className="form-group">
            {this.state.form.identityImg.map((item, index) => (
              <div key={index} style={{marginBottom: '8px'}}>
                {this.state.form.identityImg[index] === null && <div className="d-flex justify-content-between">
                  <div className="align-self-center"><label>Unggah Surat Domisili atau Surat Lainnya</label></div>
                  <div style={{minWidth: '20px'}}></div>
                  <div>
                    <input id={`identity-file-input-${index}`} type="file" accept=".jpg,.jpeg,.png" style={{display: 'none'}} onChange={(e) => this.handleFileChange(e, index)}/>
                    <button type="button" onClick={() => document.getElementById('identity-file-input-' + index).click()} className="color-button">Unggah</button>
                  </div>
                </div>}
                {this.state.form.identityImg[index] != null && <div className="d-flex">
                  <div className="filename flex-grow-1 align-self-center">{this.state.form.identityImg[index].name}</div>
                  <div style={{minWidth: '8px'}}></div>
                  <div className="filesize align-self-center">{new Intl.NumberFormat('en-IN', { maximumFractionDigits: 0 }).format(this.state.form.identityImg[index].size / 1000)}KB</div>
                  <div style={{minWidth: '12px'}}></div>
                  <div className="fileview align-self-center" data-toggle="modal" data-target={`#identity-view-modal-${index}`}>Lihat</div>
                  <div className="modal fade" id={`identity-view-modal-${index}`}>
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-body">
                          {this.state.form.identityImgPreview[index] != null && <img id={`identity-preview-img-${index}`} className="identity-preview-img" src={this.state.form.identityImgPreview[index]} alt=""/>}
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{minWidth: '15px'}}></div>
                  <div onClick={() => this.clearIdentityImg(index)} className="filedelete align-self-center">Hapus</div>
                  <div style={{minWidth: '14px'}}></div>
                </div>}
              </div>
            ))}
          </div>
          <div className="form-group">
            <button onClick={this.submitNewPatient} className="color-button w-100">Simpan, Lanjutkan</button>
          </div>
        </div>
      </div>
    </div>);
  }

  patientDataFormPage2() {
    return (
    <div className="patient-data-form-page">
      <div className="topbar">
        <div style={{minWidth: '16px'}}></div>
        <div onClick={() => {
          this.setState(prevState => ({
            form: {
              ...prevState.form,
              passcode: '',
              passcodeLocation: '',
              fullName: '',
              birthDate: '',
              genderVal: '',
              phoneNo: '',
              email: '',
              identityImg: [null],
              identityImgPreview: [null],
              ktpImg: null,
              ktpImgPreview: null,
            }
          }));
          this.goPage('VACCINE LOCATION SELECT PAGE');
        }} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
        <div className="text">Skrining Vaksin</div>
        <div style={{minWidth: '40px'}}></div>
      </div>
      <div className="d-flex flex-column h-100 overflow flex-grow-1">
        <div className="select-hospital">
          <div className="container">
            <div className="form-group">
              <label>Lokasi Vaksinasi</label>
              <input type="text" value={this.state.form.passcodeLocation.location_vaccine_company} className="input-field" readOnly={true}/>
            </div>
            <div className="note">
              <strong>Catatan:</strong>
              <div className="text" dangerouslySetInnerHTML={{ __html: this.state.form.passcodeLocation.note}}/>
            </div>
            <div style={{minHeight: '8px'}}></div>
          </div>
        </div>
        <div className="container flex-grow-1 d-flex flex-column justify-content-start">
          <div className="form-group">
            <label>Isi Data Pasien Vaksin</label>
          </div>

          <div className="form-group">
            <label>Nama Lengkap <span className="text-danger">*</span></label>
            <input name="fullName" value={this.state.form.fullName} onChange={this.handleFormChange} type="text" placeholder="Tulis Nama Lengkap" className="input-field"/>
          </div>
          <div className="form-group">
            <label>Tanggal Lahir <span className="text-danger">*</span></label>
            <DatePicker dateFormat="dd-MM-yyyy" placeholderText="Pilih tanggal lahir"
              disabledKeyboardNavigation
              withPortal
              onFocus={(e) => e.target.readOnly = true}
              selected={this.state.form.birthDate}
              onChangeRaw={(e) => e.preventDefault()}
              onChange={date => this.setBirthdate(date)}
              showYearDropdown={true}
              dropdownMode={'select'}
              maxDate={moment().toDate()} />
          </div>
          <div className="form-group">
            <label>Jenis Kelamin <span className="text-danger">*</span></label>
            <select name="genderVal" value={this.state.form.genderVal} onChange={this.handleFormChange} className="input-field">
              <option value=''>Pilih Jenis Kelamin</option>
              {this.state.form.genderList.map((item, index) => (
                <option key={index} value={item}>{item}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Nomor Handphone / WA <span className="text-danger">*</span></label>
            <input name="phoneNo" value={this.state.form.phoneNo} onChange={this.handleFormChange} type="text" placeholder="contoh: 081234567890" className="input-field"/>
          </div>
          <div className="form-group">
            <label>Email <span className="text-danger">*</span></label>
            <input name="email" value={this.state.form.email} onChange={this.handleFormChange} type="email" placeholder="Tulis E-mail" className="input-field"/>
          </div>
          <div className="form-group">
            {this.state.form.ktpImg === null && <div className="d-flex justify-content-between">
              <div className="align-self-center"><label>Unggah Foto Kartu Identitas <span className="text-danger">*</span></label></div>
              <div style={{minWidth: '20px'}}></div>
              <div>
                <input id="identity-file-input" type="file" accept=".jpg,.jpeg,.png" style={{display: 'none'}} onChange={this.handleKTPFileChange}/>
                <button type="button" onClick={() => document.getElementById('identity-file-input').click()} className="color-button">Unggah</button>
              </div>
            </div>}
            {this.state.form.ktpImg != null && <div className="d-flex">
              <div className="filename flex-grow-1 align-self-center">{this.state.form.ktpImg.name}</div>
              <div className="filesize align-self-center">{new Intl.NumberFormat('en-IN', { maximumFractionDigits: 0 }).format(this.state.form.ktpImg.size / 1000)}KB</div>
              <div style={{minWidth: '12px'}}></div>
              <div className="fileview align-self-center" data-toggle="modal" data-target="#ktp-view-modal">Lihat</div>
              <div className="modal fade" id="ktp-view-modal">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      {this.state.form.ktpImgPreview != null && <img id="identity-preview-img" className="identity-preview-img" src={this.state.form.ktpImgPreview} alt=""/>}
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{minWidth: '15px'}}></div>
              <div onClick={this.clearKTPImg} className="filedelete align-self-center">Hapus</div>
              <div style={{minWidth: '14px'}}></div>
            </div>}
          </div>
          <div className="form-group">
            {this.state.form.identityImg.map((item, index) => (
              <div key={index} style={{marginBottom: '8px'}}>
                {this.state.form.identityImg[index] === null && <div className="d-flex justify-content-between">
                  <div className="align-self-center"><label>Unggah Surat Domisili atau Surat Lainnya</label></div>
                  <div style={{minWidth: '20px'}}></div>
                  <div>
                    <input id={`identity-file-input-${index}`} type="file" accept=".jpg,.jpeg,.png" style={{display: 'none'}} onChange={(e) => this.handleFileChange(e, index)}/>
                    <button type="button" onClick={() => document.getElementById('identity-file-input-' + index).click()} className="color-button">Unggah</button>
                  </div>
                </div>}
                {this.state.form.identityImg[index] != null && <div className="d-flex">
                  <div className="filename flex-grow-1 align-self-center">{this.state.form.identityImg[index].name}</div>
                  <div style={{minWidth: '8px'}}></div>
                  <div className="filesize align-self-center">{new Intl.NumberFormat('en-IN', { maximumFractionDigits: 0 }).format(this.state.form.identityImg[index].size / 1000)}KB</div>
                  <div style={{minWidth: '12px'}}></div>
                  <div className="fileview align-self-center" data-toggle="modal" data-target={`#identity-view-modal-${index}`}>Lihat</div>
                  <div className="modal fade" id={`identity-view-modal-${index}`}>
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-body">
                          {this.state.form.identityImgPreview[index] != null && <img id={`identity-preview-img-${index}`} className="identity-preview-img" src={this.state.form.identityImgPreview[index]} alt=""/>}
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{minWidth: '15px'}}></div>
                  <div onClick={() => this.clearIdentityImg(index)} className="filedelete align-self-center">Hapus</div>
                  <div style={{minWidth: '14px'}}></div>
                </div>}
              </div>
            ))}
          </div>
          <div className="form-group">
            <button onClick={this.submitNewPatient} className="color-button w-100">Simpan, Lanjutkan</button>
          </div>
        </div>
      </div>
    </div>);
  }

  patientEditFormPage() {
    return (
    <div className="patient-edit-form-page">
      <div className="topbar">
        <div style={{minWidth: '16px'}}></div>
        <div onClick={() => this.resetState('PATIENT DETAIL PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
        <div className="text">Edit Data Pasien</div>
        <div style={{minWidth: '40px'}}></div>
      </div>
      <div className="d-flex flex-column h-100 overflow flex-grow-1">
        <div className="container flex-grow-1 d-flex flex-column justify-content-start">
          <div className="form-group">
            <label>Isi Data Pasien Vaksin</label>
          </div>

          <div className="form-group">
            <label>Nama Lengkap <span className="text-danger">*</span></label>
            <input name="fullName" value={this.state.form.fullName} onChange={this.handleFormChange} type="text" placeholder="Tulis Nama Lengkap" className="input-field"/>
          </div>
          <div className="form-group">
            <label>Tanggal Lahir <span className="text-danger">*</span></label>
            <DatePicker dateFormat="dd-MM-yyyy" placeholderText="Pilih tanggal lahir"
              disabledKeyboardNavigation
              onFocus={(e) => e.target.readOnly = true}
              popperPlacement="top-start"
              selected={this.state.form.birthDate}
              onChangeRaw={(e) => e.preventDefault()}
              onChange={date => this.setBirthdate(date)}
              showYearDropdown={true}
              dropdownMode={'select'}
              maxDate={moment().toDate()}
              withPortal />
          </div>
          <div className="form-group">
            <label>Jenis Kelamin <span className="text-danger">*</span></label>
            <select name="genderVal" value={this.state.form.genderVal} onChange={this.handleFormChange} className="input-field">
              <option value=''>Pilih Jenis Kelamin</option>
              {this.state.form.genderList.map((item, index) => (
                <option key={index} value={item}>{item}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Nomor Handphone / WA <span className="text-danger">*</span></label>
            <input name="phoneNo" value={this.state.form.phoneNo} onChange={this.handleFormChange} type="text" placeholder="contoh: 081234567890" className="input-field"/>
          </div>
          <div className="form-group">
            <label>Email <span className="text-danger">*</span></label>
            <input name="email" value={this.state.form.email} onChange={this.handleFormChange} type="email" placeholder="Tulis E-mail" className="input-field"/>
          </div>
          <div className="form-group">
            <div style={{marginBottom: '8px'}}>
              <div className="d-flex">
                <div className="filename flex-grow-1 align-self-center">Unggahan Foto KTP</div>
                <div style={{minWidth: '8px'}}></div>
                <div className="filesize align-self-center"></div>
                <div style={{minWidth: '12px'}}></div>
                <div className="fileview align-self-center" data-toggle="modal" data-target={`#ktp-exist-view-modal`}>Lihat</div>
                <div className="modal fade" id={`ktp-exist-view-modal`}>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-body">
                        <img className="identity-preview-img" src={this.state.patientVal.ktpImg} alt=""/>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{minWidth: '14px'}}></div>
              </div>
            </div>
            {this.state.form.ktpImg === null && <div className="d-flex justify-content-between">
              <div className="align-self-center"><label>Ubah Foto Kartu Identitas</label></div>
              <div style={{minWidth: '20px'}}></div>
              <div>
                <input id="identity-file-input" type="file" accept=".jpg,.jpeg,.png" style={{display: 'none'}} onChange={this.handleKTPFileChange}/>
                <button type="button" onClick={() => document.getElementById('identity-file-input').click()} className="color-button">Ubah</button>
              </div>
            </div>}
            {this.state.form.ktpImg != null && <div className="d-flex">
              <div className="filename flex-grow-1 align-self-center">{this.state.form.ktpImg.name}</div>
              <div className="filesize align-self-center">{new Intl.NumberFormat('en-IN', { maximumFractionDigits: 0 }).format(this.state.form.ktpImg.size / 1000)}KB</div>
              <div style={{minWidth: '12px'}}></div>
              <div className="fileview align-self-center" data-toggle="modal" data-target="#ktp-view-modal">Lihat</div>
              <div className="modal fade" id="ktp-view-modal">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      {this.state.form.ktpImgPreview != null && <img id="identity-preview-img" className="identity-preview-img" src={this.state.form.ktpImgPreview} alt=""/>}
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{minWidth: '15px'}}></div>
              <div onClick={this.clearKTPImg} className="filedelete align-self-center">Hapus</div>
              <div style={{minWidth: '14px'}}></div>
            </div>}
          </div>
          <div className="form-group">
            {this.state.patientVal.img.map((item, index) => (
              <div key={index} style={{marginBottom: '8px'}}>
                <div className="d-flex">
                  <div className="filename flex-grow-1 align-self-center">Unggahan {index + 1}</div>
                  <div style={{minWidth: '8px'}}></div>
                  <div className="filesize align-self-center"></div>
                  <div style={{minWidth: '12px'}}></div>
                  <div className="fileview align-self-center" data-toggle="modal" data-target={`#identity-exist-view-modal-${index}`}>Lihat</div>
                  <div className="modal fade" id={`identity-exist-view-modal-${index}`}>
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-body">
                          <img className="identity-preview-img" src={item.photoPath} alt=""/>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{minWidth: '15px'}}></div>
                  <div onClick={() => this.deleteIdentityImg(item)} className="filedelete align-self-center">Hapus</div>
                  <div style={{minWidth: '14px'}}></div>
                </div>
              </div>
            ))}
            {this.state.form.identityImg.map((item, index) => (
              <div key={index} style={{marginBottom: '8px'}}>
                {this.state.form.identityImg[index] === null && <div className="d-flex justify-content-between">
                  <div className="align-self-center"><label>Unggah Surat Domisili atau Surat Lainnya</label></div>
                  <div style={{minWidth: '20px'}}></div>
                  <div>
                    <input id={`identity-file-input-${index}`} type="file" accept=".jpg,.jpeg,.png" style={{display: 'none'}} onChange={(e) => this.handleFileChange(e, index)}/>
                    <button type="button" onClick={() => document.getElementById('identity-file-input-' + index).click()} className="color-button">Unggah</button>
                  </div>
                </div>}
                {this.state.form.identityImg[index] != null && <div className="d-flex">
                  <div className="filename flex-grow-1 align-self-center">{this.state.form.identityImg[index].name}</div>
                  <div style={{minWidth: '8px'}}></div>
                  <div className="filesize align-self-center">{new Intl.NumberFormat('en-IN', { maximumFractionDigits: 0 }).format(this.state.form.identityImg[index].size / 1000)}KB</div>
                  <div style={{minWidth: '12px'}}></div>
                  <div className="fileview align-self-center" data-toggle="modal" data-target={`#identity-view-modal-${index}`}>Lihat</div>
                  <div className="modal fade" id={`identity-view-modal-${index}`}>
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-body">
                          {this.state.form.identityImgPreview[index] != null && <img id={`identity-preview-img-${index}`} className="identity-preview-img" src={this.state.form.identityImgPreview[index]} alt=""/>}
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{minWidth: '15px'}}></div>
                  <div onClick={() => this.clearIdentityImg(index)} className="filedelete align-self-center">Hapus</div>
                  <div style={{minWidth: '14px'}}></div>
                </div>}
              </div>
            ))}
          </div>
          <div className="form-group">
            <button onClick={this.editPatient} className="color-button w-100">Simpan</button>
          </div>
        </div>
      </div>
    </div>);
  }

  patientEditFormPage2() {
    return (
    <div className="patient-edit-form-page-2">
      <div className="topbar">
        <div style={{minWidth: '16px'}}></div>
        <div onClick={() => this.goPage('HOSPITAL SELECTION EDIT PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
        <div className="text">Edit Data Pasien</div>
        <div style={{minWidth: '40px'}}></div>
      </div>
      <div className="d-flex flex-column h-100 overflow flex-grow-1">
        <div className="select-hospital">
          <div className="container">
            <div className="form-group">
              <label>Lokasi Vaksinasi</label>
              <select name="hospitalVal" value={this.state.form.hospitalVal} onChange={(e) => { this.handleFormChange(e, () => { this.fetchHospitalProgram(); this.fetchHospitalProfession(); }); this.setState(prevState => ({ form: { ...prevState.form, programVal: '', professionVal: '' } })) }} className="input-field">
                {this.state.form.hospitalList.map((item, index) => (
                  <option key={index} value={index}>{item.name}</option>
                ))}
                <option key={this.state.form.hospitalList.size + 1}>{this.state.form.hospitalVal}</option>
              </select>
            </div>
            <div className="form-group">
              <label>Pilih Program Vaksinasi</label>
              <select name="programVal" value={this.state.form.programVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih Program Vaksinasi</option>
                {this.state.form.programList.map((item, index) => (
                  <option key={index} value={index}>{item.program_name}</option>
                ))}
              </select>
              {this.state.form.programVal !== '' && <div className="program-note text-danger"><small><em>{this.state.form.programList[this.state.form.programVal].noted}</em></small></div>}
            </div>

           {this.state.form.programVal !== '' && this.state.form.programList[this.state.form.programVal].program_name === 'Vaksinasi Pelayanan Publik (18-59 tahun)' && <div className="form-group">
            <label>Kategori</label>
              <select name="professionVal" value={this.state.form.professionVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih Kategori</option>
                {this.state.form.professionList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>}

            {this.state.form.programVal !== '' && this.state.form.programList[this.state.form.programVal].program_name === 'Vaksinasi Gotong Royong (18-59 tahun)' && <div className="form-group">
            <label>Kategori</label>
              <select name="professionVal" value={this.state.form.professionVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih Kategori</option>
                {this.state.form.professionList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>}

            {this.state.form.programVal !== '' && this.state.form.programList[this.state.form.programVal].program_name === 'Vaksinasi Umum (18+ tahun)' && <div className="form-group">
              <label>Kategori</label>
              <select name="professionVal" value={this.state.form.professionVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih Kategori</option>
                {this.state.form.professionList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>}

                {this.state.form.programVal !== '' && this.state.form.programList[this.state.form.programVal].program_name === 'Vaksinasi Umum (18-59 tahun)' && <div className="form-group">
              <label>Kategori</label>
              <select name="professionVal" value={this.state.form.professionVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih Kategori</option>
                {this.state.form.professionList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>}

                {this.state.form.programVal !== '' && this.state.form.programList[this.state.form.programVal].program_name === 'Vaksinasi Umum (18+ tahun)' && <div className="form-group">
              <label>Kategori</label>
              <select name="professionVal" value={this.state.form.professionVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih Kategori</option>
                {this.state.form.professionList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>}

                {this.state.form.programVal !== '' && this.state.form.programList[this.state.form.programVal].program_name === 'Vaksinasi Umum (12+ tahun)' && <div className="form-group">
              <label>Kategori</label>
              <select name="professionVal" value={this.state.form.professionVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih Kategori</option>
                {this.state.form.professionList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>}

                {this.state.form.programVal !== '' && this.state.form.programList[this.state.form.programVal].program_name === 'Vaksinasi Umum (12-17 tahun)' && <div className="form-group">
              <label>Kategori</label>
              <select name="professionVal" value={this.state.form.professionVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih Kategori</option>
                {this.state.form.professionList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>}
          </div>
        </div>
        <div className="container flex-grow-1 d-flex flex-column justify-content-start">
          <div className="form-group">
            <label>Isi Data Pasien Vaksin</label>
          </div>

          <div className="form-group">
            <label>Nama Lengkap <span className="text-danger">*</span></label>
            <input name="fullName" value={this.state.form.fullName} onChange={this.handleFormChange} type="text" placeholder="Tulis Nama Lengkap" className="input-field"/>
          </div>
          <div className="form-group">
            <label>Tanggal Lahir <span className="text-danger">*</span></label>
            <DatePicker dateFormat="dd-MM-yyyy" placeholderText="Pilih tanggal lahir"
              disabledKeyboardNavigation
              withPortal
              onFocus={(e) => e.target.readOnly = true}
              popperPlacement="top-start"
              selected={this.state.form.birthDate}
              onChangeRaw={(e) => e.preventDefault()}
              onChange={date => this.setBirthdate(date)}
              showYearDropdown={true}
              dropdownMode={'select'}
              maxDate={moment().toDate()} />
          </div>
          <div className="form-group">
            <label>Jenis Kelamin <span className="text-danger">*</span></label>
            <select name="genderVal" value={this.state.form.genderVal} onChange={this.handleFormChange} className="input-field">
              <option value=''>Pilih Jenis Kelamin</option>
              {this.state.form.genderList.map((item, index) => (
                <option key={index} value={item}>{item}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Nomor Handphone / WA <span className="text-danger">*</span></label>
            <input name="phoneNo" value={this.state.form.phoneNo} onChange={this.handleFormChange} type="text" placeholder="contoh: 081234567890" className="input-field"/>
          </div>
          <div className="form-group">
            <label>Email <span className="text-danger">*</span></label>
            <input name="email" value={this.state.form.email} onChange={this.handleFormChange} type="email" placeholder="Tulis E-mail" className="input-field"/>
          </div>
          <div className="form-group">
            <div style={{marginBottom: '8px'}}>
              <div className="d-flex">
                <div className="filename flex-grow-1 align-self-center">Unggahan Foto KTP</div>
                <div style={{minWidth: '8px'}}></div>
                <div className="filesize align-self-center"></div>
                <div style={{minWidth: '12px'}}></div>
                <div className="fileview align-self-center" data-toggle="modal" data-target={`#ktp-exist-view-modal`}>Lihat</div>
                <div className="modal fade" id={`ktp-exist-view-modal`}>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-body">
                        <img className="identity-preview-img" src={this.state.patientVal.ktpImg} alt=""/>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{minWidth: '14px'}}></div>
              </div>
            </div>
            {this.state.form.ktpImg === null && <div className="d-flex justify-content-between">
              <div className="align-self-center"><label>Ubah Foto Kartu Identitas</label></div>
              <div style={{minWidth: '20px'}}></div>
              <div>
                <input id="identity-file-input" type="file" accept=".jpg,.jpeg,.png" style={{display: 'none'}} onChange={this.handleKTPFileChange}/>
                <button type="button" onClick={() => document.getElementById('identity-file-input').click()} className="color-button">Ubah</button>
              </div>
            </div>}
            {this.state.form.ktpImg != null && <div className="d-flex">
              <div className="filename flex-grow-1 align-self-center">{this.state.form.ktpImg.name}</div>
              <div className="filesize align-self-center">{new Intl.NumberFormat('en-IN', { maximumFractionDigits: 0 }).format(this.state.form.ktpImg.size / 1000)}KB</div>
              <div style={{minWidth: '12px'}}></div>
              <div className="fileview align-self-center" data-toggle="modal" data-target="#ktp-view-modal">Lihat</div>
              <div className="modal fade" id="ktp-view-modal">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      {this.state.form.ktpImgPreview != null && <img id="identity-preview-img" className="identity-preview-img" src={this.state.form.ktpImgPreview} alt=""/>}
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{minWidth: '15px'}}></div>
              <div onClick={this.clearKTPImg} className="filedelete align-self-center">Hapus</div>
              <div style={{minWidth: '14px'}}></div>
            </div>}
          </div>
          <div className="form-group">
            {this.state.patientVal.img.map((item, index) => (
              <div key={index} style={{marginBottom: '8px'}}>
                <div className="d-flex">
                  <div className="filename flex-grow-1 align-self-center">Unggahan {index + 1}</div>
                  <div style={{minWidth: '8px'}}></div>
                  <div className="filesize align-self-center"></div>
                  <div style={{minWidth: '12px'}}></div>
                  <div className="fileview align-self-center" data-toggle="modal" data-target={`#identity-exist-view-modal-${index}`}>Lihat</div>
                  <div className="modal fade" id={`identity-exist-view-modal-${index}`}>
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-body">
                          <img className="identity-preview-img" src={item.photoPath} alt=""/>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{minWidth: '15px'}}></div>
                  <div onClick={() => this.deleteIdentityImg(item)} className="filedelete align-self-center">Hapus</div>
                  <div style={{minWidth: '14px'}}></div>
                </div>
              </div>
            ))}
            {this.state.form.identityImg.map((item, index) => (
              <div key={index} style={{marginBottom: '8px'}}>
                {this.state.form.identityImg[index] === null && <div className="d-flex justify-content-between">
                  <div className="align-self-center"><label>Unggah Surat Domisili atau Surat Lainnya</label></div>
                  <div style={{minWidth: '20px'}}></div>
                  <div>
                    <input id={`identity-file-input-${index}`} type="file" accept=".jpg,.jpeg,.png" style={{display: 'none'}} onChange={(e) => this.handleFileChange(e, index)}/>
                    <button type="button" onClick={() => document.getElementById('identity-file-input-' + index).click()} className="color-button">Unggah</button>
                  </div>
                </div>}
                {this.state.form.identityImg[index] != null && <div className="d-flex">
                  <div className="filename flex-grow-1 align-self-center">{this.state.form.identityImg[index].name}</div>
                  <div style={{minWidth: '8px'}}></div>
                  <div className="filesize align-self-center">{new Intl.NumberFormat('en-IN', { maximumFractionDigits: 0 }).format(this.state.form.identityImg[index].size / 1000)}KB</div>
                  <div style={{minWidth: '12px'}}></div>
                  <div className="fileview align-self-center" data-toggle="modal" data-target={`#identity-view-modal-${index}`}>Lihat</div>
                  <div className="modal fade" id={`identity-view-modal-${index}`}>
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-body">
                          {this.state.form.identityImgPreview[index] != null && <img id={`identity-preview-img-${index}`} className="identity-preview-img" src={this.state.form.identityImgPreview[index]} alt=""/>}
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{minWidth: '15px'}}></div>
                  <div onClick={() => this.clearIdentityImg(index)} className="filedelete align-self-center">Hapus</div>
                  <div style={{minWidth: '14px'}}></div>
                </div>}
              </div>
            ))}
          </div>
          <div className="form-group">
            <button onClick={this.editPatient} className="color-button w-100">Simpan</button>
          </div>
        </div>
      </div>
    </div>);
  }

  patientDetailPage() {
    return (
    <div className="patient-detail-page">
      <div className="topbar">
        <div style={{minWidth: '16px'}}></div>
        <div onClick={() => this.setState({ patientVal: '', page: 'MAIN PAGE' }, this.resetState)} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
        <div className="text">Skrining Vaksin</div>
        <div style={{minWidth: '40px'}}></div>
      </div>
      <div className="container overflow">
        <div style={{minHeight: '8px'}}></div>
        <p>Detail Pasien Vaksinasi COVID-19</p>
        <div style={{minHeight: '20px'}}></div>

        <div className="d-flex">
          <div className="left">
            <img src={`${Config.BASE_URL}/img/hospital-location-icon.png`} alt=""/>
          </div>
          <div style={{minWidth: '4px'}}></div>
          <div className="middle align-self-center flex-grow-1">
            <div className="hospital-name">{this.state.patientVal.hospital_name}</div>
            <div className="program-vaccine">Program Vaksin</div>
            <div className="program-name">{this.state.patientVal.program_name}</div>
          </div>
          {this.state.patientVal.vaccine_location === 'RUMAH SAKIT' && <div onClick={() => {
            let selectedHospital;
            for(let i=0;i<this.state.form.hospitalList.length;i++) {
              let hospital = this.state.form.hospitalList[i];
              if(hospital.location_vaccine_id === Number(this.state.patientVal.hospital_id)) {
                selectedHospital = i; break;
              }
            }
            this.setState(prevState => ({
              form: {
                ...prevState.form,
                hospitalVal: selectedHospital,
              }
            }), () => this.goPage('HOSPITAL SELECTION EDIT PAGE'));
          }} className="right clickable" style={{paddingRight: '10px'}}>
            <img src={`${Config.BASE_URL}/img/edit-icon.png`} alt=""/>
          </div>}
        </div>

        <div style={{minHeight: '16px'}}></div>

        <div className="d-flex">
          <div className="left">
            <img src={`${Config.BASE_URL}/img/patient-icon.png`} alt=""/>
          </div>
          <div style={{minWidth: '4px'}}></div>
          <div className="middle align-self-center flex-grow-1">
            <div className="fullname">{this.state.patientVal.full_name}</div>
            <div className="birthdate">{moment(this.state.patientVal.birthdate).format('DD MMMM YYYY')} ({moment().diff(moment(this.state.patientVal.birthdate).format('YYYY-MM-DD'), 'years')} Tahun)</div>
            <div className="gender">{this.state.patientVal.gender}</div>
            <div className="phone">{this.state.patientVal.phone}</div>
            <div className="email">{this.state.patientVal.email}</div>
            <div style={{minHeight: '12px'}}></div>
            <div className="text">Kuisioner Skrining Vaksin</div>
            <div style={{minHeight: '8px'}}></div>
            {this.state.patientVal.status === 'PENDING' && <div onClick={() => this.goPage('SCREENING WELCOME PAGE')} className="white-button clickable">
              <div className="left">Isi Kuisioner Skrining</div>
              <div className="right"><img src={`${Config.BASE_URL}/img/arrow-right.png`} /></div>
            </div>}
            {this.state.patientVal.status === 'ACCEPTED' && <div className="green-notice">Lolos Skrining</div>}
            {this.state.patientVal.status === 'REJECTED' && <div className="red-notice">Tidak Lolos Skrining</div>}
            <div style={{minHeight: '8px'}}></div>
            {this.state.patientVal.vaccine_no != null && <div className="vaccine-no">{this.state.patientVal.vaccine_no === 'PERTAMA' ? 'Vaksin Pertama' : 'Vaksin Kedua'}</div>}
          </div>
          <div onClick={() => this.goPage('PATIENT EDIT FORM PAGE')} className="right clickable" style={{paddingRight: '10px'}}>
            <img src={`${Config.BASE_URL}/img/edit-icon.png`} alt=""/>
          </div>
        </div>

        <div style={{minHeight: '16px'}}></div>

        {this.state.patientVal.status === 'ACCEPTED' && <div>
          <div className="d-flex">
            <div className="left">
              <img src={`${Config.BASE_URL}/img/calendar-icon.png`} alt=""/>
            </div>
            <div style={{minWidth: '4px'}}></div>
            <div className="right align-self-center flex-grow-1">
              <div className="schedule-name">Jadwal</div>
              <div style={{minHeight: '4px'}}></div>
              <div className="d-flex justify-content-between">
                <div className="schedule-date">{this.state.patientVal.vaccine_date == null ? '-' : moment(this.state.patientVal.vaccine_date).format('DD MMMM YYYY')}</div>
                <div onClick={() => this.goPage('SCHEDULE SELECT PAGE')} className="edit-text">Ubah Tanggal</div>
              </div>
              <div style={{minHeight: '8px'}}></div>
              <div className="d-flex justify-content-between">
                <div className="schedule-date">{this.state.patientVal.hour_start}-{this.state.patientVal.hour_end}</div>
                <div onClick={() => this.fetchLocationVaccineHour(moment(this.state.patientVal.vaccine_date))} className="edit-text" data-toggle="modal" data-target="#hour-select-modal">Ubah Jam</div>
              </div>
              <div className="modal" id="hour-select-modal">
                <div className="modal-dialog modal-dialog-bottom">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="caption">Pilih Waktu Vaksin</div>
                      <div style={{minHeight: '10px'}}></div>
                      {this.state.form.hourList.length > 0 && <div>
                        <div className="date-selected">{moment(this.state.form.schedule).format("DD MMMM YYYY")}</div>
                        <div style={{minHeight: '20px'}}></div>
                        <div className="hour-list">
                          {this.state.form.hourList.map((item, index) => <div key={index} onClick={() => this.setState(prevState => ({
                            form: {
                              ...prevState.form,
                              hourVal: item,
                            }
                          }))} className={`hour ${this.state.form.hourVal === item ? 'active' : ''}`}>{item.hour_start} - {item.hour_end}</div>)}
                        </div>
                        <div style={{minHeight: '20px'}}></div>
                        <button onClick={this.submitSchedule} className={`color-button ${this.state.form.hourVal !== '' ? 'active' : ''}`} disabled={this.state.form.hourVal === ''} data-dismiss="modal">Pilih</button>
                      </div>}
                      {this.state.form.hourList.length === 0 && <div>
                        <div style={{minHeight: '16px'}}></div>
                        <div className="img text-center"><img src={`${Config.BASE_URL}/img/empty-calendar-icon.png`} /></div>
                        <div style={{minHeight: '16px'}}></div>
                        <div className="note">Slot Waktu Habis</div>
                        <div style={{minHeight: '32px'}}></div>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{minHeight: '12px'}}></div>

          {this.state.patientVal.vaccine_date != null && <div>
            <div onClick={() => {
              if(this.state.patientVal.is_confirmed === '1') return;
              this.setState(prevState => ({
                form: {
                  ...prevState.form,
                  term1: !this.state.form.term1,
                }
              }));
            }} className="d-flex clickable">
              <div className="left">
                {this.state.patientVal.is_confirmed === '1' && <img src={`${Config.BASE_URL}/img/radio-disabled.png`} alt=""/>}
                {this.state.patientVal.is_confirmed === '0' && this.state.form.term1 && <img src={`${Config.BASE_URL}/img/radio-check.png`} alt=""/>}
                {this.state.patientVal.is_confirmed === '0' && !this.state.form.term1 && <img src={`${Config.BASE_URL}/img/radio-uncheck.png`} alt=""/>}
              </div>
              <div style={{minWidth: '8px'}}></div>
              <div className="right align-self-center flex-grow-1 term">
                Saya menyetujui Syarat dan Ketentuan yang berlaku
              </div>
            </div>

            <div style={{minHeight: '8px'}}></div>

            <div onClick={() => {
              if(this.state.patientVal.is_confirmed === '1') return;
              this.setState(prevState => ({
                form: {
                  ...prevState.form,
                  term2: !this.state.form.term2,
                }
              }));
            }} className="d-flex clickable">
              <div className="left">
                {this.state.patientVal.is_confirmed === '1' && <img src={`${Config.BASE_URL}/img/radio-disabled.png`} alt=""/>}
                {this.state.patientVal.is_confirmed === '0' && this.state.form.term2 && <img src={`${Config.BASE_URL}/img/radio-check.png`} alt=""/>}
                {this.state.patientVal.is_confirmed === '0' && !this.state.form.term2 && <img src={`${Config.BASE_URL}/img/radio-uncheck.png`} alt=""/>}
              </div>
              <div style={{minWidth: '8px'}}></div>
              <div className="right align-self-center flex-grow-1 term">
                Data pribadi dan skrining yang saya masukkan adalah data yang benar sesuai kondisi saya sebenarnya
              </div>
            </div>

            <div style={{minHeight: '8px'}}></div>

            <div onClick={() => {
              if(this.state.patientVal.is_confirmed === '1') return;
              this.setState(prevState => ({
                form: {
                  ...prevState.form,
                  term3: !this.state.form.term3,
                }
              }));
            }} className="d-flex clickable">
              <div className="left">
                {this.state.patientVal.is_confirmed === '1' && <img src={`${Config.BASE_URL}/img/radio-disabled.png`} alt=""/>}
                {this.state.patientVal.is_confirmed === '0' && this.state.form.term3 && <img src={`${Config.BASE_URL}/img/radio-check.png`} alt=""/>}
                {this.state.patientVal.is_confirmed === '0' && !this.state.form.term3 && <img src={`${Config.BASE_URL}/img/radio-uncheck.png`} alt=""/>}
              </div>
              <div style={{minWidth: '8px'}}></div>
              <div className="right align-self-center flex-grow-1 term">
                Pihak vaksinator / rumah sakit memiliki hak untuk menolak vaksinasi saya bila kondisi kesehatan saya tidak memungkinkan untuk vaksinasi COVID-19 atau data yang saya masukkan tidak sesuai dengan kondisi sebenarnya.
              </div>
            </div>
          </div>}

          <div style={{minHeight: '8px'}}></div>
        </div>}
      </div>

      {this.state.patientVal.vaccine_date != null && <div className="action">
        {this.state.patientVal.is_confirmed === "0" && (this.state.form.term1 && this.state.form.term2 && this.state.form.term3) && <button onClick={() => { this.confirmPatient(); this.goPage('CONFIRMATION DETAIL PAGE'); }} className="color-button active">Konfirmasi</button>}
        {this.state.patientVal.is_confirmed === "0" && (!this.state.form.term1 || !this.state.form.term2 || !this.state.form.term3) && <button className="color-button">Konfirmasi</button>}
        {this.state.patientVal.is_confirmed === "1" && <button onClick={() => this.goPage('CONFIRMATION DETAIL PAGE')} className="border-button">Lihat Status Pendaftaran</button>}
      </div>}
    </div>);
  }

  confirmationDetailPage() {
    return (
    <div className="confirmation-detail-page">
      <div className="topbar">
        <div style={{minWidth: '16px'}}></div>
        <div onClick={() => this.goPage('PATIENT DETAIL PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
        <div className="text"></div>
        <div style={{minWidth: '40px'}}></div>
      </div>
      <div className="container flex-grow-1 d-flex flex-column overflow">
        <div className="img"><img src={`${Config.BASE_URL}/img/vaccine-certificate.png`} alt=""/></div>
        <div style={{minHeight: '8px'}}></div>
        <div className="title">Jadwal Vaksinasi Covid-19 telah Terdaftar.</div>
        <div style={{minHeight: '8px'}}></div>
        <div className="label bold">Detail Pendaftaran</div>
        <div className="grey-text">No.Pendaftaran : {this.state.patientVal.id}</div>
        <div style={{minHeight: '5px'}}></div>
        <div className="grey-text">Nama</div>
        <div className="label">{this.state.patientVal.full_name}</div>
        <div style={{minHeight: '5px'}}></div>
        <div className="grey-text">Tanggal</div>
        <div className="label">{moment(this.state.patientVal.vaccine_date).format('dddd, DD MMMM YYYY')}, {this.state.patientVal.hour_start} - {this.state.patientVal.hour_end}</div>
        <div style={{minHeight: '5px'}}></div>
        <div className="grey-text">Rumah Sakit</div>
        <div className="label">{this.state.patientVal.hospital_name}</div>
        <div style={{minHeight: '5px'}}></div>
        <div className="grey-text">Tipe Pendaftaran</div>
        <div className="label">{this.state.patientVal.type_vaccine}</div>
        <div style={{minHeight: '5px'}}></div>
        <div className="grey-text">Vaksin ke</div>
        <div className="label">{this.state.patientVal.vaccine_no === 'PERTAMA' ? 'Vaksin Pertama' : 'Vaksin Kedua'}</div>
        <div style={{minHeight: '5px'}}></div>
        <div className="grey-text">Note</div>
        <div className="label">{this.state.patientVal.location_vaccine_note ?? ''}</div>
        <div style={{minHeight: '32px'}}></div>
        <div className="deal">Petunjuk perjanjian vaksinasi</div>
        <div style={{minHeight: '16px'}}></div>
        <div className="deal-text">Penting untuk diperhatikan sebelum datang ke lokasi Vaksin:</div>
        <div style={{minHeight: '8px'}}></div>
        <div className="deal-text">
          <div className="d-flex">
            <div className="icon"><img src={`${Config.BASE_URL}/img/bullet-list.png`} alt=""/></div>
            <div style={{minWidth: '8px'}}></div>
            <div className="text">Membawa Kartu Tanda Penduduk (KTP Asli)</div>
          </div>
          <div style={{minHeight: '8px'}}></div>
          <div className="d-flex">
            <div className="icon"><img src={`${Config.BASE_URL}/img/bullet-list.png`} alt=""/></div>
            <div style={{minWidth: '8px'}}></div>
            <div className="text">Menggunakan pakaian lengan pendek atau yang mudah dilipat pada bagian lengan.</div>
          </div>
          <div style={{minHeight: '8px'}}></div>
          <div className="d-flex">
            <div className="icon"><img src={`${Config.BASE_URL}/img/bullet-list.png`} alt=""/></div>
            <div style={{minWidth: '8px'}}></div>
            <div className="text">Pihak vaksinator / rumah sakit memiliki hak untuk menolak vaksinasi peserta vaksin bila kondisi kesehatan peserta tidak memungkinkan untuk vaksinasi COVID-19 atau data yang peserta masukkan tidak sesuai dengan kondisi sebenarnya.</div>
          </div>
          <div style={{minHeight: '8px'}}></div>
          <div className="d-flex">
            <div className="icon"><img src={`${Config.BASE_URL}/img/bullet-list.png`} alt=""/></div>
            <div style={{minWidth: '8px'}}></div>
            <div className="text">Bila peserta memiliki riwayat penyakit tertentu, peserta wajib membawa surat dari dokter spesialis yang terkait dengan penyakit tersebut</div>
          </div>
        </div>
        <div style={{minHeight: '40px'}}></div>
        <div className="note">Silahkan datang ke rumah sakit sesuai jadwal dan datang 15 menit lebih awal untuk konfirmasi</div>
        <div style={{minHeight: '40px'}}></div>
        <div className="action"><button onClick={() => this.resetState('MAIN PAGE')} className="color-button">Kembali ke halaman utama</button></div>
        <div style={{minHeight: '20px'}}></div>
      </div>
    </div>);
  }

  screeningWelcomePage() {
    return (
    <div className="screening-welcome-page">
      <div className="topbar">
        <div style={{minWidth: '16px'}}></div>
        <div onClick={() => this.goPage('PATIENT DETAIL PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
        <div className="text">Skrining Vaksin</div>
        <div style={{minWidth: '40px'}}></div>
      </div>
      <div className="container flex-grow-1 d-flex flex-column">
        <div className="top-img"><img src={`${Config.BASE_URL}/img/join-logo.png`} alt=""/></div>
        <div className="middle-img"><img src={`${Config.BASE_URL}/img/screening-img.png`} alt=""/></div>
        <p className="blue-text">Bantu kami memahami kondisi kesehatan Anda agar kami dapat merekomendasikan pemberian vaksin COVID-19 kepada Anda</p>
        <div style={{minHeight: '16px'}}></div>
        <p className="blue-sub-text">mohon mengisi kuisioner ini dengan jujur dan sesuai dengan kondisi Anda</p>
        <div style={{minHeight: '40px'}}></div>
        <div onClick={() => this.goPage('SCREENING VACCINE NO PAGE')} className="form-group"><button className="color-button w-100">Isi Kuisioner</button></div>
      </div>
    </div>);
  }

  screeningVaccineNoPage() {
    return (
    <div className="screening-vaccine-no-page">
      <div className="wrapper flex-grow-1 d-flex flex-column">
        <div className="topbar">
          <div style={{minWidth: '16px'}}></div>
          <div onClick={() => {
            if(this.state.patientVal.vaccine_location === 'RUMAH SAKIT') this.goPage('SCREENING WELCOME PAGE');
            if(this.state.patientVal.vaccine_location === 'PASSCODE') this.goPage('PATIENT DETAIL PAGE');
          }} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
          <div className="text">Skrining Vaksin</div>
          <div style={{minWidth: '40px'}}></div>
        </div>
        <div className="container flex-grow-1 d-flex flex-column">
          <div className="img"><img src={`${Config.BASE_URL}/img/vaccine-no-img.png`} /></div>
          <div className="question">Ini adalah vaksinasi saya yang ke ?</div>
          <div style={{minHeight: '32px'}}></div>
          <div className="answer">
            {this.state.form.allowedVaccineNo.includes('Pertama') && <div className="form-group">
              <button onClick={() => {
                this.setState(prevState => ({
                  page: 'SCREENING ALERGIC PAGE',
                  form: {
                    ...prevState.form,
                    vaccineNo: 'PERTAMA',
                  }
                }));
              }} className="color-button">Vaksinasi Pertama</button>
            </div>}
            {this.state.form.allowedVaccineNo.includes('Kedua') && <div className="form-group">
              <button onClick={() => {
                this.setState(prevState => ({
                  page: 'SCREENING ALERGIC AFTER PAGE',
                  form: {
                    ...prevState.form,
                    vaccineNo: 'KEDUA',
                  }
                }));
              }} className="color-button">Vaksinasi Kedua</button>
            </div>}
          </div>
        </div>
      </div>
    </div>);
  }

  screeningAlergicPage() {
    return (
    <div className="screening-alergic-page">
      <div className="wrapper flex-grow-1 d-flex flex-column">
        <div className="topbar">
          <div style={{minWidth: '16px'}}></div>
          <div onClick={() => this.goPage('SCREENING VACCINE NO PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
          <div className="text">Skrining Vaksin</div>
          <div style={{minWidth: '40px'}}></div>
        </div>
        {this.isLansia(this.state.patientVal) && this.generateStepBar(11, 1)}
        {!this.isLansia(this.state.patientVal) && this.generateStepBar(6, 1)}
        <div className="container flex-grow-1 d-flex flex-column justify-content-end">
          <div className="img"><img src={`${Config.BASE_URL}/img/vaccine-alergic.png`} /></div>
          <div className="question">Apakah Anda memiliki riwayat alergi berat seperti sesak napas, bengkak dan urtikaria seluruh badan atau reaksi berat lainnya karena vaksin?</div>
          <div style={{minHeight: '32px'}}></div>
          <div className="answer">
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING PREGNANT PAGE',
                form: {
                  ...prevState.form,
                  alergic: 'Ya'
                }
              }));
            }} className="yes">Ya</button>
            <div style={{minWidth: '16px'}}></div>
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING PREGNANT PAGE',
                form: {
                  ...prevState.form,
                  alergic: 'Tidak'
                }
              }));
            }} className="no">Tidak</button>
          </div>
          <div style={{minHeight: '32px'}}></div>
        </div>
      </div>
    </div>);
  }

  screeningAlergicAfterPage() {
    return (
    <div className="screening-alergic-page">
      <div className="wrapper flex-grow-1 d-flex flex-column">
        <div className="topbar">
          <div style={{minWidth: '16px'}}></div>
          <div onClick={() => this.goPage('SCREENING VACCINE NO PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
          <div className="text">Skrining Vaksin</div>
          <div style={{minWidth: '40px'}}></div>
        </div>
        {this.isLansia(this.state.patientVal) && this.generateStepBar(11, 1)}
        {!this.isLansia(this.state.patientVal) && this.generateStepBar(6, 1)}
        <div className="container flex-grow-1 d-flex flex-column justify-content-end">
          <div className="img"><img src={`${Config.BASE_URL}/img/vaccine-alergic.png`} /></div>
          <div className="question">Apakah Anda memiliki riwayat alergi berat setelah divaksinasi COVID-19 sebelumnya?</div>
          <div style={{minHeight: '32px'}}></div>
          <div className="answer">
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING PREGNANT PAGE',
                form: {
                  ...prevState.form,
                  alergic: 'Ya'
                }
              }));
            }} className="yes">Ya</button>
            <div style={{minWidth: '16px'}}></div>
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING PREGNANT PAGE',
                form: {
                  ...prevState.form,
                  alergic: 'Tidak'
                }
              }));
            }} className="no">Tidak</button>
          </div>
          <div style={{minHeight: '32px'}}></div>
        </div>
      </div>
    </div>);
  }

  screeningPregnantPage() {
    return (
    <div className="screening-alergic-page">
      <div className="wrapper flex-grow-1 d-flex flex-column">
        <div className="topbar">
          <div style={{minWidth: '16px'}}></div>
          <div onClick={() => this.goPage(this.state.form.vaccineNo === 'PERTAMA' ? 'SCREENING ALERGIC PAGE' : 'SCREENING ALERGIC AFTER PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
          <div className="text">Skrining Vaksin</div>
          <div style={{minWidth: '40px'}}></div>
        </div>
        {this.isLansia(this.state.patientVal) && this.generateStepBar(11, 2)}
        {!this.isLansia(this.state.patientVal) && this.generateStepBar(6, 2)}
        <div className="container flex-grow-1 d-flex flex-column justify-content-end">
          <div className="img"><img src={`${Config.BASE_URL}/img/screening-pregnant.png`} /></div>
          <div className="question">Apakah Anda sedang hamil?</div>
          <div style={{minHeight: '32px'}}></div>
          <div className="answer">
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING AUTOIMMUNE PAGE',
                form: {
                  ...prevState.form,
                  pregnant: 'Ya'
                }
              }));
            }} className="yes">Ya</button>
            <div style={{minWidth: '16px'}}></div>
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING AUTOIMMUNE PAGE',
                form: {
                  ...prevState.form,
                  pregnant: 'Tidak'
                }
              }));
            }} className="no">Tidak</button>
          </div>
          <div style={{minHeight: '32px'}}></div>
        </div>
      </div>
    </div>);
  }

  screeningAutoimmunePage() {
    return (
    <div className="screening-alergic-page">
      <div className="wrapper flex-grow-1 d-flex flex-column">
        <div className="topbar">
          <div style={{minWidth: '16px'}}></div>
          <div onClick={() => this.goPage('SCREENING PREGNANT PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
          <div className="text">Skrining Vaksin</div>
          <div style={{minWidth: '40px'}}></div>
        </div>
        {this.isLansia(this.state.patientVal) && this.generateStepBar(11, 3)}
        {!this.isLansia(this.state.patientVal) && this.generateStepBar(6, 3)}
        <div className="container flex-grow-1 d-flex flex-column justify-content-end">
          <div className="img"><img src={`${Config.BASE_URL}/img/screening-autoimmune.png`} /></div>
          <div className="question">Apakah Anda mengidap penyakit autoimun seperti asma, lupus?</div>
          <div style={{minHeight: '32px'}}></div>
          <div className="answer">
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING BLOOD PAGE',
                form: {
                  ...prevState.form,
                  autoimmune: 'Ya'
                }
              }));
            }} className="yes">Ya</button>
            <div style={{minWidth: '16px'}}></div>
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING BLOOD PAGE',
                form: {
                  ...prevState.form,
                  autoimmune: 'Tidak'
                }
              }));
            }} className="no">Tidak</button>
          </div>
          <div style={{minHeight: '32px'}}></div>
        </div>
      </div>
    </div>);
  }

  screeningBloodPage() {
    return (
    <div className="screening-alergic-page">
      <div className="wrapper flex-grow-1 d-flex flex-column">
        <div className="topbar">
          <div style={{minWidth: '16px'}}></div>
          <div onClick={() => this.goPage('SCREENING AUTOIMMUNE PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
          <div className="text">Skrining Vaksin</div>
          <div style={{minWidth: '40px'}}></div>
        </div>
        {this.isLansia(this.state.patientVal) && this.generateStepBar(11, 4)}
        {!this.isLansia(this.state.patientVal) && this.generateStepBar(6, 4)}
        <div className="container flex-grow-1 d-flex flex-column justify-content-end">
          <div className="img"><img src={`${Config.BASE_URL}/img/screening-blood.png`} /></div>
          <div className="question">Apakah Anda sedang mendapat pengobatan untuk gangguan pembekuan darah, kelainan darah, defisiensi imun dan penerima produk darah/transfusi?</div>
          <div style={{minHeight: '32px'}}></div>
          <div className="answer">
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING CHEMO PAGE',
                form: {
                  ...prevState.form,
                  blood: 'Ya'
                }
              }));
            }} className="yes">Ya</button>
            <div style={{minWidth: '16px'}}></div>
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING CHEMO PAGE',
                form: {
                  ...prevState.form,
                  blood: 'Tidak'
                }
              }));
            }} className="no">Tidak</button>
          </div>
          <div style={{minHeight: '32px'}}></div>
        </div>
      </div>
    </div>);
  }

  screeningChemoPage() {
    return (
    <div className="screening-alergic-page">
      <div className="wrapper flex-grow-1 d-flex flex-column">
        <div className="topbar">
          <div style={{minWidth: '16px'}}></div>
          <div onClick={() => this.goPage('SCREENING BLOOD PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
          <div className="text">Skrining Vaksin</div>
          <div style={{minWidth: '40px'}}></div>
        </div>
        {this.isLansia(this.state.patientVal) && this.generateStepBar(11, 5)}
        {!this.isLansia(this.state.patientVal) && this.generateStepBar(6, 5)}
        <div className="container flex-grow-1 d-flex flex-column justify-content-end">
          <div className="img"><img src={`${Config.BASE_URL}/img/screening-chemo.png`} /></div>
          <div className="question">Apakah Anda sedang mendapat pengobatan immunosupressant seperti kortikosteroid dan kemoterapi?</div>
          <div style={{minHeight: '32px'}}></div>
          <div className="answer">
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING HEART PAGE',
                form: {
                  ...prevState.form,
                  chemo: 'Ya'
                }
              }));
            }} className="yes">Ya</button>
            <div style={{minWidth: '16px'}}></div>
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING HEART PAGE',
                form: {
                  ...prevState.form,
                  chemo: 'Tidak'
                }
              }));
            }} className="no">Tidak</button>
          </div>
          <div style={{minHeight: '32px'}}></div>
        </div>
      </div>
    </div>);
  }

  screeningHeartPage() {
    return (
    <div className="screening-alergic-page">
      <div className="wrapper flex-grow-1 d-flex flex-column">
        <div className="topbar">
          <div style={{minWidth: '16px'}}></div>
          <div onClick={() => this.goPage('SCREENING CHEMO PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
          <div className="text">Skrining Vaksin</div>
          <div style={{minWidth: '40px'}}></div>
        </div>
        {this.isLansia(this.state.patientVal) && this.generateStepBar(11, 6)}
        {!this.isLansia(this.state.patientVal) && this.generateStepBar(6, 6)}
        <div className="container flex-grow-1 d-flex flex-column justify-content-end">
          <div className="img"><img src={`${Config.BASE_URL}/img/screening-heart.png`} /></div>
          <div className="question">Apakah Anda memiliki penyakit jantung berat dalam keadaan sesak?</div>
          <div style={{minHeight: '32px'}}></div>
          <div className="answer">
            <button onClick={() => {
              if(this.isLansia(this.state.patientVal)) {
                this.setState(prevState => ({
                  page: 'SCREENING STAIRS PAGE',
                  form: {
                    ...prevState.form,
                    heart: 'Ya'
                  }
                }));
              } else {
                this.setState(prevState => ({
                  form: {
                    ...prevState.form,
                    heart: 'Ya'
                  }
                }), this.submitScreening2);
              }
            }} className="yes">Ya</button>
            <div style={{minWidth: '16px'}}></div>
            <button onClick={() => {
              if(this.isLansia(this.state.patientVal)) {
                this.setState(prevState => ({
                  page: 'SCREENING STAIRS PAGE',
                  form: {
                    ...prevState.form,
                    heart: 'Tidak'
                  }
                }));
              } else {
                this.setState(prevState => ({
                  form: {
                    ...prevState.form,
                    heart: 'Tidak'
                  }
                }), this.submitScreening2);
              }
            }} className="no">Tidak</button>
          </div>
          <div style={{minHeight: '32px'}}></div>
        </div>
      </div>
    </div>);
  }

  screeningStairsPage() {
    return (
    <div className="screening-alergic-page">
      <div className="wrapper flex-grow-1 d-flex flex-column">
        <div className="topbar">
          <div style={{minWidth: '16px'}}></div>
          <div onClick={() => this.goPage('SCREENING HEART PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
          <div className="text">Skrining Vaksin</div>
          <div style={{minWidth: '40px'}}></div>
        </div>
        {this.generateStepBar(11, 7)}
        <div className="container flex-grow-1 d-flex flex-column justify-content-end">
          <div className="img"><img src={`${Config.BASE_URL}/img/screening-stairs.png`} /></div>
          <div className="question">Apakah Anda mengalami kesulitan untuk naik 10 anak tangga?</div>
          <div style={{minHeight: '32px'}}></div>
          <div className="answer">
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING FATIQUE PAGE',
                form: {
                  ...prevState.form,
                  stairs: 'Ya'
                }
              }));
            }} className="yes">Ya</button>
            <div style={{minWidth: '16px'}}></div>
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING FATIQUE PAGE',
                form: {
                  ...prevState.form,
                  stairs: 'Tidak'
                }
              }));
            }} className="no">Tidak</button>
          </div>
          <div style={{minHeight: '32px'}}></div>
        </div>
      </div>
    </div>);
  }

  screeningFatiquePage() {
    return (
    <div className="screening-alergic-page">
      <div className="wrapper flex-grow-1 d-flex flex-column">
        <div className="topbar">
          <div style={{minWidth: '16px'}}></div>
          <div onClick={() => this.goPage('SCREENING STAIRS PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
          <div className="text">Skrining Vaksin</div>
          <div style={{minWidth: '40px'}}></div>
        </div>
        {this.generateStepBar(11, 8)}
        <div className="container flex-grow-1 d-flex flex-column justify-content-end">
          <div className="img"><img src={`${Config.BASE_URL}/img/screening-fatique.png`} /></div>
          <div className="question">Apakah Anda sering merasa kelelahan?</div>
          <div style={{minHeight: '32px'}}></div>
          <div className="answer">
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING ILLNESS PAGE',
                form: {
                  ...prevState.form,
                  fatique: 'Ya'
                }
              }));
            }} className="yes">Ya</button>
            <div style={{minWidth: '16px'}}></div>
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING ILLNESS PAGE',
                form: {
                  ...prevState.form,
                  fatique: 'Tidak'
                }
              }));
            }} className="no">Tidak</button>
          </div>
          <div style={{minHeight: '32px'}}></div>
        </div>
      </div>
    </div>);
  }

  screeningIllnessPage() {
    return (
    <div className="screening-alergic-page">
      <div className="wrapper flex-grow-1 d-flex flex-column">
        <div className="topbar">
          <div style={{minWidth: '16px'}}></div>
          <div onClick={() => this.goPage('SCREENING FATIQUE PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
          <div className="text">Skrining Vaksin</div>
          <div style={{minWidth: '40px'}}></div>
        </div>
        {this.generateStepBar(11, 9)}
        <div className="container flex-grow-1 d-flex flex-column justify-content-end">
          <div className="img"><img src={`${Config.BASE_URL}/img/screening-illness.png`} /></div>
          <div className="question">Apakah Anda memiliki paling sedikit 5 dari 11 penyakit (Hipertensi, diabetes, kanker, penyakit paru kronis, serangan jantung, gagal jantung kongestif, nyeri dada, asma, nyeri sendi, stroke dan penyakit ginjal)?</div>
          <div style={{minHeight: '32px'}}></div>
          <div className="answer">
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING WALKING PAGE',
                form: {
                  ...prevState.form,
                  illness: 'Ya'
                }
              }));
            }} className="yes">Ya</button>
            <div style={{minWidth: '16px'}}></div>
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING WALKING PAGE',
                form: {
                  ...prevState.form,
                  illness: 'Tidak'
                }
              }));
            }} className="no">Tidak</button>
          </div>
          <div style={{minHeight: '32px'}}></div>
        </div>
      </div>
    </div>);
  }

  screeningWalkingPage() {
    return (
    <div className="screening-alergic-page">
      <div className="wrapper flex-grow-1 d-flex flex-column">
        <div className="topbar">
          <div style={{minWidth: '16px'}}></div>
          <div onClick={() => this.goPage('SCREENING ILLNESS PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
          <div className="text">Skrining Vaksin</div>
          <div style={{minWidth: '40px'}}></div>
        </div>
        {this.generateStepBar(11, 10)}
        <div className="container flex-grow-1 d-flex flex-column justify-content-end">
          <div className="img"><img src={`${Config.BASE_URL}/img/screening-walking.png`} /></div>
          <div className="question">Apakah Anda mengalami kesulitan berjalan kira-kira 100 sampai 200 meter?</div>
          <div style={{minHeight: '32px'}}></div>
          <div className="answer">
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING WEIGHT PAGE',
                form: {
                  ...prevState.form,
                  walking: 'Ya'
                }
              }));
            }} className="yes">Ya</button>
            <div style={{minWidth: '16px'}}></div>
            <button onClick={() => {
              this.setState(prevState => ({
                page: 'SCREENING WEIGHT PAGE',
                form: {
                  ...prevState.form,
                  walking: 'Tidak'
                }
              }));
            }} className="no">Tidak</button>
          </div>
          <div style={{minHeight: '32px'}}></div>
        </div>
      </div>
    </div>);
  }

  screeningWeightPage() {
    return (
    <div className="screening-alergic-page">
      <div className="wrapper flex-grow-1 d-flex flex-column">
        <div className="topbar">
          <div style={{minWidth: '16px'}}></div>
          <div onClick={() => this.goPage('SCREENING WALKING PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
          <div className="text">Skrining Vaksin</div>
          <div style={{minWidth: '40px'}}></div>
        </div>
        {this.generateStepBar(11, 11)}
        <div className="container flex-grow-1 d-flex flex-column justify-content-end">
          <div className="img"><img src={`${Config.BASE_URL}/img/screening-weight.png`} /></div>
          <div className="question">Apakah Anda mengalami penurunan berat badan yang bermakna dalam setahun terakhir?</div>
          <div style={{minHeight: '32px'}}></div>
          <div className="answer">
            <button onClick={() => {
              this.setState(prevState => ({
                form: {
                  ...prevState.form,
                  weight: 'Ya'
                }
              }), this.submitScreening);
            }} className="yes">Ya</button>
            <div style={{minWidth: '16px'}}></div>
            <button onClick={() => {
              this.setState(prevState => ({
                form: {
                  ...prevState.form,
                  weight: 'Tidak'
                }
              }), this.submitScreening);
            }} className="no">Tidak</button>
          </div>
          <div style={{minHeight: '32px'}}></div>
        </div>
      </div>
    </div>);
  }

  screeningSuccessPage() {
    return (
    <div className="screening-success-page">
      <div className="topbar">
        <div style={{minWidth: '16px'}}></div>
        <div onClick={() => this.goPage('PATIENT DETAIL PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
        <div className="text">Skrining Vaksin</div>
        <div style={{minWidth: '40px'}}></div>
      </div>
      <div className="container flex-grow-1 d-flex flex-column justify-content-center">
        <div className="img"><img src={`${Config.BASE_URL}/img/vaccine-accept.png`} alt=""/></div>
        <div style={{minHeight: '8px'}}></div>
        <div className="notice">Anda telah memenuhi syarat <br/> untuk mendapatkan vaksin <br/> COVID-19</div>
        <div style={{minHeight: '20px'}}></div>
        <p className="notice">selanjutnya silahkan memilih <br/> jadwal vaksinasi</p>
        <div style={{minHeight: '8px'}}></div>
        <div onClick={() => this.goPage('SCHEDULE SELECT PAGE')} className="form-group text-center">
          <button className="color-button">Lanjutkan, Pilih Jadwal</button>
        </div>
        <div style={{minHeight: '28px'}}></div>
      </div>
    </div>);
  }

  screeningFailedPage() {
    return (
    <div className="screening-failed-page">
      <div className="topbar">
        <div style={{minWidth: '16px'}}></div>
        <div onClick={() => this.goPage('PATIENT DETAIL PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
        <div className="text">Skrining Vaksin</div>
        <div style={{minWidth: '40px'}}></div>
      </div>
      <div className="container flex-grow-1 d-flex flex-column justify-content-center">
        <div className="img"><img src={`${Config.BASE_URL}/img/vaccine-rejected.png`} alt=""/></div>
        <div style={{minHeight: '8px'}}></div>
        <div className="notice">Maaf, Anda tidak dapat melanjutkan<br/>proses Skrining Vaksinasi.</div>
        <div style={{minHeight: '20px'}}></div>
        <p className="text">Untuk mendapatkan surat persetujuan <br/>layak vaksin, Anda dapat menghubungi <br/>dokter.</p>
        <div style={{minHeight: '8px'}}></div>
        <div onClick={() => this.goPage('PATIENT DETAIL PAGE')} className="form-group text-center"><button className="color-button">Oke, Mengerti</button></div>
        <div style={{minHeight: '28px'}}></div>
      </div>
    </div>);
  }

  scheduleSelectPage() {
    return (
    <div className="schedule-select-page">
      <div className="topbar">
        <div style={{minWidth: '16px'}}></div>
        <div onClick={() => this.goPage('PATIENT DETAIL PAGE')} className="arrow clickable"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
        <div className="text">Lokasi</div>
        <div style={{minWidth: '40px'}}></div>
      </div>
      <div className="flex-grow-1 d-flex flex-column overflow" style={{maxHeight: '550px'}}>
        <div className="top flex-grow-1">
          <div className="container">
            <div className="text">Silahkan Pilih jadwal vaksinasi yang tersedia</div>
          </div>
        </div>
        <div className="bottom">
          {this.generateCalendar()}
        </div>
      </div>
    </div>);
  }

  generateCalendar() {
    let schedule = this.state.form.schedule;
    let dateRow = [];
    let row = [];
    for(let i=1;i<=schedule.daysInMonth();i++) {
      let day = moment(schedule).set("date", i);
      let weekday = moment(day).day();
      // start from monday, not sunday
      if(weekday === 0) weekday = 6;
      else if(weekday === 1) weekday = 0;
      else if(weekday === 2) weekday = 1;
      else if(weekday === 3) weekday = 2;
      else if(weekday === 4) weekday = 3;
      else if(weekday === 5) weekday = 4;
      else if(weekday === 6) weekday = 5;

      row[weekday] = day;
      if(weekday == 6) {
        dateRow.push(row);
        row = [];
      }
    }
    if(row.length > 0) {
      dateRow.push(row);
    }

    return (<div className="calendar">
      <div className="container">
        <div className="month">
          <div onClick={() => this.setState(prevState => ({
            form: {
              ...prevState.form,
              schedule: moment(schedule).add(-1, 'month'),
            }
          }))} className="left clickable"><img src={`${Config.BASE_URL}/img/calendar-arrow-left.png`} /></div>
          <div style={{minWidth: '4px'}}></div>
          <div className="middle align-self-center">{moment(schedule).format('MMMM YYYY')}</div>
          <div style={{minWidth: '4px'}}></div>
          <div onClick={() => this.setState(prevState => ({
            form: {
              ...prevState.form,
              schedule: moment(schedule).add(1, 'month'),
            }
          }))} className="right clickable"><img src={`${Config.BASE_URL}/img/calendar-arrow-right.png`} /></div>
        </div>
        <div style={{minHeight: '12px'}}></div>
        <div className="weekday">
          <div className="day">Sen</div>
          <div className="day">Sel</div>
          <div className="day">Rab</div>
          <div className="day">Kam</div>
          <div className="day">Jum</div>
          <div className="day">Sab</div>
          <div className="day">Ming</div>
        </div>
        <div style={{minHeight: '12px'}}></div>
        <div className="date-list">
          {dateRow.map((item, index) => (
            <div key={index} className="date">
              {[...Array(7)].map((_, subindex) => (
                <div key={subindex} onClick={() => {
                  if(!this.isCalendarAllowed(item[subindex])) return;
                  this.setState(prevState => ({
                    form: {
                      ...prevState.form,
                      schedule: item[subindex],
                    }
                  }), () => this.fetchLocationVaccineHour(item[subindex]));
                }} className={`item ${item[subindex] != null ? this.isCalendarAllowed(item[subindex]) ? 'active' : 'inactive' : ''}`} data-toggle="modal" data-target={`${this.isCalendarAllowed(item[subindex]) ? '#hour-select-modal' : ''}`}>{moment(item[subindex]).format('DD')}</div>
              ))}
            </div>
          ))}
        </div>
        <div className="modal" id="hour-select-modal">
          <div className="modal-dialog modal-dialog-bottom">
            <div className="modal-content">
              <div className="modal-body">
                <div className="caption">Pilih Waktu Vaksin</div>
                <div style={{minHeight: '10px'}}></div>
                {this.state.form.hourList.length > 0 && <div>
                  <div className="date-selected">{moment(this.state.form.schedule).format("DD MMMM YYYY")}</div>
                  <div style={{minHeight: '20px'}}></div>
                  <div className="hour-list">
                    {this.state.form.hourList.map((item, index) => <div key={index} onClick={() => this.setState(prevState => ({
                      form: {
                        ...prevState.form,
                        hourVal: item,
                      }
                    }))} className={`hour ${this.state.form.hourVal === item ? 'active' : ''}`}>{item.hour_start} - {item.hour_end}</div>)}
                  </div>
                  <div style={{minHeight: '20px'}}></div>
                  <button onClick={this.submitSchedule} className={`color-button ${this.state.form.hourVal !== '' ? 'active' : ''}`} disabled={this.state.form.hourVal === ''} data-dismiss="modal">Pilih</button>
                </div>}
                {this.state.form.hourList.length === 0 && <div>
                  <div style={{minHeight: '16px'}}></div>
                  <div className="img text-center"><img src={`${Config.BASE_URL}/img/empty-calendar-icon.png`} /></div>
                  <div style={{minHeight: '16px'}}></div>
                  <div className="note">Slot Waktu Habis</div>
                  <div style={{minHeight: '32px'}}></div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
  }

  isCalendarAllowed(date) {
    for(let i=0;i<this.state.form.allowedDateList.length;i++) {
      let item = this.state.form.allowedDateList[i];
      if(item.date === moment(date).format('YYYY-MM-DD')) return true;
    }
    return false;
  }

  generateStepBar(total, active) {
    return (<div className="stepbar">
      {
        [...Array(total)].map((e, i) => <div key={i} className={`step ${(i < active) ? 'active' : ''}`}></div>)
      }
    </div>);
  }

  isLansia(patient) {
    return moment().diff(moment(patient.birthdate).format('YYYY-MM-DD'), 'years') > 59;
  }

  render() {
    return (
      <div className="vaccine-2">
        {this.state.page === 'MAIN PAGE' && this.mainPage()}
        {this.state.page === 'PATIENT DATA FORM PAGE 2' && this.patientDataFormPage2()}
        {this.state.page === 'VACCINE LOCATION SELECT PAGE' && this.vaccineLocationSelectPage()}
        {this.state.page === 'HOSPITAL SELECTION PAGE' && this.hospitalSelectionPage()}
        {this.state.page === 'HOSPITAL SELECTION EDIT PAGE' && this.hospitalSelectionEditPage()}
        {this.state.page === 'PATIENT DATA FORM PAGE' && this.patientDataFormPage()}
        {this.state.page === 'PATIENT EDIT FORM PAGE' && this.patientEditFormPage()}
        {this.state.page === 'PATIENT EDIT FORM PAGE 2' && this.patientEditFormPage2()}
        {this.state.page === 'PATIENT DETAIL PAGE' && this.patientDetailPage()}
        {this.state.page === 'SCREENING WELCOME PAGE' && this.screeningWelcomePage()}
        {this.state.page === 'SCREENING VACCINE NO PAGE' && this.screeningVaccineNoPage()}
        {this.state.page === 'SCREENING ALERGIC PAGE' && this.screeningAlergicPage()}
        {this.state.page === 'SCREENING ALERGIC AFTER PAGE' && this.screeningAlergicAfterPage()}
        {this.state.page === 'SCREENING PREGNANT PAGE' && this.screeningPregnantPage()}
        {this.state.page === 'SCREENING AUTOIMMUNE PAGE' && this.screeningAutoimmunePage()}
        {this.state.page === 'SCREENING BLOOD PAGE' && this.screeningBloodPage()}
        {this.state.page === 'SCREENING CHEMO PAGE' && this.screeningChemoPage()}
        {this.state.page === 'SCREENING HEART PAGE' && this.screeningHeartPage()}
        {this.state.page === 'SCREENING STAIRS PAGE' && this.screeningStairsPage()}
        {this.state.page === 'SCREENING FATIQUE PAGE' && this.screeningFatiquePage()}
        {this.state.page === 'SCREENING ILLNESS PAGE' && this.screeningIllnessPage()}
        {this.state.page === 'SCREENING WALKING PAGE' && this.screeningWalkingPage()}
        {this.state.page === 'SCREENING WEIGHT PAGE' && this.screeningWeightPage()}
        {this.state.page === 'SCREENING A PAGE' && this.screeningFailedPage()}
        {this.state.page === 'SCREENING SUCCESS PAGE' && this.screeningSuccessPage()}
        {this.state.page === 'SCREENING FAILED PAGE' && this.screeningFailedPage()}
        {this.state.page === 'SCHEDULE SELECT PAGE' && this.scheduleSelectPage()}
        {this.state.page === 'CONFIRMATION DETAIL PAGE' && this.confirmationDetailPage()}

        {this.state.isLoading && <div className="loading"><div className="spinner-border text-primary"></div></div>}
      </div>
    );
  }
}

export default withRouter(VaccineV2);
