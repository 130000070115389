import React from 'react';
import Home from './page/home';
import ArticleDetail from './page/article-detail';
import ArticleList from './page/article-list';
import ArticleListTag from './page/article-list-tag';
import ArticleRecommended from './page/article-recommended';
import TopicList from './page/topic-list';
import TopicArticleList from './page/topic-article-list';
import Vaccine from './page/Vaccine';
import VaccineV2 from './page/VaccineV2';
import VaccineV3 from './page/VaccineV3';
import Certificate from './page/Certificate';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/vaccine-certificate/:userID">
          <Certificate></Certificate>
        </Route>
        {/*
          <Route path="/vaccine/:userID">
          <Vaccine></Vaccine>
          </Route>
        */}
        {
          <Route path="/apkdownload" component={() => {
            window.location.href = 'https://bit.ly/AlteaCare';
            return null;
          }}/>
        }
        <Route path="/vaccine/:userID">
          <VaccineV2></VaccineV2>
        </Route>

        <Route path="/vaccine">
          <VaccineV3></VaccineV3>
        </Route>
        <Route path="/playstore" component={() => {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.dre.loyalty';
          return null;
        }}/>
        <Route path="/vaksin">
          <Home login={true}></Home>
        </Route>
        <Route path="/topic-list">
          <TopicList login={false}></TopicList>
        </Route>
        <Route path="/topic/:topic">
          <TopicArticleList login={false}></TopicArticleList>
        </Route>
        <Route path="/article-list">
          <ArticleList login={false}></ArticleList>
        </Route>
        <Route path="/article-tag/:slug">
          <ArticleListTag login={false}></ArticleListTag>
        </Route>
        <Route path="/recommended-article/:id">
          <ArticleRecommended login={false}></ArticleRecommended>
        </Route>
        <Route path="/article-detail/:slug">
          <ArticleDetail login={false}></ArticleDetail>
        </Route>
        <Route path="/">
          <Home></Home>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
