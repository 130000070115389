import React from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/id';
import * as Config from './../../config';

class TopicListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topicList: [],
    }

    this.fetchTopicList = this.fetchTopicList.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  async componentDidMount() {
    this.fetchTopicList();
  }

  handleFormChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    this.state.form[name] = value;
    this.setState({ form: this.state.form });
  }

  async fetchTopicList() {
    this.setState(prevState => ({
      isLoading: true,
      form: {
        ...prevState.form,
        topicList: [],
      }
    }));
    let response = await axios.get(`${Config.API_PUBLIC_URL}/category`);
    try {
      let data = response.data;
      if(data.statusCode === 200) {
        this.setState({topicList: data.data.categories});
        console.log(this.state.topicList);
      } else {
        Swal.fire('Failed', data.message, 'error');
      }
    } catch(error) {
      Swal.fire('Error', 'Unable to connect to server', 'error');
    }
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div id="article-detail" className="article-detail">
        <div className="tab-panel">
          <div className="container d-flex">
            <div><a href={Config.BASE_URL}>Beranda</a></div>
            <div style={{minWidth: '4px'}}></div>
            <div>/</div>
            <div style={{minWidth: '4px'}}></div>
            <div>Topik</div>
          </div>
        </div>
        <div className="container">
          {this.state.topicList.length > 0 && <div className="topic-article">
            <div style={{minWidth:'32px',minHeight:'32px'}}></div>
            <div className="d-flex justify-content-between">
              <div className="title">Semua Topik</div>
            </div>
            <div style={{minWidth:'32px',minHeight:'32px'}}></div>
            <div className="topic-splide d-flex flex-wrap">
              {this.state.topicList.map((item, index) => (
        			<div className="topic-item">
                <a href={`${Config.BASE_URL}/topic/${encodeURIComponent(item.name)}`}>
                  <div className="topic-img" style={{backgroundImage: `url('${item.image}')`}}></div>
                  <div className="topic-title">{item.name}</div>
                </a>
              </div>))}
            </div>
          </div>}
          <div style={{minWidth:'40px',minHeight:'40px'}}></div>
        </div>
      </div>
    );
  }
}

export default TopicListPage;
