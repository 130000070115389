import React from 'react';
import * as Config from './../../config';

class Banner extends React.Component {
  onClick() {
    window.location.href="https://bit.ly/AlteaCare122";
  }

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if(/android/i.test(userAgent)) return "ANDROID";
    if(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "IOS";
    return "OTHERS";
  }

  render() {
    return (
      <div className="new-banner">
        <div className="d-flex flex-column flex-md-row">
          <div style={{minHeight:'20px',minWidth:'40px'}}></div>
          <div className="left">
            <img src={`${Config.BASE_URL}/img/new-banner-left.png`} alt=""/>
          </div>
          <div className="right align-self-center text-center">
            <img src={`${Config.BASE_URL}/img/new-banner-right.png`} alt=""/>
            <div style={{minHeight:'30px',minWidth:'30px'}}></div>
            {this.getMobileOperatingSystem() === 'ANDROID' && <a href="https://play.google.com/store/apps/details?id=com.dre.loyalty&hl=en&gl=US" target="_blank"><button className="banner-button">Download <strong>AlteaCare</strong> Sekarang</button></a>}
            {this.getMobileOperatingSystem() === 'IOS' && <a href="https://apps.apple.com/id/app/alteacare/id1571455658?l=id" target="_blank"><button className="banner-button">Download <strong>AlteaCare</strong> Sekarang</button></a>}
            {this.getMobileOperatingSystem() === 'OTHERS' && <a href="https://linktr.ee/DownloadAlteaCare" target="_blank"><button className="banner-button">Download <strong>AlteaCare</strong> Sekarang</button></a>}
            <div style={{minHeight:'30px',minWidth:'30px'}}></div>
            <div className="d-flex justify-content-center">
              <div><a href="https://play.google.com/store/apps/details?id=com.dre.loyalty&hl=en&gl=US" target="_blank" className="gtm linkClick"><img src={`${Config.BASE_URL}/img/new-google-play-logo.png`} alt=""/></a></div>
              <div><a href="https://apps.apple.com/id/app/alteacare/id1571455658?l=id" target="_blank" className="gtm linkClick"><img src={`${Config.BASE_URL}/img/new-app-store-logo.png`} alt=""/></a></div>
            </div>
          </div>
          <div style={{minHeight:'20px',minWidth:'40px'}}></div>
        </div>
      </div>
    );
  }
}

export default Banner;
