import React from 'react';
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";

import moment from 'moment';
import 'moment/locale/id'
import validator from 'validator';
import Swal from 'sweetalert2';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import * as Config from './../config';
import RadioButton from './../component/form/radio-button';
import { getDay } from 'date-fns';
import S3 from 'react-aws-s3';

class Vaccine extends React.Component {
  constructor(props) {
    super(props);

    moment.locale('id')

    this.resetState = this.resetState.bind(this);
    this.getCurrentPage = this.getCurrentPage.bind(this);
    this.allowPage = this.allowPage.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleDomisiliFileChange = this.handleDomisiliFileChange.bind(this);
    this.setBirthdate = this.setBirthdate.bind(this);
    this.setHospital = this.setHospital.bind(this);
    this.setProvince = this.setProvince.bind(this);
    this.setProfession = this.setProfession.bind(this);
    this.setCity = this.setCity.bind(this);
    this.setKecamatan = this.setKecamatan.bind(this);
    this.setKelurahan = this.setKelurahan.bind(this);
    this.setVaccineDate = this.setVaccineDate.bind(this);
    this.setSchedule = this.setSchedule.bind(this);
    this.clearIdentityImg = this.clearIdentityImg.bind(this);
    this.clearDomisiliImg = this.clearDomisiliImg.bind(this);
    this.goPage = this.goPage.bind(this);
    this.submit = this.submit.bind(this);
    this.submitSchedule = this.submitSchedule.bind(this);
    this.fetchRegisterVaccine = this.fetchRegisterVaccine.bind(this);
    this.fetchHospitalList = this.fetchHospitalList.bind(this);
    this.fetchTimeSlot = this.fetchTimeSlot.bind(this);
    this.fetchProvinceList = this.fetchProvinceList.bind(this);
    this.fetchCityList = this.fetchCityList.bind(this);
    this.fetchKecamatanList = this.fetchKecamatanList.bind(this);
    this.fetchKelurahanList = this.fetchKelurahanList.bind(this);

    this.state = {
      isLoading: false,
      vaccineType: null,
      page: 3,
      vaccine: { list: [], val: null },
      form: {
        // page 1
        hospitalList: [],
        hospitalListFilterLansia: [],
        hospitalListFilterPelayananPublik: [],
        hospitalListFilterPerusahaan: [],
        hospitalVal: '',
        professionList:['Pedagang Pasar','ASN','Tokoh Agama','Guru dan Dosen', 'Transjakarta', 'Pendamping Lansia', 'Lain - Lain'],
        professionListPerusahaan: ['PT Indocement Tunggal Prakarsa Tbk'],
        professionVal: '',
        identityList: ['KTP'],
        identityVal: 'KTP',
        identityNo: '',
        birthPlace: '',
        birthDate: moment().add(-60, 'years').toDate(),
        identityImg: null,
        identityImgPreview: null,
        domisiliImg: null,
        domisiliImgPreview: null,

        // page 2
        fullName: '',
        genderList: ['Pria', 'Wanita'],
        genderVal: '',
        maritalList: ['Menikah', 'Belum Menikah'],
        maritalVal: '',
        countryList: ['WNI', 'WNA'],
        countryVal: 'WNI',
        religionList: ['Kristen', 'Katolik', 'Buddha', 'Islam', 'Hindu', 'Khonghucu'],
        religionVal: '',

        // page 3
        phoneNo: '',
        email: '',
        housePhone: '',
        officePhone: '',
        identityAddress: '',
        provinceList: [],
        provinceVal: '',
        provinceIndex: '',
        cityList: [],
        cityVal: '',
        cityIndex: '',
        kecamatanList: [],
        kecamatanVal: '',
        kecamatanIndex: '',
        kelurahanList: [],
        kelurahanVal: '',
        kelurahanIndex: '',
        postalCode: '',
        address: '',

        // page 4
        motherName: '',
        fatherName: '',
        spouseName: '',

        // page 5
        emergencyFamilyName: '',
        emergencyFamilyRelationList: ['Ayah', 'Ibu', 'Anak', 'Saudara', 'Keluarga Jauh', 'Pendamping Lansia'],
        emergencyFamilyRelationVal: '',
        emergencyPhone: '',
        emergencyAddress: '',

        // page 6
        vaccineNoList: ['Pertama', 'Kedua'],
        vaccineNoVal: '',

        // page 7
        page7_1List: ['Ya', 'Tidak'],
        page7_1Val: '',
        page7_2List: ['Ya', 'Tidak'],
        page7_2Val: '',
        page7_3List: ['Ya, Pernah', 'Tidak'],
        page7_3Val: '',
        page7_4List: ['Ya', 'Tidak'],
        page7_4Val: '',
        page7_5List: ['Ya', 'Tidak'],
        page7_5Val: '',
        page7_6List: ['Ya', 'Tidak'],
        page7_6Val: '',
        page7_7List: ['Ya', 'Tidak'],
        page7_7Val: '',
        page7_8List: ['Ya', 'Tidak'],
        page7_8Val: '',
        page7_9List: ['Ya, Terkontrol', 'Ya, Tidak Terkontrol', 'Tidak'],
        page7_9Val: '',
        page7_10List: ['Ya, Terkontrol dan Pengobatan Teratur', 'Ya, Tidak Terkontrol', 'Tidak'],
        page7_10Val: '',
        page7_11List: ['Ya, Terkontrol dan Pengobatan Teratur', 'Ya, Tidak Terkontrol', 'Tidak'],
        page7_11Val: '',
        page7_12List: ['Ya, Terkontrol dan Tidak Sesak', 'Ya, Tidak Terkontrol', 'Tidak'],
        page7_12Val: '',
        page7_13List: ['Ya', 'Tidak'],
        page7_13Val: '',
        page7_14List: ['Ya', 'Tidak'],
        page7_14Val: '',
        page7_15List: ['Ya', 'Tidak'],
        page7_15Val: '',
        page7_16List: ['Ya', 'Tidak'],
        page7_16Val: '',
        page7_17List: ['Ya', 'Tidak'],
        page7_17Val: '',
        page7_18List: ['Ya', 'Tidak'],
        page7_18Val: '',

        //page7- V2:
        page7_IS_GEJALA_List: ['Ya', 'Tidak', 'Saya Vaksinasi ke-2'],
        page7_IS_GEJALA_Val: '',

        page7_IS_ALERGIC_List: ['Ya', 'Tidak'],
        page7_IS_ALERGIC_Val: '',

        page7_IS_PREGNANT_List: ['Ya, Pernah', 'Tidak'],
        page7_IS_PREGNANT_Val: '',

        page7_IS_DISEASE_List : ['Ya', 'Tidak'],
        page7_IS_DISEASE_Val: '',

        page7_IS_TRANSFUSION_List: ['Ya', 'Tidak'],
        page7_IS_TRANSFUSION_Val: '',

        page7_IS_CANCER_List: ['Ya', 'Tidak'],
        page7_IS_CANCER_Val: '',

        page7_IS_LUNGS_List: ['Ya', 'Tidak'],
        page7_IS_LUNGS_Val: '',

        page7_IS_HEART_List: ['Ya', 'Tidak'],
        page7_IS_HEART_Val: '',

        page7_IS_HARD_CLIMB_STAIR_List: ['Ya', 'Tidak'],
        page7_IS_HARD_CLIMB_STAIR_Val: '',

        page7_IS_FATIGUE_List: ['Ya', 'Tidak'],
        page7_IS_FATIGUE_Val: '',

        page7_IS_HAVE_DISEASE_List: ['Ya', 'Tidak'],
        page7_IS_HAVE_DISEASE_Val: '',

        page7_IS_WALK_List: ['Ya', 'Tidak'],
        page7_IS_WALK_Val: '',

        page7_IS_DROP_WEIGHT_List: ['Ya', 'Tidak'],
        page7_IS_DROP_WEIGHT_Val: '',

        // page 8
        termAgree: false,

        // page 10
        vaccineDate: '',
        vaccineStartDate: '',
        vaccineEndDate: '',
        holidayList: [],
        allowedDateList: [],
        hourList: [],
        hourVal: '',
      }
    };
    this.resetState();
  }

  async setSchedule(item) {
    this.state.vaccine.val = item;
    let hospital;
    for(let i=0;i<this.state.form.hospitalList.length;i++) {
      let hosp = this.state.form.hospitalList[i];
      if(hosp.name == item.hospital_name) {
        hospital = hosp;
      }
    }
    if(hospital != null) {
      this.state.form.vaccineStartDate =  moment().toDate() > moment(hospital.start_date).toDate() ? moment().toDate() : moment(hospital.start_date).toDate();
      this.state.form.vaccineEndDate =moment(hospital.end_date).toDate();
    }
    await this.fetchLocationVaccineCalendar();
    this.setState({
      vaccine: this.state.vaccine,
      form: this.state.form
    });

    this.goPage(10);
  }

  async fetchRegisterVaccine() {
    let formData = { };
    formData.userId = this.props.match.params.userID;
    try {
      let response = await axios.post(`${Config.API_URL}/get_register_vaccine_by_user`, formData);
      let data = response.data;
      if(data.statusCode === 200) {
        this.state.vaccine.list = data.data;
        console.log(this.state.vaccine);
        this.setState({vaccine: this.state.vaccine});
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Unable to connect to server', 'error');
    }
  }
  

  async fetchVaccineAvailability(){
    let formData = { };
    formData.hospital_name = this.state.form.hospitalVal.name;
    formData.vaccine_type = this.state.vaccineType;
    try {
      let vaccineNoAvailable = [];
      let response = await axios.post(`${Config.API_URL}/location_vaccine_available`, formData);
      let data = response.data;
      if(data.statusCode === 200) {
        let vaccineNo = data.data;
        vaccineNoAvailable = vaccineNo.map(item => {
          return item.vaccine_status
        })
        
        this.state.form.vaccineNoList = vaccineNoAvailable;
        this.setState({vaccine: this.state.form});
        console.log(vaccineNoAvailable)
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Unable to connect to server', 'error');
    }
  }

  async fetchLocationVaccineCalendar() {
    let formData = { };
    formData.hospital_name = this.state.vaccine.val.hospital_name;
    let response = await axios.post(`${Config.API_URL}/location_vaccine_calendar`, formData);
    try {
      let data = response.data;
      if(data.statusCode === 200) {
        this.state.allowedDateList = [];
        let allowedDate = data.data;
        for(let i=0;i<allowedDate.length;i++) {
          console.log("allow" + allowedDate[i].date)
          this.state.allowedDateList.push(moment(allowedDate[i].date).format('YYYY-MM-DD'));
        }
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Unable to connect to server', 'error');
    }
  }

  async fetchTimeSlot() {
    let formData = { };
    formData.hospitalName = this.state.vaccine.val.hospital_name;
    formData.date = moment(this.state.form.vaccineDate, true).format('YYYY-MM-DD');
    let response = await axios.post(`${Config.API_URL}/get_data_timeslot`, formData);
    try {
      let data = response.data;
      if(data.statusCode === 200) {
        this.state.form.hourList = data.data;
        this.setState({form: this.state.form});
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Unable to connect to server', 'error');
    }
  }

  async fetchHospitalList() {
    let response = await axios.get(`${Config.API_URL}/hospital_vaccine_list`);
    try {
      let data = response.data;
      if(data.statusCode === 200) {
        this.state.form.hospitalList = data.data;
        this.state.form.hospitalListFilterLansia = this.state.form.hospitalList.filter((item, index) =>  {
          return item.is_lansia === 1
        })
        this.state.form.hospitalListFilterPelayananPublik = this.state.form.hospitalList.filter((item, index) =>  {
          return item.is_pelayanan_publik === 1
        })

        this.state.form.hospitalListFilterPerusahaan = this.state.form.hospitalList.filter((item, index) =>  {
          return item.name.includes("Bina Husada") || item.name.includes("Indocement")
        })

        this.setState({form: this.state.form});
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Unable to connect to server', 'error');
    }
  }

  async fetchProvinceList() {
    let response = await axios.get(`${Config.API_URL}/place/province`);
    try {
      let data = response.data;
      if(data.statusCode === 200) {
        this.state.form.provinceList = data.data;
        this.setState({form: this.state.form});
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Unable to connect to server', 'error');
    }
  }

  async fetchCityList() {
    if(this.state.form.provinceVal == '') return;
    try {
      let response = await axios.get(`${Config.API_URL}/place/regencies/${this.state.form.provinceVal.id}`);
      let data = response.data;
      if(data.statusCode === 200) {
        this.state.form.cityList = data.data;
        this.state.form.cityVal = '';
        this.state.form.cityIndex = '';
        this.state.form.kecamatanList = [];
        this.state.form.kecamatanVal = '';
        this.state.form.kecamatanIndex = '';
        this.state.form.kelurahanList = [];
        this.state.form.kelurahanVal = '';
        this.state.form.kelurahanIndex = '';
        this.setState({form: this.state.form});
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Unable to connect to server', 'error');
    }
  }

  async fetchKecamatanList() {
    if(this.state.form.cityVal == '') return;
    let response = await axios.get(`${Config.API_URL}/place/district/${this.state.form.cityVal.id}`);
    try {
      let data = response.data;
      if(data.statusCode === 200) {
        this.state.form.kecamatanList = data.data;
        this.state.form.kecamatanVal = '';
        this.state.form.kecamatanIndex = '';
        this.state.form.kelurahanList = [];
        this.state.form.kelurahanVal = '';
        this.state.form.kelurahanIndex = '';
        console.log(this.state.form);
        this.setState({form: this.state.form});
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Unable to connect to server', 'error');
    }
  }

  async fetchKelurahanList() {
    if(this.state.form.kecamatanVal == '') return;
    let response = await axios.get(`${Config.API_URL}/place/village/${this.state.form.kecamatanVal.id}`);
    try {
      let data = response.data;
      if(data.statusCode === 200) {
        this.state.form.kelurahanList = data.data;
        this.state.form.kelurahanVal = '';
        this.state.form.kelurahanIndex = '';
        this.setState({form: this.state.form});
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Unable to connect to server', 'error');
    }
  }

  async getHolidayDate() {
    try {
      let response = await axios.get(`https://raw.githubusercontent.com/guangrei/Json-Indonesia-holidays/master/calendar.json`);
      let data = response.data;
      for(let day in data) {
        if(moment(day, 'YYYYMMDD', true).isValid() && moment(day, 'YYYYMMDD', true).format('YYYY-MM-DD') !== "2021-05-26" && moment(day, 'YYYYMMDD', true).format('YYYY-MM-DD') !== "2021-05-01") {
          this.state.form.holidayList.push(moment(day, 'YYYYMMDD', true).format('YYYY-MM-DD'));
        }
      }
      //console.log(this.state.form.holidayList);
      this.setState({form: this.state.form});
     
    } catch(error) {
      await Swal.fire('Gagal', 'Unable to connect to server', 'error');
    }
  }

  async componentDidMount() {
    this.getHolidayDate();
    this.fetchRegisterVaccine();
    this.fetchHospitalList();
    this.fetchProvinceList();
    
    // for development purpose
    // this.state.vaccineType = 'REGISTRATION';
    // this.state.page = 0;
    // this.state.form.identityNo = '3172012506940001';
    // this.state.form.birthPlace = 'Jakarta';
    // this.state.form.fullName = 'Yeremia Junanto Dermawan';
    // this.state.form.genderVal = 'Pria';
    // this.state.form.maritalVal = 'Belum Menikah';
    // this.state.form.countryVal = 'Indonesia';
    // this.state.form.religionVal = 'Kristen';
    // this.state.form.phoneNo = '6287709994175';
    // this.state.form.email = 'yeremiajunanto@gmail.com';
    // this.state.form.housePhone = '6287709994175';
    // this.state.form.officePhone = '6287709994175';
    // this.state.form.identityAddress = 'Jelambar aladding';
    // this.state.form.postalCode = '15211';
    // this.state.form.address = 'griya dadap';
    // this.state.form.motherName = 'mother';
    // this.state.form.fatherName = 'father';
    // this.state.form.spouseName = 'wife';
    // this.state.form.emergencyFamilyName = 'emergency';
    // this.state.form.emergencyFamilyRelationVal = 'Keluarga Dekat';
    // this.state.form.emergencyPhone = '08718273123';
    // this.state.form.emergencyAddress = 'addresssss';
    // this.state.form.vaccineNoVal = 'Pertama';
    // this.state.form.termAgree = true;
    // this.state.form.page7_1Val = 'Tidak';
    // this.state.form.page7_2Val = 'Tidak';
    // this.state.form.page7_3Val = 'Ya, Pernah';
    // this.state.form.page7_4Val = 'Ya';
    // this.state.form.page7_5Val = 'Tidak';
    // this.state.form.page7_6Val = 'Ya';
    // this.state.form.page7_7Val = 'Ya';
    // this.state.form.page7_8Val = 'Tidak';
    // this.state.form.page7_9Val = 'Ya, Terkontrol';
    // this.state.form.page7_10Val = 'Ya, Terkontrol dan Pengobatan Teratur';
    // this.state.form.page7_11Val = 'Ya, Terkontrol dan Pengobatan Teratur';
    // this.state.form.page7_12Val = 'Ya, Terkontrol dan Tidak Sesak';
    // this.state.form.page7_13Val = 'Ya';
    // this.state.form.page7_14Val = 'Ya';
    // this.state.form.page7_15Val = 'Ya';
    // this.state.form.page7_16Val = 'Ya';
    // this.state.form.page7_17Val = 'Ya';
    // this.state.form.page7_18Val = 'Ya';
  }

  resetState() {
    this.fetchRegisterVaccine();
    this.state.isLoading = false;
    this.state.vaccineType = null;
    this.state.page = 0;

    // page 1
    this.state.form.hospitalVal = '';
    this.state.form.identityList = ['KTP'];
    this.state.form.identityVal = 'KTP';
    this.state.form.identityNo = '';
    this.state.form.birthPlace = '';
    this.state.form.birthDate = moment().add(-60, 'years').toDate();
    this.state.form.identityImg = null;
    this.state.form.identityImgPreview = null;
    this.state.form.domisiliImg = null;
    this.state.form.domisiliImgPreview = null;

    // page 2
    this.state.form.fullName = '';
    this.state.form.genderVal = '';
    this.state.form.maritalList = ['Menikah', 'Belum Menikah'];
    this.state.form.maritalVal = '';
    this.state.form.countryList = ['WNI', 'WNA'];
    this.state.form.countryVal = 'WNI';
    this.state.form.religionList = ['Kristen', 'Katolik', 'Buddha', 'Islam', 'Hindu', 'Khonghucu'];
    this.state.form.religionVal = '';

    // page 3
    this.state.form.phoneNo = '';
    this.state.form.email = '';
    this.state.form.housePhone = '';
    this.state.form.officePhone = '';
    this.state.form.identityAddress = '';
    this.state.form.provinceVal = '';
    this.state.form.provinceIndex = '';
    this.state.form.cityVal = '';
    this.state.form.cityIndex = '';
    this.state.form.kecamatanVal = '';
    this.state.form.kecamatanIndex = '';
    this.state.form.kelurahanVal = '';
    this.state.form.kelurahanIndex = '';
    this.state.form.postalCode = '';
    this.state.form.address = '';

    // page 4
    this.state.form.motherName = '';
    this.state.form.fatherName = '';
    this.state.form.spouseName = '';

    // page 5
    this.state.form.emergencyFamilyName = '';
    this.state.form.emergencyFamilyRelationList = ['Ayah', 'Ibu', 'Anak', 'Saudara', 'Keluarga Jauh', 'Lain-lain'];
    this.state.form.emergencyFamilyRelationVal = '';
    this.state.form.emergencyPhone = '';
    this.state.form.emergencyAddress = '';

    // page 6
    this.state.form.vaccineNoList = ['Pertama', 'Kedua'];
    this.state.form.vaccineNoVal = '';

    // page 7
    this.state.form.page7_1List = ['Ya', 'Tidak'];
    this.state.form.page7_1Val = '';
    this.state.form.page7_2List = ['Ya', 'Tidak'];
    this.state.form.page7_2Val = '';
    this.state.form.page7_3List = ['Ya, Pernah', 'Tidak'];
    this.state.form.page7_3Val = '';
    this.state.form.page7_4List = ['Ya', 'Tidak'];
    this.state.form.page7_4Val = '';
    this.state.form.page7_5List = ['Ya', 'Tidak'];
    this.state.form.page7_5Val = '';
    this.state.form.page7_6List = ['Ya', 'Tidak'];
    this.state.form.page7_6Val = '';
    this.state.form.page7_7List = ['Ya', 'Tidak'];
    this.state.form.page7_7Val = '';
    this.state.form.page7_8List = ['Ya', 'Tidak'];
    this.state.form.page7_8Val = '';
    this.state.form.page7_9List = ['Ya, Terkontrol', 'Ya, Tidak Terkontrol', 'Tidak'];
    this.state.form.page7_9Val = '';
    this.state.form.page7_10List = ['Ya, Terkontrol dan Pengobatan Teratur', 'Ya, Tidak Terkontrol', 'Tidak'];
    this.state.form.page7_10Val = '';
    this.state.form.page7_11List = ['Ya, Terkontrol dan Pengobatan Teratur', 'Ya, Tidak Terkontrol', 'Tidak'];
    this.state.form.page7_11Val = '';
    this.state.form.page7_12List = ['Ya, Terkontrol dan Tidak Sesak', 'Ya, Tidak Terkontrol', 'Tidak'];
    this.state.form.page7_12Val = '';
    this.state.form.page7_13List = ['Ya', 'Tidak'];
    this.state.form.page7_13Val = '';
    this.state.form.page7_14List = ['Ya', 'Tidak'];
    this.state.form.page7_14Val = '';
    this.state.form.page7_15List = ['Ya', 'Tidak'];
    this.state.form.page7_15Val = '';
    this.state.form.page7_16List = ['Ya', 'Tidak'];
    this.state.form.page7_16Val = '';
    this.state.form.page7_17List = ['Ya', 'Tidak'];
    this.state.form.page7_17Val = '';
    this.state.form.page7_18List = ['Ya', 'Tidak'];
    this.state.form.page7_18Val = '';

    // page 8
    this.state.form.termAgree = false;

    // page 10
    this.state.form.vaccineDate = '';
    this.state.form.vaccineStartDate = '';
    this.state.form.vaccineEndDate = '';
    this.state.form.hourVal = '';
    this.state.form.hourList = [];

    this.setState(this.state);
  }

  setVaccineDate(date) {
    this.state.form.vaccineDate = moment(date).toDate();
    this.fetchTimeSlot();
    this.setState({ form: this.state.form });
  }

  setBirthdate(date) {
    this.state.form.birthDate = moment(date).toDate();
    this.setState({ form: this.state.form });
  }

  setHospital(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    if(this.state.vaccineType === 'Daftar Mandiri(Pelayanan Publik)'){
      this.state.form.hospitalVal = value == '' ? '' : this.state.form.hospitalListFilterPelayananPublik[value];
    } else if(this.state.vaccineType === 'Daftar Mandiri(Lansia)'){
      this.state.form.hospitalVal = value == '' ? '' : this.state.form.hospitalListFilterLansia[value];
    } else if(this.state.vaccineType === 'Daftar Mandiri(Perusahaan)'){
      this.state.form.hospitalVal = value == '' ? '' : this.state.form.hospitalListFilterPerusahaan[value]; 
    } else {
      this.state.form.hospitalVal = value == '' ? '' : this.state.form.hospitalList[value];
    }

    this.fetchVaccineAvailability()
    this.setState({ form: this.state.form });
  }

  setProvince(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.state.form.provinceIndex = value;
    this.state.form.provinceVal = value == '' ? '' : this.state.form.provinceList[value];
    this.fetchCityList();
    this.setState({ form: this.state.form });
  }

  setProfession(event){
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    if(this.state.vaccineType === "Daftar Mandiri(Perusahaan)"){
      this.state.form.professionVal = value == '' ? '' : this.state.form.professionListPerusahaan[value];
    } else {
      this.state.form.professionVal = value == '' ? '' : this.state.form.professionList[value];
    }

    this.setState({ form: this.state.form });
  }

  setCity(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.state.form.cityIndex = value;
    this.state.form.cityVal = value == '' ? '' : this.state.form.cityList[value];
    this.fetchKecamatanList();
    this.setState({ form: this.state.form });
  }

  setKecamatan(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.state.form.kecamatanIndex = value;
    this.state.form.kecamatanVal = value == '' ? '' : this.state.form.kecamatanList[value];
    this.fetchKelurahanList();
    this.setState({ form: this.state.form });
  }

  setKelurahan(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.state.form.kelurahanIndex = value;
    this.state.form.kelurahanVal = value == '' ? '' : this.state.form.kelurahanList[value];
    this.setState({ form: this.state.form });
  }

  getCurrentPage() {
    return this.state.page;
  }

  allowPage(page) {
    if(page === 1) {
      if(this.state.vaccineType === null) return false;
      return true;
    } else if(page === 2) {
      if(this.state.vaccineType === 'Daftar Mandiri(Pelayanan Publik)' && this.state.form.professionVal === '') return false;
      if(this.state.form.hospitalVal === '') return false;
      if(this.state.form.identityNo === '') return false;
      if(this.state.form.birthPlace === '') return false;
      if(this.state.form.birthDate === '') return false;
      if(this.state.form.identityImg === null) return false;
      return true;
    } else if(page === 3) {
      if(this.state.form.fullName === '') return false;
      if(this.state.form.genderVal === '') return false;
      if(this.state.form.maritalVal === '') return false;
      if(this.state.form.religionVal === '') return false;
      return true;
    } else if(page === 4) {
      if(this.state.form.phoneNo === '') return false;
      if(this.state.form.email === '') return false;
      // if(this.state.form.housePhone === '') return false;
      // if(this.state.form.officePhone === '') return false;
      if(this.state.form.identityAddress === '') return false;
      if(this.state.form.provinceVal === '') return false;
      if(this.state.form.cityVal === '') return false;
      if(this.state.form.kecamatanVal === '') return false;
      if(this.state.form.kelurahanVal === '') return false;
      if(this.state.form.postalCode === '') return false;
      return true;
    } else if(page === 5) {
      //if(this.state.form.motherName === '') return false;
      //if(this.state.form.fatherName === '') return false;
      //if(this.state.form.spouseName === '') return false;
      return true;
    } else if(page === 6) {
      if(this.state.form.emergencyFamilyName === '') return false;
      if(this.state.form.emergencyFamilyRelationVal === '') return false;
      if(this.state.form.emergencyPhone === '') return false;
      //if(this.state.form.emergencyAddress === '') return false;
      return true;
    } else if(page === 7) {
      if(this.state.form.vaccineNoVal === '') return false;
      return true;
    } else if(page === 8) {
      // if(this.state.form.page7_1Val === '') return false;
      // if(this.state.form.page7_2Val === '') return false;
      // if(this.state.form.page7_3Val === '') return false;
      // if(this.state.form.page7_4Val === '') return false;
      // if(this.state.form.page7_5Val === '') return false;
      // if(this.state.form.page7_6Val === '') return false;
      // if(this.state.form.page7_7Val === '') return false;
      // if(this.state.form.page7_8Val === '') return false;
      // if(this.state.form.page7_9Val === '') return false;
      // if(this.state.form.page7_10Val === '') return false;
      // if(this.state.form.page7_11Val === '') return false;
      // if(this.state.form.page7_12Val === '') return false;
      // if(this.state.form.page7_13Val === '') return false;
      // if(this.state.form.page7_14Val === '') return false;
      // if(this.state.form.page7_15Val === '') return false;
      // if(this.state.form.page7_16Val === '') return false;
      // if(this.state.form.page7_17Val === '') return false;
      // if(this.state.form.page7_18Val === '') return false;


      //if(this.state.form.page7_IS_GEJALA_Val === '') return false;
      if(this.state.form.page7_IS_ALERGIC_Val === '') return false;
      if(this.state.form.page7_IS_DISEASE_Val === '') return false;
      if(this.state.form.page7_IS_TRANSFUSION_Val === '') return false;
      if(this.state.form.page7_IS_CANCER_Val === '') return false;
      if(this.state.form.page7_IS_HEART_Val === '') return false;
      if(moment().diff(this.state.form.birthDate, 'years') > 59){
        if(this.state.form.page7_IS_HARD_CLIMB_STAIR_Val === '') return false;
        if(this.state.form.page7_IS_FATIGUE_Val === '') return false;
        if(this.state.form.page7_IS_HAVE_DISEASE_Val === '') return false;
        if(this.state.form.page7_IS_WALK_Val === '') return false;
        if(this.state.form.page7_DROP_WEIGHT_Val === '') return false;
      }

      return true;
    } else if(page === 9) {
      return this.state.form.termAgree;
    } else if(page === 11) {
      if(this.state.form.vaccineDate === '') return false;
      if(this.state.form.hourVal === '') return false;
      return true;
    }
    return false;
  }

  handleFormChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    this.state.form[name] = value;
    this.setState({ form: this.state.form });
  }

  handleFileChange(event) {
    let context = this;
    let file = event.target.files[0];
    if(file != null) {
      this.state.form.identityImg = file;
      let reader = new FileReader();
      reader.addEventListener("load", function() {
        context.state.form.identityImgPreview = reader.result;
        context.setState({form: context.state.form});
      }, false);
      reader.readAsDataURL(file);
    } else {
      this.state.form.identityImg = null;
      this.state.form.identityImgPreview = null;
    }
    this.setState({form: this.state.form});
  }

  handleDomisiliFileChange(event) {
    let context = this;
    let file = event.target.files[0];
    if(file != null) {
      this.state.form.domisiliImg = file;
      let reader = new FileReader();
      reader.addEventListener("load", function() {
        context.state.form.domisiliImgPreview = reader.result;
        context.setState({form: context.state.form});
      }, false);
      reader.readAsDataURL(file);
    } else {
      this.state.form.domisiliImg = null;
      this.state.form.domisiliImgPreview = null;
    }
    this.setState({form: this.state.form});
  }

  async uploadToS3(){
    let file = this.state.form.identityImg;
    let newFileName = "test";

    const config = {
      bucketName: "elasticbeanstalk-us-east-1-270086613765",
      dirName: "/testUpload/",
      region: "us-east-1",
      accessKeyId: "AKIAT5YTKVMCSXLFCLNW",
      secretAccessKey: "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"
    }

    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileName).then(data =>{
      //console.log(data);
      if(data.status === 204){
        console.log("success")
        return true;
      } else {
        console.log("fail")
        return false;
      }
    });
  }

  clearIdentityImg() {
    this.state.form.identityImg = null;
    this.setState({ form: this.state.form });
  }

  clearDomisiliImg() {
    this.state.form.domisiliImg = null;
    this.setState({ form: this.state.form });
  }

  goPage(page) {
    if(page === 2) {
      if(this.state.form.identityNo.length != 16) return Swal.fire('No KTP wajib 16 angka');
      if(this.state.vaccineType === 'Daftar Mandiri(Lansia)'){
        if(moment().diff(this.state.form.birthDate, 'years') <= 59) return Swal.fire('Umur pendaftar lansia harus 60 tahun atau lebih');
      }else if(this.state.vaccineType === 'Daftar Mandiri(Pelayanan Publik)') {
        if(moment().diff(this.state.form.birthDate, 'years') <= 18) return Swal.fire('Umur pendaftar harus diatas 18 tahun');
      }else if(this.state.vaccineType === 'Daftar Mandiri(Altea)') {
        if(moment().diff(this.state.form.birthDate, 'years') <= 18) return Swal.fire('Umur pendaftar harus diatas 18 tahun');
      }else if(this.state.vaccineType === 'Daftar Mandiri(Perusahaan)') {
        if(moment().diff(this.state.form.birthDate, 'years') <= 18) return Swal.fire('Umur pendaftar harus diatas 18 tahun');
      }
    }
    if(page === 4) {
      if(!validator.isEmail(this.state.form.email)) return Swal.fire('Email tidak valid');
    }
    this.setState({page: page});
  }

  async submit() {
    if(this.state.isLoading) return;
    this.setState({isLoading: true});
    let formData = new FormData();
    formData.append('userId', this.props.match.params.userID);
    formData.append('type_vaccine', this.state.vaccineType);
    formData.append('hospital_name', this.state.form.hospitalVal.name);
    formData.append('type_identity', this.state.form.identityVal);
    formData.append('card_id_number', this.state.form.identityNo);
    formData.append('birth_place', this.state.form.birthPlace);
    formData.append('birth_date', moment(this.state.form.birthDate, true).format('DD/MM/YYYY'));
    formData.append('photoPath', this.state.form.identityImg);
    if(this.state.form.professionVal != null) formData.append('profession', this.state.form.professionVal);
    if(this.state.form.domisiliImg != null) formData.append('domicile_photo', this.state.form.domisiliImg);
    formData.append('fullname', this.state.form.fullName);
    formData.append('gender', this.state.form.genderVal);
    formData.append('status', this.state.form.maritalVal);
    formData.append('nationality', this.state.form.countryVal);
    formData.append('religion', this.state.form.religionVal);
    formData.append('phone_number', this.state.form.phoneNo);
    formData.append('email', this.state.form.email);
    formData.append('phone_house', this.state.form.housePhone);
    formData.append('phone_office', this.state.form.officePhone);
    formData.append('address', this.state.form.identityAddress);
    formData.append('province', this.state.form.provinceVal.name);
    formData.append('city', this.state.form.cityVal.name);
    formData.append('kecamatan', this.state.form.kecamatanVal.name);
    formData.append('kelurahan', this.state.form.kelurahanVal.name);
    formData.append('kodepos', this.state.form.postalCode);
    formData.append('alamat_domisili', this.state.form.address);
    formData.append('nama_ibu', this.state.form.motherName);
    formData.append('nama_ayah', this.state.form.fatherName);
    formData.append('nama_suami_atau_istri', this.state.form.spouseName);
    formData.append('nama_keluarga_emergensi', this.state.form.emergencyFamilyName);
    formData.append('hubungan_keluarga_emergensi', this.state.form.emergencyFamilyRelationVal);
    formData.append('no_kontak_emergensi', this.state.form.emergencyPhone);
    formData.append('alamat_emergensi', this.state.form.emergencyAddress);
    formData.append('status_vaksinasi', this.state.form.vaccineNoVal);
    formData.append('is_covid', '');
    formData.append('is_gejala', '');
    formData.append('is_exposed_covid', '');
    formData.append('is_pregnant', this.state.form.page7_IS_PREGNANT_Val);
    formData.append('is_alergic', this.state.form.page7_IS_ALERGIC_Val);
    formData.append('is_disease', this.state.form.page7_IS_DISEASE_Val);
    formData.append('is_cancer', this.state.form.page7_IS_CANCER_Val);
    formData.append('is_transfusion', this.state.form.page7_IS_TRANSFUSION_Val);
    formData.append('is_heart', this.state.form.page7_IS_HEART_VAL);
    formData.append('is_epilepsy', '');
    formData.append('is_diabetes', '');
    formData.append('is_hiv', '');
    formData.append('is_lungs', '');
    formData.append('is_another_vaccine', '');
    formData.append('is_hard_climb_stair', this.state.form.page7_IS_HARD_CLIMB_STAIR_Val);
    formData.append('is_fatigue', this.state.form.page7_IS_FATIGUE_Val);
    formData.append('is_have_disease', this.state.form.page7_IS_HAVE_DISEASE_Val);
    formData.append('is_walk', this.state.form.page7_IS_WALK_Val);
    formData.append('is_drop_weight', this.state.form.page7_IS_DROP_WEIGHT_Val);
    try {
      let response = await axios.post(`${Config.API_URL}/create_register_vaccine`, formData);
      let data = response.data;
      if(data.statusCode === 200) {
        this.setState({isLoading: false});
        await this.fetchRegisterVaccine();
        this.goPage(9);
      } else {
        await Swal.fire('Gagal', data.statusMessage, 'warning');
        this.setState({isLoading: false});
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Unable to connect to server', 'error');
      this.setState({isLoading: false});
    }
    this.setState({isLoading: false});
  }

  async submitLegacy() {
    const test = await this.uploadToS3();
    if(this.state.isLoading) return;
    this.setState({isLoading: true});
    let formData = new FormData();
    formData.append('userId', this.props.match.params.userID);
    formData.append('type_vaccine', this.state.vaccineType);
    formData.append('hospital_name', this.state.form.hospitalVal.name);
    formData.append('type_identity', this.state.form.identityVal);
    formData.append('card_id_number', this.state.form.identityNo);
    formData.append('birth_place', this.state.form.birthPlace);
    formData.append('birth_date', moment(this.state.form.birthDate, true).format('DD/MM/YYYY'));
    formData.append('photoPath', this.state.form.identityImg);
    if(this.state.form.professionVal != null) formData.append('profession', this.state.form.professionVal);
    if(this.state.form.domisiliImg != null) formData.append('domicile_photo', this.state.form.domisiliImg);
    formData.append('fullname', this.state.form.fullName);
    formData.append('gender', this.state.form.genderVal);
    formData.append('status', this.state.form.maritalVal);
    formData.append('nationality', this.state.form.countryVal);
    formData.append('religion', this.state.form.religionVal);
    formData.append('phone_number', this.state.form.phoneNo);
    formData.append('email', this.state.form.email);
    formData.append('phone_house', this.state.form.housePhone);
    formData.append('phone_office', this.state.form.officePhone);
    formData.append('address', this.state.form.identityAddress);
    formData.append('province', this.state.form.provinceVal.name);
    formData.append('city', this.state.form.cityVal.name);
    formData.append('kecamatan', this.state.form.kecamatanVal.name);
    formData.append('kelurahan', this.state.form.kelurahanVal.name);
    formData.append('kodepos', this.state.form.postalCode);
    formData.append('alamat_domisili', this.state.form.address);
    formData.append('nama_ibu', this.state.form.motherName);
    formData.append('nama_ayah', this.state.form.fatherName);
    formData.append('nama_suami_atau_istri', this.state.form.spouseName);
    formData.append('nama_keluarga_emergensi', this.state.form.emergencyFamilyName);
    formData.append('hubungan_keluarga_emergensi', this.state.form.emergencyFamilyRelationVal);
    formData.append('no_kontak_emergensi', this.state.form.emergencyPhone);
    formData.append('alamat_emergensi', this.state.form.emergencyAddress);
    formData.append('status_vaksinasi', this.state.form.vaccineNoVal);
    formData.append('is_covid', this.state.form.page7_1Val);
    formData.append('is_gejala', this.state .form.page7_2Val);
    formData.append('is_exposed_covid', this.state.form.page7_3Val);
    formData.append('is_pregnant', this.state.form.page7_4Val);
    formData.append('is_alergic', this.state.form.page7_5Val);
    formData.append('is_disease', this.state.form.page7_6Val);
    formData.append('is_cancer', this.state.form.page7_7Val);
    formData.append('is_transfusion', this.state.form.page7_8Val);
    formData.append('is_epilepsy', this.state.form.page7_9Val);
    formData.append('is_diabetes', this.state.form.page7_10Val);
    formData.append('is_hiv', this.state.form.page7_11Val);
    formData.append('is_lungs', this.state.form.page7_12Val);
    formData.append('is_another_vaccine', this.state.form.page7_13Val);
    formData.append('is_hard_climb_stair', this.state.form.page7_14Val);
    formData.append('is_fatigue', this.state.form.page7_15Val);
    formData.append('is_have_disease', this.state.form.page7_16Val);
    formData.append('is_walk', this.state.form.page7_17Val);
    formData.append('is_drop_weight', this.state.form.page7_18Val);
    let response = await axios.post(`${Config.API_URL}/create_register_vaccine`, formData);
    try {
      let data = response.data;
      if(data.statusCode === 200) {
        this.setState({isLoading: false});
        await this.fetchRegisterVaccine();
        this.goPage(9);
      } else {
        await Swal.fire('Gagal', data.statusMessage, 'warning');
        this.setState({isLoading: false});
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Unable to connect to server', 'error');
      this.setState({isLoading: false});
    }
    this.setState({isLoading: false});
  }

  async submitSchedule() {
    if(this.state.isLoading) return;
    this.state.isLoading = true;
    let formData = { };
    formData.userId = this.props.match.params.userID;
    formData.date = moment(this.state.form.vaccineDate, true).format('YYYY-MM-DD');
    formData.timeslot_id = this.state.form.hourVal.timeslot_id;
    formData.register_vaccine_id = this.state.vaccine.val.register_vaccine_id;
    let response = await axios.post(`${Config.API_URL}/create_schedule`, formData);
    try {
      let data = response.data;
      if(data.statusCode === 200) {
        this.state.isLoading = false;
        this.goPage(11);
      } else {
        await Swal.fire('Gagal', data.statusMessage, 'warning');
        this.state.isLoading = false;
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Unable to connect to server', 'error');
      this.state.isLoading = false;
    }
    this.state.isLoading = false;
  }

  setVaccineType(type){
      this.state.vaccineType = type;
      this.setState(this.state);
      //console.log(this.state)
  }

  renderLegacyQuestion (){
    return (
      <div className="seventh-page">
        <div className="topbar">
          <div style={{minWidth: '16px'}}></div>
          <div onClick={() => this.setState({page: 6})} className="arrow"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
          <div className="text">Screening Vaksin</div>
          <div style={{minWidth: '40px'}}></div>
        </div>
        <div className="container">
          <div style={{minHeight: '16px'}}></div>
          <div className="form-group">
            <label>Apakah ada kontak dengan orang yang sedang dalam pemeriksaan/terkonfirmasi/sedang dalam perawatan karena penyakit COVID-19 dalam waktu 14 hari terakhir?<span className="text-danger">*</span></label>
            {this.state.form.page7_1List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_1Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_1Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Jika pertanyaan nomor 1 (satu) Ya dan mengalami gejala demam batuk/pilek/sesak napas dalam 7 hari terakhir? <span className="text-danger">*</span></label>
            {this.state.form.page7_2List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_2Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_2Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda pernah terkonfirmasi menderita COVID-19 ? <span className="text-danger">*</span></label>
            {this.state.form.page7_3List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_3Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_3Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda sedang hamil atau menyusui ? <span className="text-danger">*</span></label>
            {this.state.form.page7_4List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_4Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_4Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Pertanyaan untuk vaksinasi ke-2: <br/>Apakah Anda memiliki riwayat alergi berat seperti sesak napas, bengkak dan urtikaria seluruh badan atau reaksi berat lainnya setelah divaksin? <span className="text-danger">*</span></label>
            {this.state.form.page7_5List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_5Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_5Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda sedang menderita penyakit jantung, penyakit ginjal kronis / cuci darah dan penyakit hati / liver ? <span className="text-danger">*</span></label>
            {this.state.form.page7_6List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_6Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_6Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda sedang menderita dan mendapat pengobatan penyakit kanker ? <span className="text-danger">*</span></label>
            {this.state.form.page7_7List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_7Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_7Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda sedang mendapatkan pengobatan gangguan untuk pembekuan darah, defisiensi imun, penerima produk darah/transfusi? <span className="text-danger">*</span></label>
            {this.state.form.page7_8List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_8Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_8Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda memiliki riwayat penyakit epilepsi? <span className="text-danger">*</span></label>
            {this.state.form.page7_9List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_9Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_9Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda menderita Penyakit Diabetes Melitus ? <span className="text-danger">*</span></label>
            {this.state.form.page7_10List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_10Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_10Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda menderita Penyakit HIV? <span className="text-danger">*</span></label>
            {this.state.form.page7_11List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_11Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_11Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda memiliki penyakit paru (Asma, PPOK, TBC) ? <span className="text-danger">*</span></label>
            {this.state.form.page7_12List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_12Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_12Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda mendapatkan vaksinasi lain kurang dari satu bulan kebelakang? <span className="text-danger">*</span></label>
            {this.state.form.page7_13List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_13Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_13Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda mengalami Kesulitan untuk naik 10 anak Tangga ? <span className="text-danger">*</span></label>
            {this.state.form.page7_14List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_14Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_14Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda sering merasa kelelahan ? <span className="text-danger">*</span></label>
            {this.state.form.page7_15List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_15Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_15Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda memiliki paling sedikit 5 dari 11 penyakit (Hipertensi, diabetes, kanker, penyakit paru kronis, serangan jantung, gagal jantung kongestif, nyeri dada, asma, nyeri sendi, stroke dan penyakit ginjal)? <span className="text-danger">*</span></label>
            {this.state.form.page7_16List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_16Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_16Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda mengalami kesulitan berjalan kira-kira 100 sampai 200 meter? <span className="text-danger">*</span></label>
            {this.state.form.page7_17List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_17Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_17Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda mengalami penurunan berat badan yang bermakna dalam setahun terakhir? <span className="text-danger">*</span></label>
            {this.state.form.page7_18List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_18Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_18Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <button onClick={() => this.setState({page: 8})} className={`color-button ${!this.allowPage(8) ? 'disabled' : ''}`} disabled={!this.allowPage(8)} style={{width: '100%'}}>Lanjutkan</button>
          </div>
        </div>l
      </div>
    );
  }

  renderQuestion(){
 // page 7
    return (
      <div className="seventh-page">
        <div className="topbar">
          <div style={{minWidth: '16px'}}></div>
          <div onClick={() => this.setState({page: 6})} className="arrow"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
          <div className="text">Screening Vaksin</div>
          <div style={{minWidth: '40px'}}></div>
        </div>
        <div className="container">
          <div style={{minHeight: '16px'}}></div>
          {this.state.form.vaccineNoVal === 'Pertama' && <div className="form-group">
            <label>Apakah Anda memiliki riwayat alergi berat seperti sesak napas, bengkak dan urtikaria seluruh badan atau reaksi berat lainnya karena vaksin?<span className="text-danger">*</span></label>
            {this.state.form.page7_IS_ALERGIC_List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_IS_ALERGIC_Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_IS_ALERGIC_Val === item}></RadioButton></div>
            ))}
          </div>}

          {this.state.form.vaccineNoVal === 'Kedua' && <div className="form-group">
            <label>Apakah Anda memiliki riwayat alergi berat setelah divaksinasi COVID-19 sebelumnya? <span className="text-danger">*</span></label>
            {this.state.form.page7_IS_ALERGIC_List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_IS_ALERGIC_Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_IS_ALERGIC_Val === item}></RadioButton></div>
            ))}
          </div>}

          <div className="form-group">
            <label>Apakah Anda sedang hamil atau menyusui ? <span className="text-danger">*</span></label>
            {this.state.form.page7_IS_PREGNANT_List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_IS_PREGNANT_Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_IS_PREGNANT_Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda mengidap penyakit autoimun seperti asma, lupus? <span className="text-danger">*</span></label>
            {this.state.form.page7_IS_DISEASE_List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_IS_DISEASE_Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_IS_DISEASE_Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda sedang mendapat pengobatan untuk gangguan pembekuan darah, kelainan darah, defisiensi imun dan penerima produk darah/transfusi? <span className="text-danger">*</span></label>
            {this.state.form.page7_IS_TRANSFUSION_List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_IS_TRANSFUSION_Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_IS_TRANSFUSION_Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda sedang menderita dan mendapat pengobatan penyakit kanker ? <span className="text-danger">*</span></label>
            {this.state.form.page7_IS_CANCER_List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_IS_CANCER_Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_IS_CANCER_Val === item}></RadioButton></div>
            ))}
          </div>
          <div className="form-group">
            <label>Apakah Anda memiliki penyakit jantung berat dalam keadaan sesak? <span className="text-danger">*</span></label>
            {this.state.form.page7_IS_HEART_List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_IS_HEART_Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_IS_HEART_Val === item}></RadioButton></div>
            ))}
          </div>

          { moment().diff(this.state.form.birthDate, 'years') > 59 && <div className="form-group">
            <label>Apakah Anda mengalami Kesulitan untuk naik 10 anak Tangga ? <span className="text-danger">*</span></label>
            {this.state.form.page7_IS_HARD_CLIMB_STAIR_List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_IS_HARD_CLIMB_STAIR_Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_IS_HARD_CLIMB_STAIR_Val === item}></RadioButton></div>
            ))}
          </div>}

          {moment().diff(this.state.form.birthDate, 'years') > 59 && <div className="form-group">
            <label>Apakah Anda sering merasa kelelahan ? <span className="text-danger">*</span></label>
            {this.state.form.page7_IS_FATIGUE_List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_IS_FATIGUE_Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_IS_FATIGUE_Val === item}></RadioButton></div>
            ))}
          </div>}
          {moment().diff(this.state.form.birthDate, 'years') > 59 && <div className="form-group">
            <label>Apakah Anda memiliki paling sedikit 5 dari 11 penyakit (Hipertensi, diabetes, kanker, penyakit paru kronis, serangan jantung, gagal jantung kongestif, nyeri dada, asma, nyeri sendi, stroke dan penyakit ginjal)? <span className="text-danger">*</span></label>
            {this.state.form.page7_IS_HAVE_DISEASE_List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_IS_HAVE_DISEASE_Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_IS_HAVE_DISEASE_Val === item}></RadioButton></div>
            ))}
          </div>}
          {moment().diff(this.state.form.birthDate, 'years') > 59 && <div className="form-group">
            <label>Apakah Anda mengalami kesulitan berjalan kira-kira 100 sampai 200 meter? <span className="text-danger">*</span></label>
            {this.state.form.page7_IS_WALK_List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_IS_WALK_Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_IS_WALK_Val === item}></RadioButton></div>
            ))}
          </div>}
          {moment().diff(this.state.form.birthDate, 'years') > 59 && <div className="form-group">
            <label>Apakah Anda mengalami penurunan berat badan yang bermakna dalam setahun terakhir? <span className="text-danger">*</span></label>
            {this.state.form.page7_IS_DROP_WEIGHT_List.map((item, index) => (
              <div key={index} onClick={() => { this.state.form.page7_IS_DROP_WEIGHT_Val = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.page7_IS_DROP_WEIGHT_Val === item}></RadioButton></div>
            ))}
          </div>}
          <div className="form-group">
            <button onClick={() => this.setState({page: 8})} className={`color-button ${!this.allowPage(8) ? 'disabled' : ''}`} disabled={!this.allowPage(8)} style={{width: '100%'}}>Lanjutkan</button>
          </div>
      </div>
      </div>
    );
  }

  renderHospitalListFilter() {
    //console.log(this.state.vaccineType)
    if(this.state.vaccineType === "Daftar Mandiri(Lansia)"){
      return (this.state.form.hospitalListFilterLansia.map((item,index) =>
          <option key={index} value={index}>{item.name}</option>
      ));
    } else if(this.state.vaccineType === "Daftar Mandiri(Pelayanan Publik)"){
      console.log(this.state.vaccineType)
      return (this.state.form.hospitalListFilterPelayananPublik.map((item,index) =>
          <option key={index} value={index}>{item.name}</option>
      ));
    }else if(this.state.vaccineType === "Daftar Mandiri(Perusahaan)"){
      console.log(this.state.vaccineType)
      return (this.state.form.hospitalListFilterPerusahaan.map((item,index) =>
          <option key={index} value={index}>{item.name}</option>
      ));
    }else {
      console.log(this.state.vaccineType)
      return (this.state.form.hospitalList.map((item,index) =>
          <option key={index} value={index}>{item.name}</option>
      ));
    }
  }

  render() {
    const DatepickerInput = ({ ...props }) => (
      <input type="text" {...props} readOnly />
    );
    const isAllowed = date => {
      // const day = getDay(date)
      // let isNotSunday = day !== 0;
      // let isHoliday = this.state.form.holidayList.indexOf(moment(date).format('YYYY-MM-DD')) != -1 ? true : false;
      //console.log(this.state.allowedDateList.includes(moment(date).format('YYYY-MM-DD')))
      return this.state.allowedDateList.includes(moment(date).format('YYYY-MM-DD'));
    };

    return (
      <div className="vaccine">
        {this.getCurrentPage() === 0 && <div className="vaccine-type">
          <div className="topbar">
            <div style={{minWidth: '16px'}}></div>
            <div onClick={() => window.location.href = `${Config.BASE_URL}/vaccine-close`} className="arrow"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
            <div className="text">Screening Vaksin</div>
            <div style={{minWidth: '40px'}}></div>
          </div>
          <div className="container">
            <p>Silahkan memilih untuk tipe vaksinasi, sebelum melakukan pendaftaran.</p>
            <p style={{paddingTop: '8px'}}>Untuk calon penerima vaksin yang sudah berumur 60 tahun atau lebih, silakan mendaftar vaksin melalui jalur Daftar Mandiri (Khusus Lansia)</p>
            <p style={{paddingTop: '8px'}}>Vaksin COVID-19 hanya tersedia untuk Warga Negara Indonesia (WNI).</p>
            {false && <div className="button-list">
              <button onClick={() => this.setState({vaccineType: 'Jalur Undangan'})} type="button" className={`outline-button ${this.state.vaccineType === 'Jalur Undangan' ? 'active' : ''}`}>Jalur Undangan</button>
            </div>}

            <div className="button-list">
              <button onClick={() => { this.setVaccineType('Daftar Mandiri(Pelayanan Publik)'); this.goPage(1);}} type="button" className={`outline-button ${this.state.vaccineType === 'Daftar Mandiri(Pelayanan Publik)' ? 'active' : ''}`}>Daftar Mandiri (Untuk Pelayanan Publik)</button>
            </div>

            <div className="button-list">
              {/* <button onClick={() => this.setState({vaccineType: 'Daftar Mandiri'})} type="button" className={`outline-button ${this.state.vaccineType === 'Daftar Mandiri(Lansia)' ? 'active' : ''}`}>Daftar Mandiri</button> */}
              <button onClick={() => {  this.setVaccineType('Daftar Mandiri(Lansia)'); this.goPage(1); }} type="button" className={`outline-button ${this.state.vaccineType === 'Daftar Mandiri(Lansia)' ? 'active' : ''}`}>Daftar Mandiri (Khusus Lansia)</button>
            </div>

            {false && <div className="button-list">
              <button onClick={() => {  this.setVaccineType('Daftar Mandiri(Altea)'); this.goPage(1); }} type="button" className={`outline-button ${this.state.vaccineType === 'Daftar Mandiri(Altea)' ? 'active' : ''}`}>Daftar Mandiri (Altea)</button>
            </div>}

            <div className="button-list">
              <button onClick={() => {  this.setVaccineType('Daftar Mandiri(Perusahaan)'); this.goPage(1); }} type="button" className={`outline-button ${this.state.vaccineType === 'Daftar Mandiri(Perusahaan)' ? 'active' : ''}`}>Daftar Mandiri (Perusahaan)</button>
            </div>

            <div className="button-list">
              {/* <button onClick={() => this.goPage(1)} type="button" className={`color-button ${!this.allowPage(1) ? 'disabled' : ''}`} disabled={!this.allowPage(1)}>Buat Pendaftaran</button> */}
            </div>
          </div>
          <div className="subheader">Status Pendaftaran</div>
          <div className="registration-list">
            {this.state.vaccine.list.length === 0 && <div className="not-found d-flex flex-column justify-content-center"><div>tidak ada status<br/>pendaftaran saat ini</div></div>}
            {this.state.vaccine.list.map((item, index) => (
              <div key={index} className="item">
                <div className="container">
                  {item.status_register === 'Layak Vaksin' && <div className="status blue">Pendaftaran Vaksinasi Diterima</div>}
                  {item.status_register === 'Ditolak' && <div className="status red">Pendaftaran Vaksinasi Ditolak</div>}
                  {item.status_register === 'Terdaftar' && <div className="status blue">Sedang diproses</div>}
                  <div className="d-flex justify-content-between">
                    <div className="left align-self-center">
                      <div className="registrant">{item.fullname}</div>
                      <div className="identity">{item.card_id_number}</div>
                    </div>
                    {item.status_register === 'Layak Vaksin' && item.timeslot_start_time === null && <div className="right">
                      <button onClick={() => this.setSchedule(item)} type="button">Pilih Jadwal</button>
                    </div>}
                    {item.timeslot_start_time != null && <div className="right">
                      <button onClick={() => { this.state.vaccine.val = item; this.setState({vaccine: this.state.vaccine}); this.goPage(11); }} type="button">Lihat</button>
                    </div>}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>}
        {/* first page */}
        {this.getCurrentPage() === 1 && <div className="first-page">
          <div className="topbar">
            <div style={{minWidth: '16px'}}></div>
            <div onClick={() => window.location.reload()} className="arrow"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
            <div className="text">Screening Vaksin</div>
            <div style={{minWidth: '40px'}}></div>
          </div>
          <div className="container">
            <div style={{minHeight: '16px'}}></div>
            <p>1/3 Lengkapi Data Keluarga</p>

            { this.state.vaccineType === "Daftar Mandiri(Pelayanan Publik)" && <div className="form-group">
              <label>Profesi <span className="text-danger">*</span></label>
              <select name="professionVal" value={this.state.form.professionVal.index} onChange={this.setProfession} className="input-field">
                <option value=''>Pilih Profesi(Pelayanan Publik)</option>
                {this.state.form.professionList.map((item, index) => (
                  <option key={index} value={index}>{item}</option>
                ))}
              </select>
            </div>
            }

            { this.state.vaccineType === "Daftar Mandiri(Altea)" && <div className="form-group">
              <label>Profesi <span className="text-danger">*</span></label>
              <select name="professionVal" value={this.state.form.professionVal.index} onChange={this.setProfession} className="input-field">
                <option value=''>Pilih Profesi(Altea)</option>
                {this.state.form.professionListPerusahaan.map((item, index) => (
                  <option key={index} value={index}>{item}</option>
                ))}
              </select>
            </div>
            }

            { this.state.vaccineType === "Daftar Mandiri(Perusahaan)" && <div className="form-group">
              <label>Perusahaan <span className="text-danger">*</span></label>
              <select name="professionVal" value={this.state.form.professionVal.index} onChange={this.setProfession} className="input-field">
                <option value=''>Pilih Perusahaan</option>
                {this.state.form.professionListPerusahaan.map((item, index) => (
                  <option key={index} value={index}>{item}</option>
                ))}
              </select>
            </div>
            }

            <div className="form-group">
              <label>Rumah Sakit <span className="text-danger">*</span></label>
              <select name="hospitalVal" value={this.state.form.hospitalVal.index} onChange={this.setHospital} className="input-field">
                <option value=''>Pilih Rumah sakit</option>
                {this.renderHospitalListFilter()}
              </select>
            </div>

            { this.state.form.hospitalVal.hospital_vaccine_notes !== "" && <div className="form-group">
                <div dangerouslySetInnerHTML={{ __html: this.state.form.hospitalVal.hospital_vaccine_notes}}/> 
              </div>
            }

            <div className="form-group">
              <label>Kartu Identitas <span className="text-danger">*</span></label>
              <select name="identityVal" value={this.state.form.identityVal} onChange={this.handleFormChange} className="input-field">
                {this.state.form.identityList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Nomor {this.state.form.identityVal} <span className="text-danger">*</span></label>
              <input name="identityNo" value={this.state.form.identityNo} onChange={this.handleFormChange} type="text" placeholder="contoh : 317202023002002" className="input-field" maxLength="16"/>
            </div>
            <div className="form-group">
              <label>Tempat Lahir Sesuai KTP <span className="text-danger">*</span></label>
              <input name="birthPlace" value={this.state.form.birthPlace} onChange={this.handleFormChange} type="text" placeholder="contoh: Jakarta" className="input-field"/>
            </div>
            <div className="form-group">
              <label>Tanggal Lahir Sesuai KTP <span className="text-danger">*</span></label>
              <DatePicker dateFormat="dd-MM-yyyy" placeholderText="Pilih tanggal lahir"
                  disabledKeyboardNavigation
                  withPortal
                  onFocus={(e) => e.target.readOnly = true}
                  selected={this.state.form.birthDate}
                  onChangeRaw={(e) => e.preventDefault()}
                  onChange={date => this.setBirthdate(date)}
                  showYearDropdown={true}
                  dropdownMode={'select'}
                  customInput={<DatepickerInput />}
                  maxDate={moment().toDate()} />
            </div>
            <div className="form-group">
              {this.state.form.identityImg === null && <div className="d-flex justify-content-between">
                <div className="align-self-center"><label>Unggah Foto Kartu Identitas <span className="text-danger">*</span></label></div>
                <div style={{minWidth: '20px'}}></div>
                <div>
                  <input id="identity-file-input" type="file" accept=".jpg,.jpeg,.png" style={{display: 'none'}} onChange={this.handleFileChange}/>
                  <button type="button" onClick={() => document.getElementById('identity-file-input').click()} className="color-button">Unggah</button>
                </div>
              </div>}
              {this.state.form.identityImg != null && <div className="d-flex">
                <div className="filename flex-grow-1 align-self-center">{this.state.form.identityImg.name}</div>
                <div style={{minWidth: '8px'}}></div>
                <div className="filesize align-self-center">{new Intl.NumberFormat('en-IN', { maximumFractionDigits: 0 }).format(this.state.form.identityImg.size / 1000)}KB</div>
                <div style={{minWidth: '12px'}}></div>
                <div className="fileview align-self-center" data-toggle="modal" data-target="#identity-view-modal">Lihat</div>
                <div className="modal fade" id="identity-view-modal">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-body">
                        {this.state.form.identityImgPreview != null && <img id="identity-preview-img" src={this.state.form.identityImgPreview} alt=""/>}
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{minWidth: '15px'}}></div>
                <div onClick={this.clearIdentityImg} className="filedelete align-self-center">Hapus</div>
                <div style={{minWidth: '14px'}}></div>
              </div>}
            </div>
            <div className="form-group">
              {this.state.form.domisiliImg === null && <div className="d-flex justify-content-between">
                <div className="align-self-center"><label>Unggah Surat Domisili Anda (Ukuran file maksimal adalah 1MB)</label></div>
                <div style={{minWidth: '20px'}}></div>
                <div>
                  <input id="identity-file-input" type="file" accept=".jpg,.jpeg,.png" style={{display: 'none'}} onChange={this.handleDomisiliFileChange}/>
                  <button type="button" onClick={() => document.getElementById('identity-file-input').click()} className="color-button">Unggah</button>
                </div>
              </div>}
              {this.state.form.domisiliImg != null && <div className="d-flex">
                <div className="filename flex-grow-1 align-self-center">{this.state.form.domisiliImg.name}</div>
                <div className="filesize align-self-center">{new Intl.NumberFormat('en-IN', { maximumFractionDigits: 0 }).format(this.state.form.domisiliImg.size / 1000)}KB</div>
                <div style={{minWidth: '12px'}}></div>
                <div className="fileview align-self-center" data-toggle="modal" data-target="#domisili-view-modal">Lihat</div>
                <div className="modal fade" id="domisili-view-modal">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-body">
                        {this.state.form.domisiliImgPreview != null && <img id="identity-preview-img" src={this.state.form.domisiliImgPreview} alt=""/>}
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{minWidth: '15px'}}></div>
                <div onClick={this.clearDomisiliImg} className="filedelete align-self-center">Hapus</div>
                <div style={{minWidth: '14px'}}></div>
              </div>}
            </div>
            <div className="form-group">
              <button onClick={() => this.goPage(2)} className={`color-button ${!this.allowPage(2) ? 'disabled' : ''}`} disabled={!this.allowPage(2)} style={{width: '100%'}}>Lanjut, lengkapi Data Diri</button>
            </div>
          </div>
        </div>}
        {/* second page */}
        {this.getCurrentPage() === 2 && <div className="second-page">
          <div className="topbar">
            <div style={{minWidth: '16px'}}></div>
            <div onClick={() => this.setState({page: 1})} className="arrow"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
            <div className="text">Screening Vaksin</div>
            <div style={{minWidth: '40px'}}></div>
          </div>
          <div className="container">
            <div style={{minHeight: '16px'}}></div>
            <p>2/3 Lengkapi Kartu Identitas</p>
            <div className="form-group">
              <label>Nama Lengkap <span className="text-danger">*</span></label>
              <input name="fullName" value={this.state.form.fullName} onChange={this.handleFormChange} type="text" placeholder="Tulis Nama Lengkap" className="input-field"/>
            </div>
            <div className="form-group">
              <label>Jenis Kelamin <span className="text-danger">*</span></label>
              <select name="genderVal" value={this.state.form.genderVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih Jenis Kelamin</option>
                {this.state.form.genderList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Status Pernikahan <span className="text-danger">*</span></label>
              <select name="maritalVal" value={this.state.form.maritalVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih</option>
                {this.state.form.maritalList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>

            { false && <div className="form-group">
              <label>Kebangsaan <span className="text-danger">*</span></label>
              <select name="countryVal" value={this.state.form.countryVal} onChange={this.handleFormChange} className="input-field">
                {this.state.form.countryList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>}
            <div className="form-group">
              <label>Agama <span className="text-danger">*</span></label>
              <select name="religionVal" value={this.state.form.religionVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih</option>
                {this.state.form.religionList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <button onClick={() => this.goPage(3)} className={`color-button ${!this.allowPage(3) ? 'disabled' : ''}`} disabled={!this.allowPage(3)} style={{width: '100%'}}>Lanjut, Lengkapi Data Kontak</button>
            </div>
          </div>
        </div>}
        {/* third page */}
        {this.getCurrentPage() === 3 && <div className="third-page">
          <div className="topbar">
            <div style={{minWidth: '16px'}}></div>
            <div onClick={() => this.setState({page: 2})} className="arrow"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
            <div className="text">Screening Vaksin</div>
            <div style={{minWidth: '40px'}}></div>
          </div>
          <div className="container">
            <div style={{minHeight: '16px'}}></div>
            <p>3/3 Lengkapi Kartu Identitas</p>
            <div className="form-group">
              <label>Nomor Handphone Aktif WhatsApp <span className="text-danger">*</span></label>
              <input name="phoneNo" value={this.state.form.phoneNo} onChange={this.handleFormChange} type="text" placeholder="Tulis Nomor Handphone" className="input-field"/>
            </div>
            <div className="form-group">
              <label>Email <span className="text-danger">*</span></label>
              <input name="email" value={this.state.form.email} onChange={this.handleFormChange} type="email" placeholder="Tulis E-mail" className="input-field"/>
            </div>
            <div className="form-group">
              <label>Nomor Telepon Rumah</label>
              <input name="housePhone" value={this.state.form.housePhone} onChange={this.handleFormChange} type="text" placeholder="Tulis Telepon Rumah" className="input-field"/>
            </div>
            <div className="form-group">
              <label>Nomor Telepon Kantor</label>
              <input name="officePhone" value={this.state.form.officePhone} onChange={this.handleFormChange} type="text" placeholder="Tulis Telepon Kantor" className="input-field"/>
            </div>
            <div className="form-group">
              <label>Alamat Sesuai KTP <span className="text-danger">*</span></label>
              <input name="identityAddress" value={this.state.form.identityAddress} onChange={this.handleFormChange} type="text" placeholder="Co: Jl. Kenari Blok A9 No 2" className="input-field"/>
            </div>
            <div className="form-group">
              <label>Provinsi <span className="text-danger">*</span></label>
              <select name="provinceVal" value={this.state.form.provinceIndex} onChange={this.setProvince} className="input-field">
                <option value=''>Pilih provinsi</option>
                {this.state.form.provinceList.map((item, index) => (
                  <option key={index} value={index}>{item.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Kota <span className="text-danger">*</span></label>
              <select name="cityVal" value={this.state.form.cityIndex} onChange={this.setCity} className="input-field">
                <option value=''>Pilih kota</option>
                {this.state.form.cityList.map((item, index) => (
                  <option key={index} value={index}>{item.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Kecamatan <span className="text-danger">*</span></label>
              <select name="kecamatanVal" value={this.state.form.kecamatanIndex} onChange={this.setKecamatan} className="input-field">
                <option value=''>Pilih kecamatan</option>
                {this.state.form.kecamatanList.map((item, index) => (
                  <option key={index} value={index}>{item.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Kelurahan <span className="text-danger">*</span></label>
              <select name="kelurahanVal" value={this.state.form.kelurahanIndex} onChange={this.setKelurahan} className="input-field">
                <option value=''>Pilih kelurahan</option>
                {this.state.form.kelurahanList.map((item, index) => (
                  <option key={index} value={index}>{item.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Kode Pos <span className="text-danger">*</span></label>
              <input name="postalCode" value={this.state.form.postalCode} onChange={this.handleFormChange} type="text" placeholder="Tulis Kode Pos" className="input-field"/>
            </div>
            <div className="form-group">
              <label>Alamat Domisili (Bila Berbeda Dengan KTP)</label>
              <textarea name="address" value={this.state.form.address} onChange={this.handleFormChange} type="text" placeholder="Co: Jl. Kenari Blok A9 No 2" className="input-field" rows="5" cols="8"/>
            </div>
            <div className="form-group">
              <button onClick={() => this.goPage(6)} className={`color-button ${!this.allowPage(4) ? 'disabled' : ''}`} disabled={!this.allowPage(4)} style={{width: '100%'}}>Lanjut, Isi Data Keluarga</button>
            </div>
          </div>
        </div>}
        {/* fourth page */}
        {this.getCurrentPage() === 4 && <div className="fourth-page">
          <div className="topbar">
            <div style={{minWidth: '16px'}}></div>
            <div onClick={() => this.setState({page: 3})} className="arrow"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
            <div className="text">Screening Vaksin</div>
            <div style={{minWidth: '40px'}}></div>
          </div>
          <div className="container">
            <div style={{minHeight: '16px'}}></div>
            <p>4/5 Lengkapi Kartu Identitas</p>
            <div className="form-group">
              <label>Nama Ibu</label>
              <input name="motherName" value={this.state.form.motherName} onChange={this.handleFormChange} type="text" placeholder="Tuliskan Nama Ibu" className="input-field"/>
            </div>
            <div className="form-group">
              <label>Nama Ayah</label>
              <input name="fatherName" value={this.state.form.fatherName} onChange={this.handleFormChange} type="text" placeholder="Tuliskan Nama Ayah" className="input-field"/>
            </div>
            <div className="form-group">
              <label>Nama Suami/Istri</label>
              <input name="spouseName" value={this.state.form.spouseName} onChange={this.handleFormChange} type="text" placeholder="Tuliskan Nama Suami/Istri" className="input-field"/>
            </div>
            <div className="form-group">
              <button onClick={() => this.setState({page: 5})} className={`color-button ${!this.allowPage(5) ? 'disabled' : ''}`} disabled={!this.allowPage(5)} style={{width: '100%'}}>Lanjut, Isi Data Kontak Darurat</button>
            </div>
          </div>
        </div>}
        {/* fifth page */}
        {this.getCurrentPage() === 5 && <div className="fifth-page">
          <div className="topbar">
            <div style={{minWidth: '16px'}}></div>
            <div onClick={() => this.setState({page: 4})} className="arrow"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
            <div className="text">Screening Vaksin</div>
            <div style={{minWidth: '40px'}}></div>
          </div>
          <div className="container">
            <div style={{minHeight: '16px'}}></div>
            <p>5/5 Lengkapi Data Kontak Darurat</p>
            <div className="form-group">
              <label>Nama Kontak Darurat <span className="text-danger">*</span></label>
              <input name="emergencyFamilyName" value={this.state.form.emergencyFamilyName} onChange={this.handleFormChange} type="text" placeholder="Tulis nama kontak darurat" className="input-field"/>
            </div>
            <div className="form-group">
              <label>Hubungan dengan Kontak Darurat <span className="text-danger">*</span></label>
              <select name="emergencyFamilyRelationVal" value={this.state.form.emergencyFamilyRelationVal} onChange={this.handleFormChange} className="input-field">
                <option value=''>Pilih</option>
                {this.state.form.emergencyFamilyRelationList.map((item, index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Nomor Kontak Darurat <span className="text-danger">*</span></label>
              <input name="emergencyPhone" value={this.state.form.emergencyPhone} onChange={this.handleFormChange} type="text" placeholder="Tulis nomor kontak" className="input-field"/>
            </div>
            <div className="form-group">
              <label>Alamat Kontak Darurat</label>
              <textarea name="emergencyAddress" value={this.state.form.emergencyAddress} onChange={this.handleFormChange} type="text" placeholder="Tulis alamat" className="input-field" rows="5" cols="8"/>
            </div>
            <div className="form-group">
              <button onClick={() => this.setState({page: 6})} className={`color-button ${!this.allowPage(6) ? 'disabled' : ''}`} disabled={!this.allowPage(6)} style={{width: '100%'}}>Lanjut, Isi Form Kesehatan</button>
            </div>
          </div>
        </div>}
        {/* six page */}
        {this.getCurrentPage() === 6 && <div className="six-page">
          <div className="topbar">
            <div style={{minWidth: '16px'}}></div>
            <div onClick={() => this.setState({page: 3})} className="arrow"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
            <div className="text">Screening Vaksin</div>
            <div style={{minWidth: '40px'}}></div>
          </div>
          <div className="container">
            <div style={{minHeight: '16px'}}></div>
            <div className="form-group">
              <label>Ini adalah Vaksinasi COVID-19 saya yang ? <span className="text-danger">*</span></label>
              {this.state.form.vaccineNoList.map((item, index) => (
                <div key={index} onClick={() => { this.state.form.vaccineNoVal = item; this.setState({ form: this.state.form }); }}><RadioButton text={item} active={this.state.form.vaccineNoVal === item}></RadioButton></div>
              ))}
            </div>
            <div className="form-group">
              <button onClick={() => this.setState({page: 7})} className={`color-button ${!this.allowPage(7) ? 'disabled' : ''}`} disabled={!this.allowPage(7)} style={{width: '100%'}}>Lanjutkan</button>
            </div>
          </div>
        </div>}
        {/* seventh page */}
        {this.getCurrentPage() === 7 && this.renderQuestion()}
        {/* eight page */}
        {this.getCurrentPage() === 8 && <div className="eight-page">
          <div className="topbar">
            <div style={{minWidth: '16px'}}></div>
            <div onClick={() => this.setState({page: 7})} className="arrow"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
            <div className="text">Screening Vaksin</div>
            <div style={{minWidth: '40px'}}></div>
          </div>
          <div className="container flex-grow-1">
            <div className="d-flex flex-column h-100">
              <div style={{minHeight: '16px'}}></div>
              <h3>Syarat dan Ketentuan</h3>
              <p className="term flex-grow-1">
                Saya yang mengirim pernyataan ini memahami dan menyetujui bahwa:
                <br/><br/>
                 <p className="term flex-grow-1"> 1.Bersedia untuk menerima vaksin COVID-19 sebanyak dua dosis:</p>
                 <p className="term flex-grow-1"> a. Vaksin kedua akan diberikan 28 hari kalender setelah pemberian vaksin pertama.</p>
                 <p className="term flex-grow-1">  b. Lokasi pemberian vaksin kedua diberikan di lokasi fasilitas Kesehatan yang sama dengan lokasi pemberian vaksin pertama.</p>
                <br/><br/>
                2. Resiko yang dapat terjadi setelah penerimaan vaksin COVID-19 berupa nyeri, kemerahan di area suntikan, badan lemas, kepala dan/otot nyeri, demam ringan, reaksi alergi, hingga resiko lainnya yang dapat muncul tergantung dari kondisi penerima vaksin
                <br/><br/>
                3. Setelah pemberian vaksin, penerima vaksin perlu menunggu selama 30 menit untuk observasi kondisi penerima vaksin
                <br/><br/>
                4. Penerima vaksin COVID-10 tetap dapat terinfeksi dan menularkan kepada orang lain. Pemberian vaksin COVID-19 meningkatkan imunitas terhadap infeksi COVID-19 tetapi tidak menjamin kekebalan dari infeksi COVID-19.
              </p>
              <div onClick={() => { this.state.form.termAgree = !this.state.form.termAgree; this.setState({form: this.state.form}); } } className="form-group">
                <div className="d-flex">
                  <div className={`checkbox mt-1 ${this.state.form.termAgree && 'active'}`}><div className="icon">✓</div></div>
                  <div style={{minWidth: '6px'}}></div>
                  <div className="agreement">Saya mematuhi Syarat dan Ketentuan dan telah mengisi segala informasi dengan jujur. Saya setuju bahwa Altea Loyalty dapat mengumpulkan, menggunakan, mengungkapkan informasi yang saya berikan.</div>
                </div>
              </div>
              <div className="form-group">
                <button onClick={this.submit} className={`color-button ${!this.allowPage(9) ? 'disabled' : ''}`} disabled={!this.allowPage(9)} style={{width: '100%'}}>Setuju dan Kirim</button>
              </div>
            </div>
          </div>
        </div>}
        {/* ninth page */}
        {this.getCurrentPage() === 9 && <div className="ninth-page">
          <div className="container flex-grow-1">
            <div className="d-flex flex-column h-100">
              <div className="flex-grow-1">
                <div className="d-flex flex-column h-100 justify-content-center">
                  <div className="img"><img src={`${Config.BASE_URL}/img/vaccine-icon.png`} alt=""/></div>
                  <h5>Pendaftaran Terkirim</h5>
                  <p>Pendaftaran kamu sedang di konfirmasi oleh Tim rumah sakit, proses konfirmasi membutuhkan beberapa jam, mohon menunggu.</p>
                  <p>Selanjutnya jika pendaftaran kamu diterima silahkan memilih tanggal dan jam untuk jadwal vaksinasi.</p>
                </div>
              </div>
              <div className="form-group">
                <button onClick={this.resetState} className={`color-button`} style={{width: '100%'}}>Ok, cek status pendaftaran</button>
              </div>
            </div>
          </div>
        </div>}
        {/* tenth page */}
        {this.getCurrentPage() === 10 &&
        <div className="tenth-page">

         <div className="topbar">
            <div style={{minWidth: '16px'}}></div>
            <div onClick={() => this.setState({vaccineType: null, page: 0})} className="arrow"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
            <div className="text">Pilih Waktu</div>
            <div style={{minWidth: '40px'}}></div>
          </div>

          <div className="container flex-grow-1">
            <div className="d-flex flex-column h-100">

              <div className="flex-grow-1">
                <div className="note">Silahkan memilih jadwal untuk vaksin di rumah sakit <span className="rs">{this.state.vaccine.val.hospital_name}</span></div>
                <div className="form-group">
                  <label>Tanggal <span className="text-danger">*</span></label>
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    disabledKeyboardNavigation
                    withPortal
                    onFocus={(e) => e.target.readOnly = true}
                    placeholderText="Pilih tanggal vaksin"
                    selected={this.state.form.vaccineDate}
                    onChangeRaw={(e) => e.preventDefault()}
                    onChange={date => this.setVaccineDate(date)}
                    customInput={<DatepickerInput />}
                    filterDate={isAllowed}
                  />
                </div>
                <div className="form-group">
                  <label>Pilih jam <span className="text-danger">*</span></label>
                  <div className="hour-list d-flex flex-wrap">
                    {this.state.form.hourList.map((item, index) => (
                      <div key={index} onClick={() => {this.state.form.hourVal = item; this.setState({form: this.state.form})}} className={this.state.form.hourVal === item ? 'item active' : 'item'}>{item.timeslot_start_time}-{item.timeslot_end_time}</div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button onClick={this.submitSchedule} className={`color-button ${!this.allowPage(11) ? 'disabled' : ''}`} disabled={!this.allowPage(11)} style={{width: '100%'}}>Lanjutkan</button>
              </div>
            </div>
          </div>
        </div>}
        {/* eleventh page */}
        {this.getCurrentPage() === 11 &&
         <div className="eleventh-page">
         <div className="topbar">
            <div style={{minWidth: '16px'}}></div>
            <div onClick={() => this.setState({vaccineType: null, page: 0})} className="arrow"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
            <div className="text">Jadwal Vaksinasi Covid-19 telah Terdaftar.</div>

            <div style={{minWidth: '40px'}}></div>
          </div>

          <div className="container flex-grow-1">
            <div className="d-flex flex-column h-100">
              <div className="flex-grow-1">
                <h5>Detail Pendaftaran</h5>
                <label>No.Pendaftaran : {this.state.vaccine.val.register_vaccine_id}</label>

                <label>Nama</label>
                <div className="value">{this.state.vaccine.val.fullname}</div>

                <label>Tanggal</label>
                <div className="value">{moment(this.state.vaccine.val.date ?? this.state.form.vaccineDate, true).format('dddd, DD MMMM YYYY')}, {this.state.vaccine.val.timeslot_start_time ?? this.state.form.hourVal.timeslot_start_time}-{this.state.vaccine.val.timeslot_end_time ?? this.state.form.hourVal.timeslot_end_time}</div>

                <label>Rumah sakit</label>
                <div className="value">{this.state.vaccine.val.hospital_name}</div>

                <div dangerouslySetInnerHTML={{ __html: this.state.vaccine.val.hospital_vaccine_notes}}/> 
                <br></br>
                <label>Tipe Vaksin</label>
                <div className='value'>{this.state.vaccine.val.type_vaccine}</div>

                <label>Profesi</label>
                <div className='value'>{this.state.vaccine.val.profession !== "" ? this.state.vaccine.val.profession : "-" }</div>
                <div className="note3">Screenshoot halaman ini sebagai bukti konfirmasi.</div>
                {this.state.vaccine.val.type_vaccine === 'Daftar Mandiri(Lansia)' && <div className="note">
                  <div>Penting untuk diperhatikan sebelum datang ke lokasi Vaksin:</div>
                  <ul>
                    <li>Membawa Kartu Tanda Penduduk (Asli)</li>
                    <li>Bagi pasien lansia yang berkebutuhan khusus dapat mengikutsertakan pendamping pada saat proses vaksinasi.</li>
                    <li>Menggunakan pakaian lengan pendek atau yang mudah dilipat pada bagian lengan atau lengan panjang yang mudah di lipat.</li>
                  </ul>
                </div>
                }

                {this.state.vaccine.val.type_vaccine === 'Daftar Mandiri(Altea)' && <div className="note">
                  <div>Penting untuk diperhatikan sebelum datang ke lokasi Vaksin:</div>
                  <ul>
                    <li>Membawa Kartu Tanda Penduduk (Asli)</li>
                    <li>Menggunakan pakaian lengan pendek atau yang mudah dilipat pada bagian lengan atau lengan panjang yang mudah di lipat.</li>
                    <li>Khusus karyawan internal Altea</li>
                  </ul>
                </div>
                }


                {this.state.vaccine.val.type_vaccine === 'Daftar Mandiri(Perusahaan)' && <div className="note">
                  <div>Penting untuk diperhatikan sebelum datang ke lokasi Vaksin:</div>
                  <ul>
                    <li>Membawa Kartu Tanda Penduduk (Asli)</li>
                    <li>Menggunakan pakaian lengan pendek atau yang mudah dilipat pada bagian lengan atau lengan panjang yang mudah di lipat.</li>
                    <li>Khusus karyawan perusahaan</li>
                  </ul>
                </div>
                }

                  {this.state.vaccine.val.type_vaccine === 'Daftar Mandiri' && <div className="note">
                  <div>Penting untuk diperhatikan sebelum datang ke lokasi Vaksin:</div>
                  <ul>
                    <li>Membawa Kartu Tanda Penduduk (Asli)</li>
                    <li>Bagi pasien lansia yang berkebutuhan khusus dapat mengikutsertakan pendamping pada saat proses vaksinasi.</li>
                    <li>Menggunakan pakaian lengan pendek atau yang mudah dilipat pada bagian lengan atau lengan panjang yang mudah di lipat.</li>
                  </ul>
                </div>
                }

                 {this.state.vaccine.val.type_vaccine === 'Daftar Mandiri(Pelayanan Publik)' && <div className="note">
                  <div>Penting untuk diperhatikan sebelum datang ke lokasi Vaksin:</div>
                  <ul>
                    <li>Membawa Kartu Tanda Penduduk (Asli)</li>
                    <li>Mohon peserta vaksinasi membawa surat keterangan dengan kop resmi dan berstempel basah dari organisasi yang menyebutkan jabatan yang bersangkutan sesuai kriteria sasaran pelayan publik penerima vaksin COVID-19</li>
                    <li>Menggunakan pakaian lengan pendek atau yang mudah dilipat pada bagian lengan atau lengan panjang yang mudah di lipat.</li>
                    <li>Mohon pastikan nama Anda telah terdaftar sebagai calon penerima vaksin COVID-19 di sistem pemerintah sesuai dengan profesi masing-masing.</li>
                  </ul>
                </div>
                }
                <div className="note2">Silahkan datang ke rumah sakit sesuai jadwal dan datang 15 menit lebih awal untuk konfirmasi</div>

              </div>
              <div className="form-group">
                <button onClick={this.resetState} className='color-button' style={{width: '100%'}}>Selesai</button>
              </div>
            </div>
          </div>
        </div>}
        {this.state.isLoading && <div className="loading"><div className="spinner-border text-primary"></div></div>}
      </div>
    );
  }
}

export default withRouter(Vaccine);
