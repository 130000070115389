import React from 'react';
import TopBar from './../component/topbar';
import ArticleRecommendedPage from './../component/article/article-recommended';
import Footer from './../component/footer';

class ArticleRecommended extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      article: []
    }
  }

  render() {
    return (
      <div className="main">
        <TopBar login={this.props.login}></TopBar>
        <ArticleRecommendedPage></ArticleRecommendedPage>
      </div>
    );
  }
}

export default ArticleRecommended;
