import React from 'react';

class RadioButton extends React.Component {
  render() {
    return (
      <div className="radio">
        <div className="circle"><div className="icon">{this.props.active && <div className="active"></div>}</div></div>
        <div className="text">{this.props.text}</div>
      </div>
    );
  }
}

export default RadioButton;
