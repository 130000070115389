import React from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/id';
import * as Config from './../../config';

class Article extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      article: []
    }
  }

  async componentDidMount() {
    axios.get(`${Config.API_PUBLIC_URL}/article?is_popular=false`).then((response) => {
      try {
        this.setState({ article: response.data.data.article });
        console.log(this.state.article);
      } catch(error) {
        Swal.fire(error.message);
      }
    });
  }

  render() {
    return (
      <div id="article-section" className="article">
        <div className="container">
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="title">Artikel Kesehatan Terbaru</div>
            <div className="align-self-end view-all"><a href={`${Config.BASE_URL}/article-list`}>Lihat Semua</a></div>
          </div>
          <div style={{minWidth: '30px', minHeight: '30px'}}></div>
          <div className="d-flex flex-column flex-md-row">
            {this.state.article.map((item, index) => index < 3 && <React.Fragment key={index}>
              <a href={`${Config.BASE_URL}/article-detail/${item.slug}`} style={{maxWidth:'360px',flexBasis:'33%'}} className="article-card">
                <div className="d-flex flex-column h-100">
                  <div className="top" style={{backgroundImage: `url('${item.image}')`}}></div>
                  <div className="bottom flex-grow-1 d-flex flex-column">
                    {/* <div className="article-tags">
                      {item.tags.map((item, subindex) => <div key={subindex} className="tag">{item}</div>)}
                    </div>
                    {item.tags.length > 0 && <div style={{minHeight:'12px'}}></div>} */}
                    <div className="article-title">{item.title}</div>
                    <div style={{minHeight:'12px'}}></div>
                    <div className="article-desc flex-grow-1">{item.metadata}</div>
                    <div style={{minHeight:'16px'}}></div>
                    <div className="article-date">{moment(item.created_at).format("dddd, DD MMMM YYYY")}</div>
                  </div>
                </div>
              </a>
              {index < 2 && <div style={{minWidth: '45px', minHeight: '45px'}}></div>}
            </React.Fragment>)}
          </div>
        </div>
      </div>
    );
  }
}

export default Article;
