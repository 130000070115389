import React from 'react';
import Swal from 'sweetalert2';
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/id';
import * as Config from './../../config';

class TopicArticleListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      article: [],
      articleRecommended: [],
      maxNewestArticle: 8,
      form: {
        search: '',
      },
      topic: '',
    }
    this.viewNewestArticle = this.viewNewestArticle.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.fetchArticleList = this.fetchArticleList.bind(this);
    this.addMoreNewest = this.addMoreNewest.bind(this);
  }

  async componentDidMount() {
    let topic = decodeURIComponent(window.location.pathname.replace("/topic/", ""));
    this.setState({topic: topic}, this.fetchArticleList);
  }

  handleFormChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    this.state.form[name] = value;
    this.setState({ form: this.state.form });
  }

  async fetchArticleList() {
    this.setState(prevState => ({
      isLoading: true,
      form: {
        ...prevState.form,
        article: [],
      }
    }));
    let response = await axios.get(`${Config.API_PUBLIC_URL}/article/category/${this.state.topic}`);
    try {
      let data = response.data;
      if(data.statusCode === 200) {
        this.setState({article: data.data.articles});
        console.log(this.state.article);
      } else {
        Swal.fire('Failed', data.message, 'error');
      }
    } catch(error) {
      Swal.fire('Error', 'Unable to connect to server', 'error');
    }
    this.setState({ isLoading: false });
  }

  viewNewestArticle() {
    let result = [];
    let haveMore = false;
    for(let i=0;i<this.state.article.length;i++) {
      if(result.length >= this.state.maxNewestArticle) {
        haveMore = true; break;
      }
      let item = this.state.article[i];
      let tagExist = false;
      for(let s=0;s<item.tags.length;s++) {
        let tag = item.tags[s];
        if(tag.toLowerCase().includes(this.state.form.search.toLowerCase())) {
          tagExist = true; break;
        }
      }
      if(
        item.title.toLowerCase().includes(this.state.form.search.toLowerCase())
        ||
        item.category.toLowerCase().includes(this.state.form.search.toLowerCase())
        ||
        tagExist
      ) {
        result.push(this.state.article[i]);
      }
    }
    return { list: result, haveMore: haveMore };
  }

  addMoreNewest() {
    this.setState({maxNewestArticle: this.state.maxNewestArticle + 8});
  }

  render() {
    return (
      <div id="article-detail" className="article-detail">
        <div className="tab-panel">
          <div className="container d-flex">
            <div><a href={Config.BASE_URL}>Beranda</a></div>
            <div style={{minWidth: '4px'}}></div>
            <div>/</div>
            <div style={{minWidth: '4px'}}></div>
            <div><a href={Config.BASE_URL + '/topic-list'}></a>Topik</div>
            <div style={{minWidth: '4px'}}></div>
            <div>/</div>
            <div style={{minWidth: '4px'}}></div>
            <div>{this.state.topic}</div>
          </div>
        </div>
        <div className="container">
          <div style={{minWidth:'40px',minHeight:'40px'}}></div>
          {this.state.article.length > 0 && <div className="article-filter">
            <input name="search" type="text" onChange={this.handleFormChange} placeholder="Cari artikel berdasarkan judul, kategori, topik"/>
            <div className="search-icon"><img src={`${Config.BASE_URL}/img/search-icon.png`} alt=""/></div>
          </div>}
          <div style={{minWidth:'24px',minHeight:'24px'}}></div>
          <div className="article-label d-flex">
            <div className="align-self-center">Topik : </div>
            <div style={{minWidth:'4px',minHeight:'4px'}}></div>
            <div className="tag-circle">{this.state.topic}</div>
          </div>
          {this.state.article.length === 0 && <div className="text-center text-muted my-3"><small><em>Tidak ada data ditemukan</em></small></div>}
          {this.state.article.length > 0 && <div className="newest-article">
            {this.state.form.search !== '' && <>
            <div style={{minWidth:'24px',minHeight:'24px'}}></div>
            <div className="title">Hasil Pencarian untuk "{this.state.form.search}"</div>
            </>}
            <div style={{minWidth:'24px',minHeight:'24px'}}></div>
            <div className="d-flex flex-column flex-md-row flex-wrap" style={{marginLeft:'-16px',marginRight:'-16px'}}>
              {this.viewNewestArticle().list.map((item, index) => <React.Fragment key={index}>
                <a href={`${Config.BASE_URL}/article-detail/${item.slug}`} style={{maxWidth:'360px',flexBasis:'22%',margin:'16px'}} className="article-card">
                  <div className="d-flex flex-column h-100">
                    <div className="top" style={{backgroundImage: `url('${item.image}')`}}></div>
                    <div className="bottom flex-grow-1 d-flex flex-column">
                      {/* <div className="article-tags">
                      {item.tags.map((item, subindex) => <div key={subindex} className="tag">{item}</div>)}
                      </div>
                      {item.tags.length > 0 && <div style={{minHeight:'12px'}}></div>} */}
                      <div className="article-title flex-grow-1">{item.title}</div>
                      <div style={{minHeight:'12px'}}></div>
                      <div className="article-desc flex-grow-1">{item.metadata}</div>
                      <div style={{minHeight:'16px'}}></div>
                      <div className="article-date">{moment(item.created_at).format("dddd, DD MMMM YYYY")}</div>
                    </div>
                  </div>
                </a>
              </React.Fragment>)}
            </div>
            <div style={{minWidth:'20px',minHeight:'20px'}}></div>
            {this.viewNewestArticle().haveMore && <div className="text-center">
              <button className="add-more" onClick={this.addMoreNewest}>Selanjutnya</button>
            </div>}
          </div>}
          <div style={{minWidth:'40px',minHeight:'40px'}}></div>
        </div>
      </div>
    );
  }
}

export default TopicArticleListPage;
