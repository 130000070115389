import React from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/id';
import * as Config from './../../config';

class ArticleListTagPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: '',
      article: [],
      maxNewestArticle: 8,
      form: {
        search: '',
      }
    }

    this.viewarticle = this.viewarticle.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.addMoreNewest = this.addMoreNewest.bind(this);
  }

  async componentDidMount() {
    let tag = decodeURI(window.location.pathname.replace("/article-tag/", ""));
    this.setState({tag: tag});
    axios.get(`${Config.API_PUBLIC_URL}/article/tag/${tag}`).then((response) => {
      try {
        this.setState({ article: response.data.data.articles });
        console.log(this.state.article);
      } catch(error) {
        Swal.fire(error.message);
      }
    });
  }

  handleFormChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    this.state.form[name] = value;
    this.setState({ form: this.state.form });
  }

  viewarticle() {
    let result = [];
    let haveMore = false;
    for(let i=0;i<this.state.article.length;i++) {
      if(result.length >= this.state.maxNewestArticle) {
        haveMore = true; break;
      }
      let item = this.state.article[i];
      let tagExist = false;
      for(let s=0;s<item.tags.length;s++) {
        let tag = item.tags[s];
        if(tag.toLowerCase().includes(this.state.form.search.toLowerCase())) {
          tagExist = true; break;
        }
      }
      if(
        item.title.toLowerCase().includes(this.state.form.search.toLowerCase())
        ||
        item.category.toLowerCase().includes(this.state.form.search.toLowerCase())
        ||
        tagExist
      ) {
        result.push(this.state.article[i]);
      }
    }
    console.log(haveMore);
    return { list: result, haveMore: haveMore };
  }

  addMoreNewest() {
    this.setState({maxNewestArticle: this.state.maxNewestArticle + 6});
  }

  render() {
    return (
      <div id="article-detail" className="article-detail">
        <div className="tab-panel">
          <div className="container d-flex">
            <div><a href={Config.BASE_URL}>Beranda</a></div>
            <div style={{minWidth: '4px'}}></div>
            <div>/</div>
            <div style={{minWidth: '4px'}}></div>
            <div>Tags</div>
            <div style={{minWidth: '4px'}}></div>
            <div>/</div>
            <div style={{minWidth: '4px'}}></div>
            <div>{this.state.tag}</div>
          </div>
        </div>
        <div className="container">
          <div style={{minWidth:'40px',minHeight:'40px'}}></div>
          {this.state.article.length > 0 && <div className="article-filter">
            <input name="search" type="text" onChange={this.handleFormChange} placeholder="Cari artikel berdasarkan judul, kategori, topik"/>
            <div className="search-icon"><img src={`${Config.BASE_URL}/img/search-icon.png`} alt=""/></div>
          </div>}
          {this.state.form.search !== '' && <div style={{minWidth:'32px',minHeight:'32px'}}></div>}
          {this.state.article.length > 0 && <div className="newest-article">
            {this.state.form.search !== '' && <div className="title">Hasil Pencarian untuk "{this.state.form.search}"</div>}
            <div style={{minWidth:'32px',minHeight:'32px'}}></div>
            <div className="tags-title">Tags : <span>{this.state.tag}</span></div>
            <div style={{minWidth:'32px',minHeight:'32px'}}></div>
            <div className="d-flex flex-column flex-md-row flex-wrap" style={{marginLeft:'-16px',marginRight:'-16px'}}>
              {this.viewarticle().list.map((item, index) => <React.Fragment key={index}>
                <a href={`${Config.BASE_URL}/article-detail/${item.slug}`} style={{maxWidth:'360px',flexBasis:'22%',margin:'16px'}} className="article-card">
                  <div className="d-flex flex-column h-100">
                    <div className="top" style={{backgroundImage: `url('${item.image}')`}}></div>
                    <div className="bottom flex-grow-1 d-flex flex-column">
                      {/* <div className="article-tags">
                      {item.tags.map((item, subindex) => <div key={subindex} className="tag">{item}</div>)}
                      </div>
                      {item.tags.length > 0 && <div style={{minHeight:'12px'}}></div>} */}
                      <div className="article-title flex-grow-1">{item.title}</div>
                      <div style={{minHeight:'12px'}}></div>
                      <div className="article-desc flex-grow-1">{item.metadata}</div>
                      <div style={{minHeight:'16px'}}></div>
                      <div className="article-date">{moment(item.created_at).format("dddd, DD MMMM YYYY")}</div>
                    </div>
                  </div>
                </a>
              </React.Fragment>)}
            </div>
            <div style={{minWidth:'20px',minHeight:'20px'}}></div>
            {this.viewarticle().haveMore && <div className="text-center">
              <button className="add-more" onClick={this.addMoreNewest}>Selanjutnya</button>
            </div>}
          </div>}
          <div style={{minWidth:'40px',minHeight:'40px'}}></div>
        </div>
      </div>
    );
  }
}

export default ArticleListTagPage;
