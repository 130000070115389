import React from 'react';
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import moment from 'moment';
import validator from 'validator';
import Swal from 'sweetalert2';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import * as Config from './../config';

class Certificate extends React.Component {
  constructor(props) {
    super(props);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.openCertUpload = this.openCertUpload.bind(this);
    this.fetchCertificate = this.fetchCertificate.bind(this);
    this.getSelectedCert = this.getSelectedCert.bind(this);

    this.state = {
      isLoading: false,
      vaccine1: null,
      vaccine2: null,
      vaccineSelected: null,
    };
  }
  async fetchCertificate() {
    try {
      let response = await axios.get(`${Config.API_URL}/get_certificate_vaccine_user/${this.props.match.params.userID}`);
      let data = response.data;
      if(data.statusCode === 200) {
        let certificate = data.data;
        for(let i=0;i<certificate.length;i++) {
          if(certificate[i].type_vaccine == 'Vaksin Pertama') this.setState({vaccine1: certificate[i]});
          else if(certificate[i].type_vaccine == 'Vaksin Kedua') this.setState({vaccine2: certificate[i]});
        }
        console.log(this.state);
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Unable to connect to server', 'error');
    }
  }

  async componentDidMount() {
    this.fetchCertificate();
  }

  getSelectedCert() {
    if(this.state.vaccineSelected === 'Pertama') return this.state.vaccine1;
    if(this.state.vaccineSelected === 'Kedua') return this.state.vaccine2;
    return null;
  }

  handleFormChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    this.state.form[name] = value;
    this.setState({ form: this.state.form });
  }

  openCertUpload() {
    if(this.state.isLoading) return;
    document.getElementById('cert-upload').click();
  }

  async handleFileChange(event) {
    let context = this;
    let file = event.target.files[0];
    if(file != null) {
      this.setState({isLoading: true});
      let formData = new FormData();
      formData.append('userId', this.props.match.params.userID);
      formData.append('type_vaccine', `Vaksin ${this.state.vaccineSelected}`);
      formData.append('photo_certificate', file);
      formData.append('size', file.size);
      try {
        let response = await axios.post(`${Config.API_URL}/create_certificate_vaccine_user`, formData);
        let data = response.data;
        console.log(data);
        if(data.statusCode === 200) {
          this.setState({isLoading: false});
          if(this.state.vaccineSelected == 'Pertama') this.setState({vaccine1: data.data});
          if(this.state.vaccineSelected == 'Kedua') this.setState({vaccine2: data.data});
        } else {
          await Swal.fire('Gagal', data.statusMessage, 'warning');
        }
      } catch(error) {
        await Swal.fire('Gagal', 'Unable to connect to server', 'error');
      }
      this.setState({isLoading: false});
    }
  }

  async deleteCert() {
    if(this.state.isLoading) return;
    let confirm = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });
    if(!confirm.value) return;
    this.setState({isLoading: true});
    console.log(this.state);
    let response = await axios.post(`${Config.API_URL}/delete_certificate_vaccine_user/${this.getSelectedCert().certificate_vaccine_id ?? this.getSelectedCert().id}`);
    try {
      let data = response.data;
      console.log(data);
      if(data.statusCode === 200) {
        this.setState({isLoading: false});
        if(this.state.vaccineSelected == 'Pertama') this.setState({vaccine1: null});
        if(this.state.vaccineSelected == 'Kedua') this.setState({vaccine2: null});
      } else {
        await Swal.fire('Gagal', data.statusMessage, 'warning');
      }
    } catch(error) {
      await Swal.fire('Gagal', 'Unable to connect to server', 'error');
    }
    this.setState({isLoading: false});
  }

  render() {
    return (
      <div className="vaccine-certificate">
        {this.state.vaccineSelected === null && <>
        <div className="topbar">
          <div style={{minWidth: '16px'}}></div>
          <div onClick={() => window.location.href = `${Config.BASE_URL}/vaccine-close`} className="arrow"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
          <div className="text">Sertifikat Vaksin COVID-19​</div>
          <div style={{minWidth: '40px'}}></div>
        </div>
        <div className="content">
          <div className="container">
            <div className="img-logo"><img src={`${Config.BASE_URL}/img/vaccine-certificate.png`} alt=""/></div>
            <div className="img-logo-text">Saya Sudah Mendapat Vaksin COVID-19</div>
            <div style={{minHeight: '40px'}}></div>
            <div className="form-group">
              <label>Vaksin Pertama</label>
              <div onClick={() => this.setState({vaccineSelected: 'Pertama'})} className="upload-certificate-button clickable">
                {this.state.vaccine1 === null && <div className="left">Unggah Sertifikat</div>}
                {this.state.vaccine1 !== null && <div className="left-active">Lihat</div>}
                <div className="arrow"><img src={`${Config.BASE_URL}/img/arrow-right.png`} alt=""/></div>
              </div>
            </div>
            <div className="form-group">
              <label>Vaksin Kedua</label>
              <div onClick={() => this.setState({vaccineSelected: 'Kedua'})} className="upload-certificate-button clickable">
                {this.state.vaccine2 === null && <div className="left">Unggah Sertifikat</div>}
                {this.state.vaccine2 !== null && <div className="left-active">Lihat</div>}
                <div className="arrow"><img src={`${Config.BASE_URL}/img/arrow-right.png`} alt=""/></div>
              </div>
            </div>
          </div>
        </div>
        </>}
        {this.state.vaccineSelected !== null && <>
        <div className="topbar">
          <div style={{minWidth: '16px'}}></div>
          <div onClick={() => this.setState({vaccineSelected: null})} className="arrow"><img src={`${Config.BASE_URL}/img/back-arrow-icon.png`} /></div>
          <div className="text">Upload Sertifikat</div>
          <div style={{minWidth: '40px'}}></div>
        </div>
        <div className="content">
          <div className="container">
            <h5>Sertifikat Vaksin {this.state.vaccineSelected}</h5>
            {this.getSelectedCert() === null && <>
            <div onClick={this.openCertUpload} className="upload-form clickable">
              <div className="frame"><img src={`${Config.BASE_URL}/img/upload-frame.png`} alt=""/></div>
              <div style={{minHeight: '8px'}}></div>
              <div className="d-flex justify-content-center">
                <div className="plus align-self-center"><img src={`${Config.BASE_URL}/img/upload-plus-icon.png`} alt=""/></div>
                <div style={{minWidth: '8px'}}></div>
                <div className="upload-text align-self-center">Unggah disini</div>
              </div>
            </div>
            <input id="cert-upload" name={this.state.vaccineSelected} type="file" onChange={this.handleFileChange} accept=".jpg,.jpeg,.png" style={{display: 'none'}} />
            </>}
            {this.getSelectedCert() !== null && <>
            <div className="upload-data">
              <img src={this.getSelectedCert().photo_certificate} alt=""/>
              <div className="action d-flex justify-content-end">
                <div className="view" data-toggle="modal" data-target="#cert-view-modal">Lihat</div>
                <div style={{minWidth: '16px'}}></div>
                <div onClick={() => this.deleteCert()} className="delete">Hapus</div>
              </div>
            </div>
            <div className="modal fade" id="cert-view-modal">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body p-0">
                    <img id="identity-preview-img" src={this.getSelectedCert().photo_certificate} alt=""/>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
            </>}
            <div style={{minHeight: '80px'}}></div>
          </div>
        </div>
        </>}
        {this.state.isLoading && <div className="loading"><div className="spinner-border text-info"></div></div>}
      </div>
    );
  }
}

export default withRouter(Certificate);
