import React from 'react';
import TopBar from './../component/topbar';
import ArticleListPage from './../component/article/article-list';
import Footer from './../component/footer';

class ArticleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      article: []
    }
  }

  render() {
    return (
      <div className="main">
        <TopBar login={this.props.login}></TopBar>
        <ArticleListPage></ArticleListPage>
      </div>
    );
  }
}

export default ArticleList;
