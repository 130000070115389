import React from 'react';
import TopBar from './../component/topbar';
import Banner from './../component/home/banner';
import HowTo from './../component/home/how-to';
import Download from './../component/home/download';
import Article from './../component/home/article';
import AboutUs from './../component/home/about-us';
import ContactUs from './../component/home/contact-us';
import Footer from './../component/footer';

class Home extends React.Component {
  render() {
    return (
      <div className="main">
        <TopBar login={this.props.login}></TopBar>
        <Banner></Banner>
        <HowTo></HowTo>
        <Download></Download>
        <Article></Article>
        <AboutUs></AboutUs>
        <ContactUs></ContactUs>
        <Footer></Footer>
      </div>
    );
  }
}

export default Home;
