import React from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/id';
import * as Config from './../../config';

class ArticleDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      article: null,
      articleRecommended: [],
    }
  }

  copyText(text) {
    navigator.clipboard.writeText(text);
  }

  async componentDidMount() {
    let slug = window.location.pathname.replace("/article-detail/", "");
    axios.get(`${Config.API_PUBLIC_URL}/article/detail/${slug}`).then((response) => {
      try {
        this.setState({ article: response.data.data.articles[0], articleRecommended: response.data.data.article_recomend });
        console.log(this.state.article);
      } catch(error) {
        Swal.fire(error.message);
      }
    });
  }

  render() {
    return (
      <div id="article-detail" className="article-detail">
        <div className="tab-panel">
          <div className="container d-flex">
            <div><a href={Config.BASE_URL}>Beranda</a></div>
            <div style={{minWidth: '4px'}}></div>
            <div>/</div>
            <div style={{minWidth: '4px'}}></div>
            <div><a href={`${Config.BASE_URL}/article-list`}>Artikel</a></div>
            <div style={{minWidth: '4px'}}></div>
            <div>/</div>
            <div style={{minWidth: '4px'}}></div>
            {this.state.article != null && <div>{this.state.article.title}</div>}
          </div>
        </div>
        {this.state.article != null && <div className="container" style={{maxWidth: '700px'}}>
          <div style={{minHeight: '40px'}}></div>
          <div className="article-title">{this.state.article.title}</div>
          <div className="article-meta d-flex justify-content-between">
            <div className="writer">Ditulis oleh : <span className="text-info">{this.state.article.created_by}</span></div>
            <div className="date">{moment(this.state.article.created_at).format("dddd, DD MMMM YYYY")}</div>
          </div>
          <div className="article-meta">
            <div className="writer">Ditinjau oleh : <span className="text-info">{this.state.article.peninjau_matery}</span></div>
          </div>
          <div style={{minHeight: '20px'}}></div>
          <div className="article-img">
            <img src={this.state.article.image} alt=""/>
            <div style={{minHeight: '16px'}}></div>
            <div className="img-desc">{this.state.article.image_description}</div>
          </div>
          <div style={{minHeight: '30px'}}></div>
          <div className="content" dangerouslySetInnerHTML={{__html: this.state.article.text}}></div>
          <div style={{minHeight: '30px'}}></div>
          <div className="article-tags">
            {this.state.article.tags.map((item, index) => <a key={index} href={`${Config.BASE_URL}/article-tag/${item}`}><div className="tag">{item}</div></a>)}
          </div>
          <div style={{minHeight: '30px'}}></div>
          <div className="article-share d-flex flex-column flex-md-row">
            <div className="share-text align-self-center">Bagikan</div>
            <div style={{minHeight:'12px',minWidth:'12px'}}></div>
            <div className="share-socmed d-flex align-self-center">
              <div className="clickable">
                <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${this.state.article.title}`} target="_blank"><img src={`${Config.BASE_URL}/img/share-twitter-icon.png`} alt=""/></a>
              </div>
              <div style={{minHeight:'8px',minWidth:'8px'}}></div>
              <div className="clickable">
                <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`} target="_blank"><img src={`${Config.BASE_URL}/img/share-linkedin-icon.png`} alt=""/></a>
              </div>
              <div style={{minHeight:'8px',minWidth:'8px'}}></div>
              <div className="clickable">
                <a href={`https://www.facebook.com/share.php?u=${encodeURIComponent(window.location.href)}`} target="_blank"><img src={`${Config.BASE_URL}/img/share-fb-icon.png`} alt=""/></a>
              </div>
              <div style={{minHeight:'8px',minWidth:'8px'}}></div>
              <div className="clickable">
                <a href={`https://wa.me/?text=${encodeURIComponent(window.location.href)}`} target="_blank"><img src={`${Config.BASE_URL}/img/share-wa-icon.png`} alt=""/></a>
              </div>
              <div style={{minHeight:'8px',minWidth:'8px'}}></div>
              <div className="clickable">
                <img onClick={() => this.copyText(window.location.href)} src={`${Config.BASE_URL}/img/share-link-icon.png`} alt=""/>
              </div>
            </div>
          </div>
        </div>}
        <div style={{minHeight: '30px'}}></div>
        {this.state.article != null && this.state.articleRecommended.length > 0 && <div className="container">
          <div id="article-section" className="article">
            <div className="d-flex flex-column flex-md-row justify-content-between">
              <div className="title">Rekomendasi Artikel</div>
              <div className="align-self-end view-all"><a href={`${Config.BASE_URL}/recommended-article/${this.state.article.id}`}>Lihat Semua</a></div>
            </div>
            <div style={{minWidth: '30px', minHeight: '30px'}}></div>
            <div className="d-flex flex-column flex-md-row">
              {this.state.articleRecommended.map((item, index) => index < 3 && <React.Fragment key={index}>
                <a href={`${Config.BASE_URL}/article-detail/${item.slug}`} style={{maxWidth:'360px',flexBasis:'33%'}} className="article-card">
                  <div className="d-flex flex-column h-100">
                    <div className="top" style={{backgroundImage: `url('${item.image}')`}}></div>
                    <div className="bottom flex-grow-1 d-flex flex-column">
                      {/* <div className="article-tags">
                        {item.tags.map((item, subindex) => <div key={subindex} className="tag">{item}</div>)}
                      </div>
                      {item.tags.length > 0 && <div style={{minHeight:'12px'}}></div>} */}
                      <div className="article-title">{item.title}</div>
                      <div style={{minHeight:'12px'}}></div>
                      <div className="article-desc flex-grow-1">{item.metadata}</div>
                      <div style={{minHeight:'16px'}}></div>
                      <div className="article-date">{moment(item.created_at).format("dddd, DD MMMM YYYY")}</div>
                    </div>
                  </div>
                </a>
                {index < 2 && <div style={{minWidth: '45px', minHeight: '45px'}}></div>}
              </React.Fragment>)}
            </div>
          </div>
          <div style={{minHeight: '30px'}}></div>
        </div>}
      </div>
    );
  }
}

export default ArticleDetailPage;
