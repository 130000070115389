// export const BASE_URL = `http://localhost:3000`;
export const BASE_URL = `https://loyaltyapps.digitalnative.id`;
export const API_URL = `https://apitest.digitalnative.id`;
export const API_PUBLIC_URL = `https://apipublic.altea.digitalnative.id`;
export const API_URL_WEB = 'https://api.loyalty-apps.digitanative.id';
export const API_URL_AUTH = 'https://staging-services.alteacare.com';

// export const BASE_URL = "https://devapiweb.loyalty.alteacare.com";
// export const BASE_URL = `https://alteacare.com`;
// export const API_URL = `https://apiapp.alteacare.com`;
// export const API_URL_WEB = `https://apiweb.alteacare.com`;
//export const API_URL_AUTH = `https://staging-services.alteacare.com`;
// export const API_URL_AUTH = `https://services.alteacare.com`;
