import React from 'react';
import * as Config from './../config';

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="d-flex flex-md-row flex-column">
            <div className="footer-logo flex-grow-1">
              <div><a href={Config.BASE_URL}><img src={`${Config.BASE_URL}/img/footer-logo-img.png`} alt=""/></a></div>
            </div>
            <div style={{minHeight:'24px',minWidth:'24px'}}></div>
            <div className="footer-sitemap flex-grow-1">
              <div className="d-flex flex-column">
                <a href={`${Config.BASE_URL}/article-list`}>Artikel Kesehatan</a>
                <div style={{minHeight:'8px',minWidth:'8px'}}></div>
                <a href={`${Config.BASE_URL}/vaksin`}>Vaksinasi Covid-19</a>
                <div style={{minHeight:'8px',minWidth:'8px'}}></div>
                <a href="#about-us">Tentang Kami</a>
                <div style={{minHeight:'8px',minWidth:'8px'}}></div>
                <a href="#contact-us">Hubungi Kami</a>
              </div>
            </div>
            <div style={{minHeight:'24px',minWidth:'24px'}}></div>
            <div className="footer-contact">
              <p><strong>Kontak</strong></p>
              <p>Jalan Raya Bintaro Utama, Bintaro Jaya <br/> Sektor 3A Tangerang Selatan 15225</p>
              <p>
                Hotline WA Alteacare <br/><strong>+62 813 15739235</strong><br/>email : cs@alteacare.com
              </p>
              <div className="d-flex">
                <div className="align-self-center"><strong>Ikuti Kami</strong></div>
                <div style={{minHeight:'4px',minWidth:'4px'}}></div>
                <div className="align-self-center"><a href="https://www.instagram.com/alteacare.id/" target="_blank"><img src={`${Config.BASE_URL}/img/footer-ig-logo.png`} alt=""/></a></div>
                <div style={{minHeight:'4px',minWidth:'4px'}}></div>
                {/*
                  <div className="align-self-center"><img src={`${Config.BASE_URL}/img/footer-twitter-logo.png`} alt=""/></div>
                  <div style={{minHeight:'4px',minWidth:'4px'}}></div>
                  <div className="align-self-center"><img src={`${Config.BASE_URL}/img/footer-linkedin-logo.png`} alt=""/></div>
                  <div style={{minHeight:'4px',minWidth:'4px'}}></div>
                */}
                <div className="align-self-center"><a href="https://www.facebook.com/AlteaCare/" target="_blank"><img src={`${Config.BASE_URL}/img/footer-fb-logo.png`} alt=""/></a></div>
                <div style={{minHeight:'4px',minWidth:'4px'}}></div>
                <div className="align-self-center"><a href="https://www.youtube.com/channel/UCNWx4hWEgCp_NoQxoM0o1hA" target="_blank"><img src={`${Config.BASE_URL}/img/footer-youtube-logo.png`} alt=""/></a></div>
              </div>
            </div>
            <div style={{minHeight:'24px',minWidth:'24px'}}></div>
            <div className="footer-download">
              <div className="d-flex flex-column">
                <div><strong>Download Aplikasi</strong></div>
                <div style={{minHeight:'14px',minWidth:'14px'}}></div>
                <div><a href="https://play.google.com/store/apps/details?id=com.dre.loyalty" target="_blank" className="gtm linkClick"><img src={`${Config.BASE_URL}/img/footer-google-play-download.png`} alt=""/></a></div>
                <div style={{minHeight:'8px',minWidth:'8px'}}></div>
                <div><a href="https://apps.apple.com/id/app/alteacare/id1571455658" target="_blank" className="gtm linkClick"><img src={`${Config.BASE_URL}/img/footer-appstore-download.png`} alt=""/></a></div>
              </div>
            </div>
          </div>
        </div>
        <div style={{minHeight:'80px',minWidth:'80px'}}></div>
        <div className="copyright">©2021 Alteacare All Rights Reserved</div>
      </footer>
    );
  }
}

export default Footer;
